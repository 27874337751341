import { useState } from "react";

import { Hint } from "components/UI/Hint/Hint";

import "./ProjectsActiveReportAuctionVisibilityPercentage.scss";

const ProjectsActiveReportAuctionVisibilityPercentage = ({ percentage, styleColorGray = false, report, isMobile }) => {
  const [showModal, setShowModal] = useState(false);

  const getTextColorClass = (selectedPercentage) => {
    let colorText;

    if (selectedPercentage < 40) {
      colorText = "red";
    } else if (selectedPercentage < 59) {
      colorText = "orange";
    } else if (selectedPercentage < 80) {
      colorText = "lightgreen";
    } else if (selectedPercentage < 99) {
      colorText = "green";
    } else {
      colorText = "linear-gradient-green";
    }
    return colorText;
  };

  return (
    <div className="projects-active-report-auction-visibility-percentage">
      <div className="projects-active-report-auction-visibility-percentage__container">
        <div className="projects-active-report-auction-visibility-percentage__icon" />
        <p className={`projects-active-report-auction-visibility-percentage__text ${styleColorGray ? "gray" : getTextColorClass(percentage)}`}>{percentage}%</p>
        <div className="projects-active-report-auction-visibility-percentage__hint" onMouseOver={() => setShowModal(true)} onMouseLeave={() => setShowModal(false)} />
      </div>
      {isMobile && 
        <div className="projects-active-report-auction-visibility-percentage__container">
          <div className="projects-active-report-auction-visibility-percentage__icon-coin" />
          <p className="projects-active-report-auction-visibility-percentage__text-coin">{report.extraPrice ? report.extraPrice : 0} ₽</p>
        </div>
      }
      {showModal && (
        <Hint
          className="hint-projects-active-report-auction-visibility-percentage"
          title="Как сделать задачу заметнее?"
          text={[
            "Если вашу задачу игнорируют, значит, на одной из популярных фриланс-бирж есть более привлекательные предложения",
            <br />,
            <br />,
            "Мы проанализировали 1 000+ заданий и рассчитали индекс привлекательности, который поможет оценить и улучшить ваше предложение",
          ]}
          strong={true}
          strongTitle="Важно!"
          strongText={[
            "Повышение цены не гарантирует, что задание возьмет ответственный исполнитель.",
            <br />,
            "Пожалуйста проверяйте отчеты внимательно."
          ]}
        />
      )}
    </div>
  );
};

export default ProjectsActiveReportAuctionVisibilityPercentage;
