import { useState } from "react";
import { observer } from "mobx-react";

import FoldableSection from "components/UI/FoldableSection/FoldableSection";
import Tooltips from "components/UI/Tooltips/Tooltips";
import Icon from "components/UI/IconSVG/Icon";

import ProjectCounters from "pages/Projects/components/Project/ProjectCounters/ProjectCounters";
import ProjectSetupModal from "pages/Projects/components/ProjectSetupModal/ProjectSetupModal";
import ProjectsProgressIndicator from "../ProjectsProgressIndicator/ProjectsProgressIndicator";
import ProjectsActiveBlockWait from "./ProjectsActiveBlockWait/ProjectsActiveBlockWait";
import ProjectsActiveBlockError from "./ProjectsActiveBlockError/ProjectsActiveBlockError";
import { ProjectsActiveReportRow } from "./ProjectsActiveReportRow/ProjectsActiveReportRow";
import ProjectLinkButton from "pages/Projects/components/ProjectLinkButton/ProjectLinkButton";
import ProjectTitle from "pages/Projects/components/Project/ProjectTitle/ProjectTitle";
import ProjectSettingsMobile from "pages/Projects/components/Project/mobile-ver/ProjectSettingsMobile/ProjectSettingsMobile";

import AppStore from "AppStore";
import popupStore from "components/Popup/PopupStore";

import {
  findActivityCount,
  getProjectReportsWithStatus,
  calcTotalProjectActions,
  formatCity,
} from "../../../Projects/ProjectsConstants";

import { ProjectStatuses, ReportStatuses } from "client";

import "./ProjectsActiveRow.scss";

/**
 * @param {Object[]} activities
 * @returns {Object[]}
 */
function getActiveReportsToShow(reports) {
  if (!reports) return reports;

  const filtered = reports.filter(
    (report) => report.status !== ReportStatuses.NEW
  );

  const ready = filtered.filter(
    (report) => report.status === ReportStatuses.READY
  );
  const run = filtered.filter((report) => report.status === ReportStatuses.RUN);

  const other = filtered.filter(
    (report) =>
      ![
        ReportStatuses.READY,
        ReportStatuses.RUN,
        ReportStatuses.NEW,
        ReportStatuses.FINISHED,
      ].includes(report.status)
  );

  ready.sort((a, b) => new Date(a.acceptDatetime) - new Date(b.acceptDatetime));
  run.sort((a, b) => new Date(a.sentDatetime) - new Date(b.sentDatetime));
  other.sort(
    (a, b) => new Date(a.scheduledDatetime) - new Date(b.scheduledDatetime)
  );

  const sorted = [...ready, ...other, ...run];
  return sorted;
}

export const ProjectsActiveRow = observer(
  ({ project, initialExpanded = false, hasBotsInType, isMobile }) => {
    const [expanded, setExpanded] = useState(initialExpanded);
    const [renderReports, setRenderReports] = useState(initialExpanded);
    const [isShowSettings, setIsShowSettings] = useState(false);

    function invertExpanded(e) {
      if (AppStore.editingTitle && AppStore.editingProjectId === project.id) return;
      if (e.target.closest(".project-editing-title__button-save, .projects-active-row__button-config")) return;
      if (e.target.closest(".projects-drafts-row__button-mobile-config")) return;

      if (expanded && AppStore.isSetupOpen && AppStore.openSetupProjectId === project.id) {
        setTimeout(() => {
          AppStore.setIsSetupOpen(false);
          AppStore.setOpenSetupProjectId(null);
          AppStore.clearUploadedFile('default');
          AppStore.startEditingNewLink(false);
        }, 500);
      }

      setExpanded(!expanded);
      if (!expanded)
        setRenderReports(true);
    }

    const status = project?.status;
    const activities = project?.activities;

    const actions = calcTotalProjectActions(project).toLocaleString();
    const reviewsCount = findActivityCount(activities, "MAIN.REVIEW");
    const reportsToShow = getActiveReportsToShow(project.reports);
    const newReports = getProjectReportsWithStatus(project.reports, "new");
    const finish = getProjectReportsWithStatus(project.reports, "finished");
    finish.sort((a, b) => new Date(b.sentDatetime) - new Date(a.sentDatetime));

    const onTransitionEnd = () => {
      if (!expanded)
        setRenderReports(false);
    };

    const disabled = !!project.testing;
    const isEditing = AppStore.editingTitle && AppStore.editingProjectId === project.id;

    return (
      <>
      <FoldableSection className="projects-active-row" expanded={expanded} onTransitionEnd={onTransitionEnd}>
        <div className="projects-active-row__title-container">
          <div className="projects-active-row__title-section" onClick={disabled ? undefined : invertExpanded}>
            <Icon className={`projects-active-row__title-section-icon ${expanded ? "expanded" : ""} ${disabled ? "disabled" : ""}`} sprite="projects" name="accordion" color="#4F4F4F" />
            <ProjectTitle store={AppStore} project={project} expanded={expanded} isMobile={isMobile} />
            {!isMobile && (isEditing ? (
              <></>
            ) : (
              <button
                type="button"
                aria-label="Редактировать"
                className="projects-active-row__button-edit"
                onClick={() => {
                  AppStore.startEditingProjectTitle(project.id);
                  AppStore.setEditingTitle(true);
                  AppStore.setNewTitle(project.title);
                }}
              />
            ))}
            {isMobile && 
              <button type="button" aria-label="Открыть все настройки" onClick={() => { popupStore.open("project-settings-mobile"); setIsShowSettings(true); }}>
                <Icon className="projects-drafts-row__button-mobile-config" sprite="projects" name="mobile-config" color="#1E1E1E" width={4} height={18} />
              </button>
            }
            <div className="projects-active-row__config">
              {!project.cancelling && !project.bots ?
                <Tooltips description="Раскройте проект и нажмите здесь для Настроек проекта" innerStyle={{ left: "45px" }}>
                  <button
                    type="button"
                    aria-label="Настройки проекта"
                    className="projects-active-row__button-config"
                    onClick={() => {
                      if (AppStore.isSetupOpen && AppStore.openSetupProjectId !== project.id) {
                        AppStore.setIsSetupOpen(false);
                        AppStore.setOpenSetupProjectId(null);
                        setTimeout(() => {
                          AppStore.clearUploadedFile('default');
                          AppStore.setIsSetupOpen(true);
                          AppStore.setOpenSetupProjectId(project.id);
                        }, 0);
                      } else {
                        AppStore.setIsSetupOpen(!AppStore.isSetupOpen);
                        AppStore.setOpenSetupProjectId(AppStore.isSetupOpen ? project.id : null);
                      }
                    }}
                  />
                </Tooltips>
                :
                <div className="projects-active-row__no-config" />
              }
            </div>
          </div>

          <div className="projects-active-row__info">
            {!isMobile && <ProjectLinkButton style={{ visibility: project.link ? 'visible' : 'hidden' }} link={project.link} />}
            {!project.cancelling 
              ? <ProjectsProgressIndicator state={project.progress} status={status} />
              : <div className="projects-active-row__info-status">Процесс остановки</div>
            }
            <ProjectCounters actions={actions} reviewsCount={reviewsCount} projectBots={project.bots} projectTest={project.testing} />
            <p className="projects-active-row__info-city">{formatCity(project.city)}</p>
            <p className="projects-active-row__info-price">{project ? `${project.price.toLocaleString()} ₽` : ""}</p>
          </div>

          {!isMobile && 
            <div className="projects-active-row__buttons">
              <Tooltips description="При остановке проекта неизрасходованные деньги вернуться на ваш баланс.">
                <button type="button" aria-label="Остановка проекта" className="projects-active-row__stop-icon" onClick={() => { AppStore.stopProjectWithConfirm(project) }} />
              </Tooltips>
            </div>
          }
        </div>

        {renderReports && (
          <>
            {AppStore.isSetupOpen && AppStore.openSetupProjectId === project.id && (
              <ProjectSetupModal
                project={project}
                link={project.link}
                onClose={() => {
                  AppStore.setIsSetupOpen(false);
                  AppStore.setOpenSetupProjectId(null);
                  AppStore.startEditingNewLink(false);
                  AppStore.clearUploadedFile('default');
                }}
                isMobile={isMobile}
              />
            )}
            <div>
              {status === ProjectStatuses.FAILED && <ProjectsActiveBlockError project={project} isMobile={isMobile} />}

              {project.bots && !project.testing && (
                <>
                  <ProjectsActiveReportRow
                    project={project}
                    report={{
                      id: `bot-${project.id}`,
                      status: project.status,
                      chain: project.activities.map(activity => activity.type) || [],
                      startDatetime: project.startDatetime,
                      scheduledDatetime: project.startDatetime,
                      workerId: null,
                      searchPhrases: project.searchPhrases,
                    }}
                    hasBotsInType={hasBotsInType}
                    isMobile={isMobile}
                  />
                </>
              )}

              {reportsToShow?.map((report, i) => <ProjectsActiveReportRow key={i} project={project} report={report} isMobile={isMobile} />)}
              {newReports.length > 0 && <ProjectsActiveBlockWait isReviews={reviewsCount} />}

              {newReports.map((report, i) => <ProjectsActiveReportRow key={i} project={project} report={report} isMobile={isMobile} />)}
              {finish.map((report, i) => <ProjectsActiveReportRow key={i} project={project} report={report} isMobile={isMobile} />)}
            </div>
          </>
        )}
      </FoldableSection>

      {isMobile && isShowSettings && <ProjectSettingsMobile store={AppStore} project={project} isMobile={isMobile} />}
      </>
    );
  }
);
