import { observer } from "mobx-react";
import { useEffect, useState } from "react";

import ProjectsGroupTitle from "../components/ProjectsGroup/ProjectsGroupTitle/ProjectsGroupTitle";
import { ProjectsActiveRow } from "./ProjectsActiveRow/ProjectsActiveRow";
import ProjectsFilter from "../components/ProjectsFilter/ProjectsFilter";
// import Icon from "components/UI/IconSVG/Icon";

import AppStore from "AppStore";

import { NO_PROJECTS_COMMENT_FOR_ACTIVE } from "../../Projects/ProjectsConstants";

import "./ProjectsActive.scss";
import ProjectsContainer from "../components/ProjectsContainer/ProjectsContainer";

const ProjectsActive = observer(({ projects, isMobile }) => {
  const [expandedTypes, setExpandedTypes] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    if (projects?.length) {
      const projectTypes = new Set(projects.map((project) => project.type));
      setExpandedTypes(Array.from(projectTypes));
    }
  }, [projects]);

  const toggleType = (type) => {
    if (expandedTypes.includes(type)) {
      setExpandedTypes(expandedTypes.filter((t) => t !== type));
    } else {
      setExpandedTypes([...expandedTypes, type]);
    }
  };

  const groupedProjects = projects?.reduce((acc, project) => {
    const type = project.type;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(project);
    return acc;
  }, {});

  /* if (!(projects?.length))
    return wrapToDiv(<ProjectsPlate title="Тут пусто" comment={comment} />);
 */
  let indexToExpand = projects?.findIndex((project) => project.reports?.find((report) => report.status === "ready"));
  if (indexToExpand < 0 && projects?.length) indexToExpand = 0;

  const toggleDropdown = (type) => {
    setOpenDropdown(openDropdown === type ? null : type);
  };

  return (
    <ProjectsContainer className="projects-archive" hasProjects={projects?.length > 0} emptyComment={NO_PROJECTS_COMMENT_FOR_ACTIVE}>
      {Object.entries(groupedProjects).map(([type, typeProjects]) => {
        const filteredProjects = AppStore.getFilteredProjects('active', type, typeProjects);
        const cities = AppStore.getCitiesForType(typeProjects);
        const hasBotsInType = typeProjects.some(project => project.bots);

        return (
          <div key={type}>
            <div className="projects-active__row">
              <div className="projects-active__row-container">
                <div className="projects-active__row-header" onClick={() => toggleType(type)} >
                  <ProjectsGroupTitle type={type} count={filteredProjects.length ? filteredProjects.length || typeProjects.length : 0} expandedTypes={expandedTypes.includes(type)} isMobile={isMobile} />
                </div>
              </div>
              <ProjectsFilter
                cities={cities}
                selectedCities={AppStore.selectedCities.active}
                type={type}
                typeProjects={typeProjects}
                onChange={(type, city) => AppStore.setSelectedCity('active', type, city)}
                isOpen={openDropdown === type}
                onToggle={() => toggleDropdown(type)}
                isMobile={isMobile}
              />
            </div>
            <div className={`projects-active__row-reports ${expandedTypes.includes(type) ? "expanded" : ""}`}>
              {filteredProjects.length > 0
                ? filteredProjects.map((project, i) => <ProjectsActiveRow key={i} project={project} initialExpanded={i === indexToExpand} hasBotsInType={hasBotsInType} isMobile={isMobile} />)
                : <div className="projects-active__no-results">Нет результатов</div>
              }
            </div>
          </div>
        );
      })}
    </ProjectsContainer>
  );
});

export default ProjectsActive;
