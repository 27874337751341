import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import Button from "components/UI/Button/Button.jsx";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon.jsx";
import { ActivitiesCalculator } from "../componentsCalculatorStep/ActivitiesCalculator/ActivitiesCalculator.jsx";
import ModeSelection from "../componentsCalculatorStep/ModeSelection/ModeSelection.jsx";
import BotsCalculator from "../componentsCalculatorStep/CalculatorStep/BotsCalculator/BotsCalculator.jsx";
import HumansCalculator from "../componentsCalculatorStep/CalculatorStep/HumansCalculator/HumansCalculator.jsx";
import Faqed from "components/UI/Faqed/Faqed.jsx";
import Recommendation from "../componentsCalculatorStep/Recommendation/Recommendation.jsx";
import Icon from "components/UI/IconSVG/Icon.jsx";
import DeadlineSelector from "../componentsCalculatorStep/DeadlineSelector/DeadlineSelector.jsx";

import popupStore from "components/Popup/PopupStore.js";

import upDoubleArrow from "assets/images/upDoubleArrow.svg";

import "./IsMobileCalculatorStep.scss";

export const IsMobileCalculatorStep = observer(
  ({
    store,
    bots = false,
    calculator,
    pathname,
    reviewRef,
    commentRef,
    getDisplayCount,
    getAllActionCount,
    onClickNext,
    showRecommendation,
    currentCalculatorPrice,
    isTestPackageSelected,
    titleModeSelection,
    descriptionModeSelection,
    isMobile
  }) => {
    const [active, setActive] = useState(false);
    const [showRecommendationModal, setShowRecommendationModal] = useState(false);
    const { ref, inView } = useInView({ threshold: 0.2 });
    const { market, mode } = calculator;

    const subtitle = market.isSeo
      ? `Как получить эффект SEO ${market.title}`
      : "Как получить эффект от ПФ";

    const handleShowRecommendation = () => {
      setShowRecommendationModal(true);
      popupStore.open("recommendation-modal");
    };

    useEffect(() => {
      if (inView) {
        setActive(true);
      } else {
        setActive(false);
      }
    }, [inView]);

    return (
      <div className="market-step2-mobile">
        {store.calculators.filter((calculator) => !!calculator.mode.bots === bots).length > 1 && <ModeSelection store={store} bots={bots} />}
        {/* {store.calculators.some(calculator => calculator.mode.bots) && <ExecutorSelection store={store} />} */}
        <div className="market-step2-mobile__distribution">
          <Icon className="market-step2-mobile__distribution-icon" name={market.icon} />
          <Faqed text={mode.stepTwoTitleFaq}><h3 className="market-step2-mobile__distribution-title">{mode.calculatorTitle}</h3></Faqed>
        </div>
        {market.isSeo && store.calculators.length > 1 && <ModeSelection store={store} title={titleModeSelection} description={descriptionModeSelection} />}
        {!mode.bots && showRecommendation && (
          <div className="market-step2-mobile__recommendation">
            <p className="market-step2-mobile__recommendation-title">Рекомендации</p>
            <div className="market-step2-mobile__recommendation-description">
              <p className="market-step2-mobile__recommendation-subtitle">{subtitle}</p>
              <Button title="Читать" className="market-step2-mobile__recommendation-button" onClick={handleShowRecommendation} />
            </div>
            {showRecommendationModal && <Recommendation market={market} />}
          </div>
        )}
        <div ref={ref}><ActivitiesCalculator store={store} reviewRef={reviewRef} commentRef={commentRef} /></div>
        <div className={`market-step2-mobile__settings ${mode.bots ? "in-view" : active ? "fixed-bottom" : "" }`} >
          {mode.bots 
            ? !calculator.store.testing && (<BotsCalculator store={store} modeBots={mode.bots} currentCalculatorPrice={currentCalculatorPrice} isTestPackageSelected={isTestPackageSelected} isMobile={isMobile} />)
            : <HumansCalculator store={store} mode={mode} pathname={pathname} currentCalculatorPrice={currentCalculatorPrice} showRecommendation={showRecommendation} getDisplayCount={getDisplayCount} getAllActionCount={getAllActionCount} isMobile={isMobile} />
          }
        </div>
        <DeadlineSelector store={store} title={mode.bots ? "Выберите пакет продвижения:" : "Выберите скорость отправки заданий исполнителям:"} isTestPackageSelected={isTestPackageSelected} modePath={mode.bots} isMobile={isMobile} />
        <ButtonWithIcon className="market-step2-mobile__create-button" title="Следующий шаг" icon={upDoubleArrow} onClick={onClickNext} />
      </div>
    );
  }
);
