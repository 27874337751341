import { observer } from "mobx-react";

import { Popup } from "components/Popup/Popup";
import Button from "components/UI/Button/Button";
import { TextInput } from "components/UI/TextInput/TextInput";

import popupStore from "components/Popup/PopupStore";
import referralStore from "pages/Referral/ReferralStore";

import { useMatchMedia } from "utils/hooks/use-match-media";

import "./ReferralWithdrawalModal.scss";

export const ReferralWithdrawalModal = observer(() => {
  const { isMobile } = useMatchMedia();

  return (
    <Popup modalType="referral-withdrawal-modal" title={isMobile ? "Вывод средств" : <>Вывод средств <br /> с партнерской программы</>}>
      <div className="referral-withdrawal-modal__info">
        <div className="referral-withdrawal-modal__balance">
          <p>Текущий баланс:</p>
          <p>{referralStore.bonusesAvailable?.toLocaleString()}</p>
        </div>
        <div className="referral-withdrawal-modal__input">
          <p className="referral-withdrawal-modal__input-title">Введите сумму перевода</p>
          <TextInput
            type="text"
            inputmode="decimal"
            placeholder={`Сумма от ${referralStore.minWithdrawalAmount} бонусов`}
            maxLength={10}
            value={referralStore.amountToWithdraw ?? ''}
            onChange={e => referralStore.setAmountToWithdraw(e.target.value)}
          />
          {!isMobile && <p className="referral-withdrawal-modal__input-text">1 бонус = 1 &#8381;; Минимальная сумма: {referralStore.minWithdrawalAmount}</p>}
        </div>
      </div>

      <div className="referral-withdrawal-modal__consent-container">
        <div className="referral-withdrawal-modal__buttons">
          <Button
            className="referral-withdrawal-modal__button-cancel"
            title="Отмена"
            onClick={() => popupStore.close()}
          />
          <Button
            classes={["wide"]}
            title="Перевести на счет"
            onClick={() => referralStore.doWithdrawal()}
            disabled={!referralStore.canWithdraw && !referralStore.doWithdrawalRunning}
          />
        </div>
      </div>
    </Popup>
  );
});
