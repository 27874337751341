import { observer } from "mobx-react";

/*
import Button from "components/UI/Button/Button";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";

import cogIcon from "assets/images/cogicon.svg";

import AppStore from "AppStore";
*/

import "./ProjectsActiveBlockError.scss";

const ProjectsActiveBlockError = observer(({ project }) => {
  return (
    <div className="projects-active-row__error-block-wrapper">
      {project.statusComment && <p className="projects-active-row__error-comment">{project.statusComment}</p>}
      <div className="projects-active-row__error-block">
        <div className="projects-active-row__error-block_container">
          <div className="projects-active-row__error-icon" />
          <div className="projects-active-row__error-description_container">
            <p className="projects-active-row__error-description">
              Задание остановлено
              <br />
              <span>
                Вы можете добавить его в архив или обратиться в поддержку для возобновления
              </span>
            </p>
            <p></p>
          </div>
        </div>

        {/* <div className="projects-active-row__error-block-buttons">
          <Button
            title="Отправить в архив"
            className="projects-active-row__error-block-buttons-archive"
            onClick={() => {
              AppStore.stopProjectWithConfirm(project);
            }}
          />
          {!project.cancelling &&
            <ButtonWithIcon
              title="Изменить настройки"
              className="projects-active-row__error-block-buttons-config"
              icon={cogIcon}
              ariaLabel="Изменить настройки"
              onClick={() => {
                AppStore.setIsSetupOpen(!AppStore.isSetupOpen);
                AppStore.setOpenSetupProjectId(project.id);
              }}
            />
          }
        </div> */}
      </div>
    </div>
  );
});

export default ProjectsActiveBlockError;
