import { useState } from "react";
import { observer } from "mobx-react";

import { TextInput } from "components/UI/TextInput/TextInput";

import PhraseTag from "./PhraseTag/PhraseTag";

import { useMatchMedia } from "utils/hooks/use-match-media";

import "./Phrase.scss";

export const Phrase = observer(({ store, maxPhrases, phraseType, disabled }) => {
  const { isMobile } = useMatchMedia();

  const [phrase, setPhrase] = useState("");
  const phrases = phraseType === 'brand' ? store.brandPhrases : store.searchPhrases;
  const maxPhrasesCount = maxPhrases || 4;

  function phraseHandler(e) {
    if (e.type === "keydown" && e.code === "Enter") {
      return addPhrase();
    }
    setPhrase(e.target.value);
  }

  function addPhrase() {
    if (phrase.length > 0 && phrases.length < maxPhrasesCount) {
      if (phraseType === 'brand') {
        store.addBrandPhrase(phrase);
      } else {
        store.addSearchPhrase(phrase);
      }
      setPhrase("");
    }
  }

  function removePhrase(e) {
    if (e.target.className.includes("close")) {
      const target = e.target.previousElementSibling;
      if (phraseType === 'brand') {
        store.removeBrandPhrase(target.textContent);
      } else {
        store.removeSearchPhrase(target.textContent);
      }
    }
  }

  const phrasesStyle = isMobile
    ? { marginBottom: "8px" }
    : { marginBottom: "18px" };

    const placeholder = isMobile
    ? `${phraseType === 'brand' ? 'Название бренда' : 'Поисковая фраза'} (${phrases.length} из ${maxPhrasesCount})`
    : `${phraseType === 'brand' ? 'Название бренда' : 'Поисковая фраза для продвижения'} (${phrases.length} из ${maxPhrasesCount})`;

  return (
    <div className={`formstep__phrase ${disabled ? 'disabled' : ''}`}>
      <div className="formstep__phrase-container" style={phrases.length ? phrasesStyle : {}} >
        <TextInput
          value={phrase}
          placeholder={placeholder}
          onChange={phraseHandler}
          onKeyDown={phraseHandler}
          errortext={phraseType === 'brand' ? store.brandPhrasesError : store.searchPhrasesError}
        />
        <button className={`formstep__phrase-button ${disabled ? 'disabled' : ''}`} onClick={addPhrase} />
      </div>
      <div className="formstep__phrase-tags" onClick={removePhrase}>
        {phrases.map((phrase, i) => <PhraseTag key={i} title={phrase} />)}
      </div>
    </div>
  );
});
