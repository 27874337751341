export class Project {

  title;
  link;
  screenshot;
  reports = [];

  constructor(project) {
    Object.assign(this, project);

    this.reports = project.reports || [];

    if (!project.screenshot)
      this.screenshot = false;
    if (!project.cancelling)
      this.cancelling = false;
  }

  getDefaultTitle() {
    if (!this.type) return "Активность на площадке";
    if (this.type === "AVITO") return "Активность ОБЪЯВЛЕНИЯ";
    return `Активность на ${this.type}`;
  }

  get titleToShow() {
    const baseTitle = this.title || this.getDefaultTitle();
    return `${baseTitle} (#${this.id})`;
  }

  get fullTitle() {
    let t = this.title || this.getDefaultTitle();
    return `${t} (#${this.id})`;
  }

  // TODO: delete, update, pay, finish, archive...
}
