import { observer } from 'mobx-react';

import Slider from 'components/UI/Slider/Slider';
import Button from 'components/UI/Button/Button';

import { useMatchMedia } from 'utils/hooks/use-match-media';

import './BudgetSlider.scss';

// TODO: use thresholds from mode's model
function getThresholdInfo(price, minPrice, maxPrice) {
  const coeff = (price - minPrice) / (maxPrice - minPrice);
  if (coeff <= 0.075) return { text: "Протестировать", color: '#EEB010' };
  if (coeff <= 0.25) return { text: "Если низкая конкуренция", color: '#AAC506' };
  if (coeff <= 0.53) return { text: "Если умеренная конкуренция", color: '#72A71D' };
  return { text: "Если высокая конкуренция", color: '#37ac38' };
};

export const BudgetSlider = observer(({ store, children, onClickNext, modePath, modeBots, disabled = false }) => {
  const { isMobile } = useMatchMedia();
  const calculator = store.currentCalculator;

  const thresholdValue = getThresholdInfo(calculator.price, calculator.priceMin, calculator.priceMax);

  return (
    <div className='budget-slider'>
      <div className='budget-slider__children-container'>
        {children}
      </div>

      <div className='budget-slider__slider-container'>
        <p className='budget-slider__slider-info' style={{ color: thresholdValue.color }}>{thresholdValue.text}</p>

        <Slider
          min={calculator.sliderValueMin}
          max={calculator.sliderValueMax}
          value={calculator.sliderValue}
          handler={store.isActivitiesCalculatorManual ? undefined : (value => { calculator.setSliderValue(value) })}
          onMouseDown={() => { store.setIsActivitiesCalculatorManual(false) }}
          onTouchEnd={() => { store.setIsActivitiesCalculatorManual(false) }}
          thresholdColor={thresholdValue.color}
          disabled={disabled}
        />

        <div className='budget-slider__limits-container'>
          <div className={`budget-slider__limit ${modeBots ? 'without-rub' : ''}`}>{calculator.priceMin.toLocaleString()}</div>
          {isMobile && modePath
            ? <Button className="budget-slider__create-button" title="Создать проект" onClick={onClickNext} />
            : <></>
          }
          <div className={`budget-slider__limit ${modeBots ? 'without-rub' : ''}`}>{calculator.priceMax.toLocaleString()}</div>
        </div>
      </div>
    </div>
  )
});
