import classNames from "classnames";

import IntroAnimation from "../IntroAnimation/IntroAnimation";
import IntroPerformers from "../IntroPerformers/IntroPerformers";
import IntroIllustration from "../IntroIllustration/IntroIllustration";

import { ReactComponent as MessageIcon } from 'assets/images/dashboard/intro/message.svg';
import { ReactComponent as LikeIcon } from 'assets/images/dashboard/intro/like.svg';
import { ReactComponent as EyeIcon } from 'assets/images/dashboard/intro/eye.svg';
import { ReactComponent as HumanIcon } from 'assets/images/dashboard/intro/human.svg';

import "./IntroMarkets.scss";

export default function IntroMarkets({ marketMode, isMobile }) {
  const path = marketMode?.path ?? '';

  const classes = path.slice(1);
  const classesReviewAvito = path.includes('reviews-na-obyavleniya');
  const classesReviewYandex = path.includes('reviews-on-yandexmaps');

  return (
    <div className={classNames("intro-markets", classes)}>
      <IntroPerformers marketMode={marketMode} />
      <IntroAnimation className={classes} />
      <div className={classNames("intro-markets__container", classes)}>
        <MessageIcon className={classNames("intro-markets__container-message", classes)} />
        <LikeIcon className={classNames("intro-markets__container-like", classes)} />
        <EyeIcon className={classNames("intro-markets__container-eye", classes)} />
        <HumanIcon className={classNames("intro-markets__container-human", classes)} />
      </div>
      {!isMobile && <>{classesReviewAvito || classesReviewYandex ? <div className={`intro-markets__container-review ${classesReviewAvito ? 'avito' : 'yandex'}`}/> : <IntroIllustration marketMode={marketMode} />}</>}
    </div>
  );
}
