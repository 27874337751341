import { observer } from "mobx-react";

import Button from "components/UI/Button/Button";
import ProjectPropertyColumn from "../components/ProjectPropertyColumn/ProjectPropertyColumn";
import CommentSection from "./InfoSection/CommentSection/CommentSection";
import TimerSection from "./InfoSection/TimerSection/TimerSection";
import { FAQReportModal } from "./FAQReportModal/FAQReportModal";
import { AnswerReportModal } from "./AnswerReportModal/AnswerReportModal";
import { QuestionsReportModal } from "./QuestionsReportModal/QuestionsReportModal";
import { Popup } from "components/Popup/Popup";
import WorkerBadge from "./WorkerBadge/WorkerBadge";

import messageBoxStore from "MessageBox/MessageBoxStore";
import popupStore from "components/Popup/PopupStore";

import { ApiError } from "client";

import { useMatchMedia } from "utils/hooks/use-match-media";
import { formatReportDate, formatTime } from "../../Projects/ProjectsConstants";
import { FAQ_IN_REPORT } from "./FAQReportModal/faqConstants";

import "./ReportModal.scss";

export const ReportModal = observer(() => {
  const { isMobile } = useMatchMedia();

  const reportData = popupStore.reportData;
  const projectData = popupStore.projectData;
  const activityReview = popupStore.activityType === "Отзыв";
  const timeUntilUnlockingButton = popupStore.getTimeUntilConfirmationReviews();

  const handleReject = () => {
    popupStore.setShowComment(true);
  };

  const handleChangePerformer = async () => {
    try {
      await popupStore.replace();
      if (!reportData.status === "run" && !reportData.cancelling) {
        messageBoxStore.showInfo("Смена исполнителя");
      }
    } catch (e) {
      if (e instanceof ApiError) {
        messageBoxStore.showError(e.message);
      } else {
        console.error("Произошла ошибка при смене исполнителя:", e);
      }
    }
  };

  const handleRework = async () => {
    try {
      await popupStore.reject();
    } catch (e) {
      if (e instanceof ApiError) {
        messageBoxStore.showError(e.message);
      } else {
        console.error("Произошла ошибка при отправке задачи на доработку", e);
      }
    }
  };

  const handleApprove = async () => {
    try {
      await popupStore.approve();
    } catch (e) {
      if (e instanceof ApiError) {
        messageBoxStore.showError(e.message);
      } else {
        console.error("Произошла ошибка при подтверждении", e);
      }
    }
  };

  return (
    <Popup
      modalType="report-modal"
      header={isMobile ? false : true}
      title="Отчет исполнителя"
      subtitle={`${projectData?.titleToShow} отчет (#${reportData?.id})`}
      backButton={popupStore.showComment ? true : false}
      onBack={() => popupStore.setShowComment(false)}
    >
      <div className="report-modal__container">
        <div className="report-modal__row">
          <ProjectPropertyColumn
            title="Тип активности"
            text={popupStore.activityType}
            activityType={popupStore.activityType}
            className="report-modal__row-activity-type"
          />
          {popupStore.searchPhrase ? (
            <ProjectPropertyColumn
              title="Поисковая фраза"
              text={popupStore.searchPhrase}
            />
          ) : (
            <></>
          )}
          {popupStore.city ? (
            <ProjectPropertyColumn title="Город" text={popupStore.city} />
          ) : (
            <></>
          )}
          <ProjectPropertyColumn title="Статус" text={popupStore.statusText} />
          <ProjectPropertyColumn
            className="report-modal__row_worker-badge"
            title="Исполнитель"
            text={
              <>
                {reportData?.isPro !== undefined && reportData?.isPro !== null && <WorkerBadge isPro={reportData.isPro} isMobile={isMobile} />}
                {reportData?.workerId}
              </>
            }
          />
          <ProjectPropertyColumn
            title="Дата активности"
            text={`≈ ${formatReportDate(
              new Date(reportData ? reportData.sentDatetime : "")
            )}`}
          />
        </div>

        {reportData && (
          <div className="report-modal__info">
            {popupStore.showComment ? (
              <CommentSection store={popupStore} />
            ) : (
              <>
                <TimerSection reportTimer={popupStore.getReportTimer()} />
                <div className="report-modal__info-answer">
                  {reportData.questions && (
                    <QuestionsReportModal report={reportData} />
                  )}
                  <AnswerReportModal report={reportData} />
                </div>
              </>
            )}
          </div>
        )}
        {reportData &&
          reportData.status === "ready" &&
          !popupStore.showComment && (
            <>
              <div className="report-modal__buttons-container">
                <Button
                  classes={["report-modal__buttons-replace"]}
                  title="Отклонить"
                  onClick={handleReject}
                />
                <Button
                  classes={["report-modal__buttons-approve"]}
                  title="Подтвердить и оплатить"
                  boldText={
                    activityReview && timeUntilUnlockingButton > 0
                      ? formatTime(timeUntilUnlockingButton)
                      : ""
                  }
                  onClick={handleApprove}
                  disabled={activityReview && timeUntilUnlockingButton > 0}
                />
              </div>
              {["YANDEXSEO", "GOOGLESEO"].includes(projectData.type) && (
                <div className="report-modal__small-comment">
                  Внимание! Данные Яндекс Метрики и Вебвизора могут быть
                  неточными (± 15 минут). Ориентируйтесь на ключевые запросы, а
                  не только на время визита. При сомнении отправьте проект на
                  доработку с дополнительными вопросами исполнителю.
                </div>
              )}
              {activityReview && timeUntilUnlockingButton > 0 && (
                <div className="report-modal__warning">
                  <div className="report-modal__warning-img" />
                  <p className="report-modal__warning-text">
                    Вы сможете принять задание через:{" "}
                    {formatTime(timeUntilUnlockingButton)}.
                    <br />
                    <span>
                      Убедитесь, что на момент принятия задания сайт не удалил
                      отзыв и оплатите работу или дождитесь автооплаты задачи.
                    </span>
                  </p>
                </div>
              )}
            </>
          )}

        {popupStore.showComment && (
          <div className="report-modal__buttons-container">
            <Button
              classes={["report-modal__buttons-replace"]}
              title={
                projectData.cancelling || reportData.cancelling
                  ? "Отказаться от работы"
                  : "Сменить исполнителя"
              }
              onClick={handleChangePerformer}
            />
            <Button
              classes={["report-modal__buttons-reject"]}
              title={
                isMobile
                  ? "Отправить на доработку"
                  : "Отправить на доработку с комментарием"
              }
              onClick={handleRework}
            />
          </div>
        )}
      </div>
      {!popupStore.showComment && (
        <FAQReportModal
          type="faq-in-report"
          store={popupStore}
          faq={FAQ_IN_REPORT}
        />
      )}
    </Popup>
  );
});
