import classNames from "classnames";

import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";

import selectdIcon from "assets/images/selectdeadline.svg";

import "./Deadline.scss";

export default function Deadline({
  deadline,
  emoji,
  title,
  text,
  onClick,
  isActive,
  modePath,
  isMobile
}) {
  return (
    <div className={classNames("deadline", isActive ? "active" : null)} onClick={() => onClick(deadline)}>
      <div className={`deadline-container ${modePath ? "column" : ""}`}>
        <div className="deadline-days">{`≈ ${deadline}`}</div>

        <div className="deadline-content">
          {((modePath && isMobile) || !isMobile) && <div className="deadline-emoji">{emoji}</div>}
          <p className="deadline-title">{title}</p>
        </div>
        {((modePath && isMobile) || !isMobile) && <p className="deadline-text">{text}</p>}
        <ButtonWithIcon
          title={isActive ? "Выбрано" : "Выбрать"}
          className={classNames("deadline-button", isActive ? "active" : "")}
          icon={isActive ? selectdIcon : ""}
          ariaLabel="Выбрать скорость отправки заданий"
        />
      </div>
    </div>
  );
}
