import Icon from "components/UI/IconSVG/Icon";

import "./ProjectsGroupTitle.scss";

const ProjectsGroupTitle = ({ type, count, totalSum, expandedTypes, isMobile }) => {
  const displayType = type === "AVITO" ? "ОБЪЯВЛЕНИЯ" : type;

  return (
    <div className="projects-drafts__row-total">
      <div className="projects-drafts__row-total-project">
        <Icon className={`projects-drafts__row-accordion-icon ${expandedTypes ? "expanded" : ""}`} sprite="projects" name="accordion" color="#4F4F4F" />
        <h3 className="projects-drafts__row-title">Проекты {displayType} {isMobile && <br />} ({count} шт.)</h3>
      </div>
      {totalSum && <p className={`projects-drafts__row-total-sum ${expandedTypes ? "other-color": ""}`}>Общая сумма: <span>{totalSum.toLocaleString()} ₽</span></p>}
    </div>
  );
};

export default ProjectsGroupTitle;
