import Icon from "components/UI/IconSVG/Icon";

import "./Equipment.scss";

export default function Equipment() {
  const equipmentPrice = [
    { title: "Аренда сервера", price: "2 500 ₽/месяц" },
    { title: "Программное обеспечение", price: "2 500 ₽/месяц" },
    { title: "Настройка прокси для ботов", price: "3 500 ₽" },
    { title: "Прокси, обеспечивающие до 2500 заходов в месяц (до 15 прокси на один сервер)", price: "750 ₽" },
  ];

  return (
    <div className="equipment">
      <div className="equipment__title">
        <Icon className="equipment__title-icon" sprite="another" name="equipment-budget" />
        <div>
          <p>Аренда оборудования</p>
          <p>Оплачивается при первом запуске на месяц</p>
        </div>
      </div>

      <div className="equipment__list">
        {equipmentPrice.map(({ title, price }) => (
          <div key={title} className="equipment__list-item">
            <p>{title}</p>
            <p>{price.replace(/\s/g, '\u00a0')}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
