import ProjectsPlate from "../ProjectsPlate/ProjectsPlate";

import "./ProjectsContainer.scss";

const ProjectsContainer = ({ children, hasProjects, emptyTitle = "Тут пусто", emptyComment, className }) => {

  return (
    <div className={className}>
      {hasProjects ? children : <ProjectsPlate title={emptyTitle} comment={emptyComment} />}
    </div>
  );
};

export default ProjectsContainer;
