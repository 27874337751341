import logoSpritePath from "assets/icons/sprite-logo.svg";
import activitiesSpritePath from "assets/icons/sprite-activities.svg";
import anotherSpritePath from "assets/icons/sprite-another.svg";
import projectsSpritePath from "assets/icons/sprite-projects.svg";

import "./Icon.scss";

const spritePaths = {
  logo: logoSpritePath,
  activities: activitiesSpritePath,
  another: anotherSpritePath,
  projects: projectsSpritePath,
};

const Icon = ({ sprite, name, width, height, color = "currentColor", className = "", ...props }) => {
  const iconHref = sprite ? `${spritePaths[sprite]}#${name}` : `#${name}`;

  return (
    <svg width={width} height={height} className={`icon icon-${name} ${className}`} {...props}>
      <use xlinkHref={iconHref} fill={color} style={{ color }}  />
    </svg>
  );
};

export default Icon;
