import "./ProjectsActiveBlockWait.scss";

export default function ProjectsActiveBlockWait({ isReviews, isMobile }) {
  return (
    <div className={`projects-active-row__wait-info ${isReviews ? "" : "dependent-height"}`}>
      <div className="projects-active-row__wait-info-icon" />
      <div className="projects-active-row__wait-info-lines-container">
        <p className="projects-active-row__wait-info-first-line">
          Проекты отданы в работу
        </p>
        <p className="projects-active-row__wait-info-second-line">
          Исполнители скоро приступят к выполнению заказа
        </p>
        {!isMobile && <p className="projects-active-row__wait-info-third-line">
          Первые отчеты могут прийти на проверку через 4-5 часов в дневное время
          и через 6-8 часов в вечернее время.
        </p>}
        <br />
        {isReviews ? (
          <p>
            Отчет на проверку отзыва может прийти от 2 до 6 дней:
            <br />
            ≈ 2 дня* на написание отзыва исполнителем по алгоритму;
            <br />
            ≈ 3 дня на модерация отзыва на доске объявлений.
          </p>
        ) : (
          <></>
        )}
        <p>
          {!isMobile && <br />}*
          <i>
            Если исполнитель или вы откажетесь от работ, задание начнется
            заново.
          </i>
        </p>
      </div>
    </div>
  );
}
