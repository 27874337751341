import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import classNames from "classnames";

import { SpinBox } from "components/UI/SpinBox/SpinBox";
import CheckBox from "components/UI/CheckBox/CheckBox";
import { Hint } from "components/UI/Hint/Hint";
import Icon from "components/UI/IconSVG/Icon";

import popupStore from "components/Popup/PopupStore";

import {
  REVIEW_PLACEHOLDER_TXT,
  REVIEW_PLACEHOLDER_TXT_ANOTHER,
  REVIEW_ERROR_MESSAGE,
  COMMENT_PLACEHOLDER_TXT
} from "./activityRowConstants";

import { useMatchMedia } from "utils/hooks/use-match-media";

import "./ActivityRow.scss";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const ActivityRow = observer(
  ({
    store,
    activity,
    count,
    editable = true,
    onDown,
    onUp,
    onChange,
    dis,
    reviewRef,
    commentRef,
  }) => {
    const generatedReviewRef = useRef(null);
    const textareaReviewRef = useRef(null);
    const { isMobile } = useMatchMedia();
    const type = activity.type;
    const query = useQuery();
    const encodedReview = query.get("review-text");
    const [showNotification, setShowNotification] = useState(false);

    const getGptGenerationLink = () => {
      return store.market.id === 'avito'
        ? 'https://ai.boostclick.ru/ai-otzyv-obyavleniya/#settings'
        : 'https://ai.boostclick.ru/#settings';
    };

    const toggleWarranty = () => {
      if (!store.reviewWarranty) {
        popupStore.open("warranty-popup");
      } else {
        store.setReviewWarranty(!store.reviewWarranty);
      }
    };

    const adjustTextareaHeight = () => {
      if (textareaReviewRef.current) {
        textareaReviewRef.current.style.height = 'auto';
        textareaReviewRef.current.style.height = `${textareaReviewRef.current.scrollHeight}px`;
      }
    };

    useEffect(() => {
      if (encodedReview && generatedReviewRef.current) {
        const decodedReview = decodeURIComponent(atob(encodedReview)).trim();
        generatedReviewRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });        
        store.setReviewComment(decodedReview);
        setShowNotification(true);
        setTimeout(adjustTextareaHeight, 0);
      }
    }, [encodedReview, store, generatedReviewRef])

    useEffect(() => {
      adjustTextareaHeight();
    }, [store.reviewComment]);

    return (
      <div className="activity-row__wrapper">
        <div className={classNames("activity-row",count ? "activity-row_disabled" : null, type.startsWith("MAIN") ? "activity-row_solo" : null)}>
          <div className="activity-row__title">
            <Icon className="activity-row__icon" sprite="activities" name={activity.icon} color="#FFE14B" />
            <p>
              {activity.title}
              {activity.titleComment && <span><br />{activity.titleComment}</span>}
            </p>
          </div>
          <SpinBox
            store={store}
            activity={activity}
            count={count}
            editable={editable}
            onDown={onDown}
            onUp={onUp}
            onChange={onChange}
            dis={dis}
          />
        </div>
        {/* TODO: Move customizers specs to the model classes */}
        {type === "MAIN.REVIEW" && count > 0 && (
          <div className="textarea__container" ref={reviewRef}>
            <CheckBox
              title={
                <>
                  <div className="check-box__title-container">
                    <div className="check-box__title-warranty-icon" />
                    <p className={`${isMobile ? 'textarea__container-subinfo' : ''}`}>
                      <b>Гарантия на отзывы&nbsp;({store.currentCalculator.reviewWarrantyPrice}&nbsp;₽</b>/отзыв)
                    </p>
                  </div>
                  <p className="check-box__title-warranty-text">Время одобрить задание 168 часов (вместо 72-x часов)</p>
                </>
              }
              checked={store.reviewWarranty}
              onClick={toggleWarranty}
            />
            <div className="activity-row__fields">
              {showNotification && (
                <Hint
                  className="hint-review-notification"
                  title="Важное уведомление"
                  text={[
                    "1 отзыв = 1 уникальный текст = 1 проект",
                    <br />,
                    <br />,
                    "Чтобы заказать больше отзывов:",
                    <br />,
                    <br />,
                    "А. Составьте универсальное задание, что надо исполнителям отразить в отзыве.",
                    <br />,
                    <br />,
                    "Б. Или создайте новый проект с другим текстом отзыва."
                  ]}
                  buttonText="Понятно"
                  onClick={() => setShowNotification(false)}
                />
              )}
              <div className="activity-row__fields-label">
                <p className="activity-row__fields-label-title">
                  Техническое задание к отзыву
                </p>
                <Link
                  className="activity-row__fields-label-button"
                  to={getGptGenerationLink()}
                  // to={process.env.REACT_APP_LANDING_ADDRESS}
                  target="_blank"
                >
                  <Icon className="activity-row__fields-label-button-icon" sprite="activities" name="magic" color="#4F4F4F" />
                  <p className="activity-row__fields-label-button-text">GPT генерация отзыва</p>
                </Link>
              </div>
              <CheckBox
                title="Добавить фотографии к отзыву"
                checked={store.isPhotosUpload}
                onClick={() => store.setPhotosUpload(!store.isPhotosUpload)}
              />
              {store.isPhotosUpload && (
                <input
                  type="text"
                  className="textinput"
                  placeholder="Введите ссылку на файлообменник (например, Яндекс Диск)"
                  value={store.photosUrl}
                  onChange={(e) => store.setPhotosUrl(e.target.value)}
                />
              )}
              {store.reviewCommentError && <p className="textarea__error-message">{REVIEW_ERROR_MESSAGE}</p>}
              <textarea
                minLength={0}
                maxLength={550}
                className={`textarea ${store.reviewCommentError ? " error" : ""}`}
                placeholder={store.market.id === 'avito' ? REVIEW_PLACEHOLDER_TXT : REVIEW_PLACEHOLDER_TXT_ANOTHER}
                value={store.reviewComment}
                onChange={(e) => {
                  store.setReviewComment(e.target.value);
                  adjustTextareaHeight();
                }}
                ref={(el) => {
                  textareaReviewRef.current = el;
                  generatedReviewRef.current = el;
                }}
              />
            </div>
          </div>
        )}
        {type === "MAIN.COMMENT" && count > 0 && (
          <div className="textarea__container" ref={commentRef}>
            <textarea
              minLength={0}
              maxLength={450}
              className={`textarea ${store.commentCommentError ? " error" : ""}`}
              placeholder={COMMENT_PLACEHOLDER_TXT}
              value={store.commentComment}
              onChange={(e) => {
                store.setCommentComment(e.target.value);
              }}
            />
          </div>
        )}
      </div>
    );
  }
);
