import Lottie from "lottie-react";
import classNames from "classnames";

import animationData from "assets/animation/chart-animation.json";

import "./IntroAnimation.scss";

const numberExchangeUsage = 5740;
const numberCompletedTasks = 811231;

export default function IntroAnimation({ className }) {
  const options = {
    animationData: animationData,
    loop: false,
    autoplay: true,
  };

  return (
    <div className={classNames("intro-animation", className)}>
      <div className="intro-animation__info">
        <p>{numberExchangeUsage.toLocaleString()}</p>
        <span>воспользовались биржей Boostclick</span>
      </div>
      <div className="intro-animation__info">
        <p>{numberCompletedTasks.toLocaleString()}</p>
        <span>завершенных заданий</span>
      </div>
      <div className={classNames("intro-animation__lottie", className)}>
        <Lottie {...options} />
      </div>
    </div>
  );
}
