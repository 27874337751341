import { observer } from "mobx-react";
import { useEffect } from "react";

import { Popup } from "components/Popup/Popup";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import ProjectEditingTitle from "pages/Projects/components/ProjectSetupModal/ProjectEditingTitle/ProjectEditingTitle";

import popupStore from "components/Popup/PopupStore";
import popupBoxStore from "PopupBox/PopupBoxStore";

import stopIcon from "assets/images/stop.svg";
import edit from "assets/images/button-edit.svg";
import linkIcon from "assets/images/link.svg";

import "./ProjectSettingsMobile.scss";

const ProjectSettingsMobile = observer(({ store, project, isMobile }) => {
  const isActive =
    project && (project.status === "running" || project.status === "failed");
  const isEditing = store.editingTitle && store.editingProjectId === project.id;

  const handleEditTitle = () => {
    store.startEditingProjectTitle(project?.id);
    store.setEditingTitle(true);
    store.setNewTitle(project?.title);
  };

  const handleSaveTitle = (newTitle) => {
    store.updateProjectDetails(project, { title: newTitle });
    store.setEditingTitle(false);
    popupStore.close();
  };

  const handleCopyLink = async () => {
    if (!project?.link) return;
    
    try {
      await navigator.clipboard.writeText(project.link);
      popupStore.close();
      popupBoxStore.showInfo("Ссылка скопирована");
    } catch (err) {
      const textArea = document.createElement("textarea");
      textArea.value = project.link;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      
      textArea.focus();
      textArea.select();

      let success = false;
      try {
        success = document.execCommand("copy");
      } catch (e) {
        console.error("Failed to copy: ", e);
      }
      
      document.body.removeChild(textArea);
      
      if (success) {
        popupStore.close();
        popupBoxStore.showInfo("Ссылка скопирована");
      } else {
        popupBoxStore.showError("Не удалось скопировать ссылку");
      }
    }
  };

  useEffect(() => {
    return () => {
      if (store.editingTitle && store.editingProjectId === project.id) {
        store.setEditingTitle(false);
      }
    };
  }, [project, store]);

  return (
    <Popup className="project-settings-mobile" modalType="project-settings-mobile" title={project.titleToShow}>
      {isEditing ? (
        <ProjectEditingTitle
          project={project}
          onSave={handleSaveTitle}
          initialValue={project?.title}
          isMobile={isMobile}
        />
      ) : (
        <>
          <ButtonWithIcon
            title="Редактировать название"
            icon={edit}
            onClick={handleEditTitle}
          />
          {project.link && (
            <ButtonWithIcon
              title="Скопировать ссылку"
              icon={linkIcon}
              onClick={handleCopyLink}
            />
          )}
          {isActive && !project.cancelling && (
            <ButtonWithIcon
              title="Остановить проект"
              icon={stopIcon}
              onClick={() => { store.stopProjectWithConfirm(project) }}
            />
          )}
        </>
      )}
    </Popup>
  );
});

export default ProjectSettingsMobile;
