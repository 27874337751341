import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import BannerStep from "components/BannerStep/BannerStep";
import Faqed from "components/UI/Faqed/Faqed";
import Plate from "components/UI/Plate/Plate";
import InputLink from "components/InputLink/InputLink";
import CityInput from "../../SettingsStep/componentsSettingsStep/CityInput/CityInput";
import CheckBox from "components/UI/CheckBox/CheckBox";

import AppStore from "AppStore";
import popupStore from "components/Popup/PopupStore";

import { setCookie } from "utils/cookie";
import { useMatchMedia } from "utils/hooks/use-match-media";

import "./SettingsSeoStepBots.scss";

export const SettingsSeoStepBots = observer(({
  store,
  linkRef,
  cityRef,
  searchPhrasesRef,
  scrollToError,
}) => {
    const navigate = useNavigate();
    const { isMobile } = useMatchMedia();

    async function onClickCreateProject() {
      if (!AppStore.isAuth) {
        const redirectUrl = window.location.pathname;
        setCookie("redirect_url", redirectUrl, { expires: 1 });
        return navigate("/signin");
      }
  
      let res = true;
      if (!store.validateStepSeoBots()) {
        const errorRefs = [
          store.linkError ? linkRef : null,
          store.cityError ? cityRef : null,
          store.searchPhrasesError ? searchPhrasesRef : null,
        ];
        const firstErrorRef = errorRefs.find((ref) => ref !== null);
        if (firstErrorRef) {
          scrollToError(firstErrorRef);
        }
        return;
      }
      if (!res) return;

      store.setIsCreatingProject(true);
      popupStore.setAgreedRulesRaisePrice(false);
      popupStore.open("accept-rules");
      AppStore.setActiveTab("drafts");
    }

    const onChangeLinkToAd = (e) => {
      store.setLinkToAd(e.target.value);
    };

    const onChangeAddress = (e) => {
      store.setAddress(e.value);
      store.setCountry(e.data.country);
      store.setCity(e.data.city);
    };

    return (
      <>
        <Plate className="settings-seo-step-bots">
          <div className="settings-seo-step-bots__params">
            <div className="settings-seo-step-bots__params-item" ref={linkRef}>
              <Faqed text="Боты будут заходить на ваш сайт">
                <p className="settings-seo-step-bots__label">Укажите ссылку на сайт</p>
              </Faqed>
              <InputLink defaultValue={store.linkToAd} onChange={onChangeLinkToAd} errortext={store.linkError} />
            </div>
            <div className="settings-seo-step-bots__params-item" ref={cityRef}>
              <Faqed text="Вы можете выбрать все города с помощью чекбокса ниже">
                <p className="settings-seo-step-bots__label">Укажите город продвижения</p>
              </Faqed>
              <CityInput onChange={onChangeAddress} error={store.cityError} />
              <CheckBox
                title="Все города России"
                checked={store.allCitiesFlag}
                onClick={() => store.setAllCitiesFlag(!store.allCitiesFlag) }
              />
            </div>
          </div>

          <div className="settings-seo-step-bots__request" ref={searchPhrasesRef}>
            <div className="settings-seo-step-bots__request-left">
              <div className="settings-seo-step-bots__request-left-title">
                <p className="settings-seo-step-bots__label">Кластер ключевых запросов</p>
                {!isMobile && <p className="settings-seo-step-bots__sublabel">Поисковой запрос должен <br /> находиться <span>в ТОП 50</span></p>}
              </div>
              <textarea
                className="settings-seo-step-bots__textarea"
                placeholder="Квартиры в СПБ, Квартиры Санкт-Петербург..."
                onChange={(e) => { store.setKeysClusterBuffer(e.target.value) }}
              />
              <div className="settings-seo-step-bots__request-left-description">
                {isMobile && <p className="settings-seo-step-bots__sublabel">Пропишите ключи через запятую <span>до 300 штук</span></p>}
                <p className="settings-seo-step-bots__mark">{store.searchPhrases.length}<span>/300 (объем ботов будет распределен по всем запросам из списка)</span></p>
              </div>
            </div>

            <div className="settings-seo-step-bots__request-right">
              {/*
              <p><span>ИЛИ</span> вставьте из файла</p>
              <button>Вставить XLM файлом</button>
              <p className="settings-seo-step-bots__sublabel">Чтобы файл корректно считался, укажите каждый ключ <br /> <span>с новой строки</span></p>
              */}
            </div>
          </div>

          {store.isBotFarm &&
            <div className={`settings-seo-step-bots__request ${store.isBotFarm  ? "another-position" : ""}`}>
              <div className="settings-seo-step-bots__request-title">
                <p className="settings-seo-step-bots__label">Укажите долгосрочные интересы пользователей с Яндекс Метрики</p>
                {!isMobile && <p className="settings-seo-step-bots__sublabel">Пропишите интересы <br /> через запятую</p>}
              </div>
              <textarea
                className="settings-seo-step-bots__textarea"
                placeholder="Бизнес, Развлечения и досуг, Недвижимость..."
                onChange={(e) => { store.setLongInterestsBuffer(e.target.value) }}
              />
              <p className="settings-seo-step-bots__mark">Поле не обязательно, но для более точного результата рекомендуем его заполнить</p>
            </div>
          }
        </Plate>

        <BannerStep banner="banner-third-step" onClick={onClickCreateProject} isMobile={isMobile} />
      </>
    );
  }
);
