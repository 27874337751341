export const TITLE_FOR_MIN_ACTIONS = "Мы не можем убрать это действие";
export const DESCRIPTION_FOR_MIN_ACTIONS = [
  "Три возможных причины:",
  <ol>
    <li>Оно необходимо для выполнения последующих действий.</li>
    <li>Достигнут минимальный лимит действий.</li>
    <li>
      Для написания отзыва нужно прогреть аккаунт другими действиями, чтобы
      повысить шанс модерации.
      <br />
      Рекомендованная пропорция 10 заявок = 1 отзыв.
    </li>
  </ol>,
];

export const TITLE_FOR_MAX_ACTIONS = "Мы не можем добавить больше";
export const DESCRIPTION_FOR_MAX_ACTIONS = [
  "Вы достигли максимального количества задания в рамках одного проекта",
  <br />,
  <br />,
  "Создайте еще проект с другим заданием и желательно на другое ваше объявление."
];

export const TITLE_FOR_NOTIFICATION_ACTIONS = "Важное уведомление";
export const DESCRIPTION_FOR_NOTIFICATION_ACTIONS = [
  "1 отзыв = 1 уникальный текст = 1 проект",
  <br />,
  <br />,
  "Чтобы заказать больше отзывов:",
  <br />,
  <br />,
  "А. Составьте универсальное задание, что надо исполнителям отразить в отзыве.",
  <br />,
  <br />,
  "Б. Или создайте новый проект с другим текстом отзыва."
];

export const TITLE_FOR_BOT_FARM_DISABLED = "Функция недоступна";
export const DESCRIPTION_FOR_BOT_FARM_DISABLED = "Для управления ботами необходимо включить бот-ферму";
