import { observer } from "mobx-react";

import { FAQModal } from "pages/Projects/ReportModal/FAQReportModal/FAQModal/FAQModal";

import popupStore from "components/Popup/PopupStore";

import { FAQ_IN_REPORT, FAQ_IN_PROJECT } from "../faqConstants";
import { useMatchMedia } from "utils/hooks/use-match-media";

import "./RenderFAQModal.scss";

export const RenderFAQModal = observer(() => {
  const { isMobile } = useMatchMedia();
  
  const modals = {
    "faq-in-report": (<FAQModal type="faq-in-report" store={popupStore} faq={FAQ_IN_REPORT} isMobile={isMobile} />),
    "faq-in-project": (<FAQModal type="faq-in-project" store={popupStore} faq={FAQ_IN_PROJECT} isMobile={isMobile} />),
  };

  return modals[popupStore.modalType];
});
