import { observer } from "mobx-react";
import classNames from "classnames";

import BotColumns from "pages/Projects/components/ProjectPropertyColumn/BotColumns/BotColumns";
import StandardColumns from "pages/Projects/components/ProjectPropertyColumn/StandardColumns/StandardColumns";
import Tooltips from "components/UI/Tooltips/Tooltips";
import Button from "components/UI/Button/Button";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import { ProjectsActiveReportChains } from "./ProjectsActiveReportChains/ProjectsActiveReportChains";
import ProjectsActiveReportBlockWarn from "./ProjectsActiveReportBlockWarn/ProjectsActiveReportBlockWarn";
import ProjectsActiveReportAuctionCancel from "./ProjectsActiveReportAuction/ProjectsActiveReportAuctionCancel/ProjectsActiveReportAuctionCancel";
import ProjectsActiveReportAuction from "./ProjectsActiveReportAuction/ProjectsActiveReportAuction";
import ProjectsActiveReportAuctionVisibilityPercentage from "./ProjectsActiveReportAuction/ProjectsActiveReportAuctionVisibility/ProjectsActiveReportAuctionVisibilityPercentage/ProjectsActiveReportAuctionVisibilityPercentage";

import AppStore from "AppStore";

import popupStore from "components/Popup/PopupStore";
import ReportTimerStore from "pages/Projects/ReportModal/ReportTimerStore";
import {
  formatReportDate,
  formatTime,
  upPrice,
} from "../../../../Projects/ProjectsConstants";

import "./ProjectsActiveReportRow.scss";

/** @returns {string|null|undefined} */
function activityDateStr(report) {
  if (!report) return undefined;

  for (const prop of ["sentDatetime", "scheduledDatetime"]) {
    const val = report[prop];
    if (val) return `≈ ${formatReportDate(new Date(val))}`;
  }

  return null;
}

export const ProjectsActiveReportRow = observer(
  ({ project, report, hasBotsInType, isMobile }) => {
    const isBotPlaceholder =
      project.bots && report.id.toString().startsWith("bot-");
    const chain = isBotPlaceholder
      ? project.activities.map((a) => a.type)
      : report.chain;
    const reportTimer = new ReportTimerStore(report.acceptDatetime);
    const timeLeft = reportTimer.timeLeft;
    const isDayLeft = timeLeft < 24 * 60 * 60 * 1000;
    const stylesReportsCancelling = report.cancelling;
    const status = report.status;
    const showAuctionComponents =
      status === "run" &&
      !report.workerId &&
      !report.cancelling &&
      new Date().getTime() -
        new Date(report.extraPriceUpdateDatetime).getTime() >
        3 * 24 * 60 * 60 * 1000;

    const extraPrice = report?.extraPrice || 0;
    const percentage = popupStore.getPercentageWithoutAddition(
      popupStore.baseAmount,
      popupStore.basePercentage,
      extraPrice
    );

    let activityType;
    let activityIcon;
    const mainAction = chain.find((action) => action.startsWith("MAIN."));
    if (mainAction) {
      const marketStore = AppStore.findMarketStoreById(
        project.type.toLowerCase()
      );
      if (marketStore) {
        const action = marketStore.market.actionsMap[mainAction];
        activityType = action.activityType;
        activityIcon = action.icon;
      }
    }
    if (!activityType && chain.some((action) => action.startsWith("VIEW."))) {
      activityType = "Просмотр";
      activityIcon = "eye";
    }

    const isReview = !!chain.find((action) => action === "MAIN.REVIEW");

    const statusMap = {
      new: { text: "В плане" },
      run: {
        text: `${
          showAuctionComponents
            ? "Поиск исполнителя"
            : !report.workerId
              ? report.cancelling
                ? "В работе (отменяется)"
                : "Поиск исполнителя"
              : "В работе"
        }`,
        color: showAuctionComponents ? "#F3901C" : "",
      },
      ready: { text: "Ожидает проверки" },
      replace: {
        text:
          project.cancelling || report.cancelling
            ? "Отказ от работы"
            : "Смена исполнителя",
      },
      rework: { text: "На доработке" },
      approved: { text: "Принято", color: "#01BE02" },
      finished: { text: `${report.cancelling ? "Отменена" : "Завершено"}` },
      failed: { text: "Ошибка (!)", color: "#FF7373" },
    };

    const statusText = isBotPlaceholder ? "В работе" : statusMap[status]?.text;

    function renderAutoReworkTooltip(autoReworkReason) {
      const autoReworkTooltipMap = {
        empty: {
          text: [
            "Мы выявили пустой отчет, возможно это был 🤖 бот.",
            <br />,
            "Работа передана новому исполнителю.",
          ],
        },
        copy: {
          text: [
            "Мы выявили отчет-копию, возможно это был 🤖 бот.",
            <br />,
            "Работа передана новому исполнителю.",
          ],
        },
        foreign: {
          text: [
            "Мы выявили подозрительный отчет, возможно это был 🤖 бот.",
            <br />,
            "Работа передана новому исполнителю.",
          ],
        },
      };
      const tooltipData = autoReworkTooltipMap[autoReworkReason];

      if (!tooltipData) return null;

      return (
        <Tooltips description={tooltipData.text} innerStyle={{ top: "-10px" }}>
          {statusText}
          <div className="projects-active-report-row__icon-warning" />
        </Tooltips>
      );
    }

    function renderReportButton() {
      let buttonTitle;
      let buttonClassName;

      if (["finished", "approved"].includes(status) && !report.cancelling) {
        buttonTitle = "Открыть отчет";
        buttonClassName = `projects-active-report-row__report-check-${status}`;
      } else if (status === "ready" && report.answer) {
        buttonTitle = "Проверить отчет";
        buttonClassName = `projects-active-report-row__report-check-${status}`;
      } else if (status === "finished" && report.cancelling) {
        buttonTitle = "Задача отменена";
        buttonClassName = "projects-active-report-row__report-check-cancel";
      } else {
        buttonTitle = "Отчет еще не готов";
        buttonClassName = `projects-active-report-row__report-check-${status}`;
      }

      return (
        <Button
          title={buttonTitle}
          className={`projects-active-report-row__report-check ${buttonClassName}`}
          onClick={openReportModal}
        />
      );
    }

    const openReportModal = () => {
      popupStore.open("report-modal");
      popupStore.openReportModal(report, project);
      popupStore.setSearchPhrase(report.fullSearchPhrase);
      popupStore.setCity(project.city);
      popupStore.setActivityType(activityType);
      popupStore.setStatusText(statusText);
    };

    const openShowModalAuction = () => {
      popupStore.setShowModalAuction(true);
      popupStore.setSelectedReport(report);
    };

    const activityDate = activityDateStr(report);
    const rowClassNames = classNames("projects-active-report-row", {
      [`projects-active-report-row_${status}`]: true,
      "projects-active-report-row_warn": status === "ready" && isDayLeft,
      "projects-active-report-row_cancel":
        status === "finished" && stylesReportsCancelling,
    });
    const columnProps = {
      project,
      report,
      status,
      statusMap,
      statusText,
      activityType,
      activityIcon,
      activityDate,
      reportTimer,
      isDayLeft,
      renderAutoReworkTooltip,
      isReview,
    };

    return (
      <div className="projects-active-report-row-wrapper">
        <div className={rowClassNames}>
          {isReview && showAuctionComponents ? (
            <ProjectsActiveReportAuctionCancel
              store={popupStore}
              report={report}
            />
          ) : (
            <></>
          )}
          <div className={`projects-active-report-row__container-column ${hasBotsInType ? "other-display" : ""}`}>
            {!project.bots && <StandardColumns {...columnProps} />}
            {project.bots && <BotColumns {...columnProps} hasBotsInType={hasBotsInType} />}
          </div>
          {isReview && status !== "new" && !showAuctionComponents ? (
            <>
              {!popupStore.showModalAuction || popupStore.selectedReport?.id !== report.id ? (
                isMobile ? (
                  <div className="projects-active-report-row__report-other-settings">
                    <ProjectsActiveReportAuctionVisibilityPercentage percentage={percentage} styleColorGray={true} report={report} isMobile={isMobile} />
                    <ButtonWithIcon
                      className="projects-active-report-row__report-raise-price-button mobile-full-width"
                      title="Повысить"
                      icon={upPrice}
                      onClick={openShowModalAuction}
                      disabled={["finished", "ready", "failed"].includes(status) || report.workerId || report.cancelling}
                      ariaLabel="Повысить стоимость отзыва"
                    />
                    <div className="projects-active-report-row__mobile-bottom-buttons">
                      {!project.bots && !["run", "replace", "rework"].includes(status) && (status === "ready" || report.answer) ? renderReportButton() : !project.bots && renderReportButton()}
                      <Tooltips description="Отменить задачу и вернуть деньги" innerStyle={{ left: "40px" }}>
                        <button
                          className="projects-active-report-row__report-cancelling-button"
                          type="button"
                          aria-label="Отменить задачу"
                          onClick={() => popupStore.cancelTaskWithConfirm(report)}
                          disabled={["finished", "ready", "failed"].includes(status) || report.workerId || report.cancelling}
                        />
                      </Tooltips>
                    </div>
                  </div>
                ) : (
                  <>
                    {report.extraPrice ? (
                      <div className="projects-active-report-row__report-other-settings">
                        <ProjectsActiveReportAuctionVisibilityPercentage percentage={percentage} styleColorGray={true} report={report} isMobile={isMobile} />
                        <div className="projects-active-report-row__report-raise-price"><p>Вы повысили <span>+{report.extraPrice} ₽</span></p></div>
                        <ButtonWithIcon
                          title="Повысить"
                          className="projects-active-report-row__report-raise-price-button"
                          icon={upPrice}
                          onClick={openShowModalAuction}
                          disabled={["finished", "ready", "failed"].includes(status) || report.workerId || report.cancelling}
                          ariaLabel="Повысить стоимость отзыва"
                        />
                        <Tooltips description="Отменить задачу и вернуть деньги" innerStyle={{ left: "40px" }}>
                          <button
                            className={`projects-active-report-row__report-cancelling-button ${status === "ready" && isDayLeft ? "day-left" : ""}`}
                            type="button"
                            aria-label="Отменить задачу"
                            onClick={() => popupStore.cancelTaskWithConfirm(report)}
                            disabled={["finished", "ready", "failed"].includes(status) || report.workerId || report.cancelling}
                          />
                        </Tooltips>
                      </div>
                    ) : (
                      <>
                        <ButtonWithIcon
                          title="Повысить"
                          className="projects-active-report-row__report-raise-price-button"
                          icon={upPrice}
                          onClick={openShowModalAuction}
                          disabled={["finished", "ready", "failed"].includes(status) || report.workerId || report.cancelling}
                          ariaLabel="Повысить стоимость отзыва"
                        />
                        <Tooltips description="Отменить задачу и вернуть деньги" innerStyle={{ left: "40px" }}>
                          <button
                            className="projects-active-report-row__report-cancelling-button"
                            type="button"
                            aria-label="Отменить задачу"
                            onClick={() => popupStore.cancelTaskWithConfirm(report)}
                            disabled={["finished", "ready", "failed"].includes(status) || report.workerId || report.cancelling}
                          />
                        </Tooltips>
                      </>
                    )}
                    {!project.bots && !["run", "replace", "rework"].includes(status) && (status === "ready" || report.answer)
                      ? renderReportButton()
                      : !project.bots && renderReportButton()}
                  </>
                )
              ) : null}
            </>
          ) : (
            !project.bots && renderReportButton()
          )}
          {status === "ready" && isDayLeft && !project.bots && <ProjectsActiveReportBlockWarn timer={formatTime(reportTimer.timeLeft)} />}
          {(isReview && showAuctionComponents) || (popupStore.showModalAuction && popupStore.selectedReport?.id === report.id) 
            ? <ProjectsActiveReportAuction store={popupStore} report={report} isMobile={isMobile} />
            : <></>
          }
        </div>
        {chain.length > 1 && ((project.bots && status === "finished") || !project.bots) && <ProjectsActiveReportChains project={project} report={report} />}
      </div>
    );
  }
);
