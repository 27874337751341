import { observer } from "mobx-react";
import Button from "components/UI/Button/Button";

import ProjectsActiveReportAuctionVisibility from "../ProjectsActiveReportAuctionVisibility/ProjectsActiveReportAuctionVisibility";
import ProjectsActiveReportAuctionSlider from "../ProjectsActiveReportAuctionSlider/ProjectsActiveReportAuctionSlider";

import AppStore from "AppStore";
import messageBoxStore from "MessageBox/MessageBoxStore";

import client, { ApiError } from "client";

import "./ProjectsActiveReportAuctionRise.scss";

const ProjectsActiveReportAuctionRise = observer(({ store, report, isMobile }) => {
  const handleContinueWithoutChangingPrice = async () => {
    if (report) {
      const currentExtraPrice = report.extraPrice || 0;
      try {
        await store.updateTaskExtraPrice(report, currentExtraPrice);
        store.setShowModalAuction(false);
      } catch (e) {
        if (e instanceof ApiError) {
          messageBoxStore.showError(e.message);
        } else {
          console.error(
            "Произошла ошибка при возвращении отзыва в работу с текущей ценой:",
            e
          );
        }
      }
    }
  };

  const handleRaisePrice = async () => {
    if (report) {
      const currentExtraPrice = report.extraPrice || 0;
      const newExtraPrice = currentExtraPrice + store.markValues[store.value];

      const userBalance = await client.getBalance();
      const extraPriceIncrease = newExtraPrice - currentExtraPrice;

      if (userBalance < extraPriceIncrease) {
        return messageBoxStore.showError("Недостаточно средств!");
      }

      store.setReportDataForRaisePrice(report, newExtraPrice);
      AppStore.currentMarketStore.setIsCreatingProject(false);
      store.setAgreedRulesRaisePrice(true);
      store.open("accept-rules");
    }
  };

  return (
    <div className="projects-active-report-auction-rise">
      <ProjectsActiveReportAuctionVisibility store={store} report={report} isMobile={isMobile} />
      <ProjectsActiveReportAuctionSlider store={store} isMobile={isMobile} />
      <div className="projects-active-report-auction-rise__raise-price">
        <Button
          title="Поднять ставку"
          boldText={`+ ${store.markValues[store.value]} ₽`}
          className="projects-active-report-auction-rise__update"
          onClick={handleRaisePrice}
        />
        <Button
          title="Продолжить без изменений"
          className="projects-active-report-auction-rise__continue"
          onClick={handleContinueWithoutChangingPrice}
        />
      </div>
    </div>
  );
});

export default ProjectsActiveReportAuctionRise;
