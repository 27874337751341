import { useEffect, useState } from 'react';

import ProjectsContainer from '../components/ProjectsContainer/ProjectsContainer';
import ProjectsGroupTitle from '../components/ProjectsGroup/ProjectsGroupTitle/ProjectsGroupTitle';
import ProjectsDraftsRow from './ProjectsDraftsRow/ProjectsDraftsRow';

import { NO_PROJECTS_COMMENT_FOR_DRAFTS } from '../../Projects/ProjectsConstants';

import './ProjectsDrafts.scss';

export default function ProjectsDrafts({ projects, isMobile }) {
  const [expandedTypes, setExpandedTypes] = useState([]);

  const toggleType = (type) => {
    if (expandedTypes.includes(type)) {
      setExpandedTypes(expandedTypes.filter((t) => t !== type));
    } else {
      setExpandedTypes([...expandedTypes, type]);
    }
  };

  const groupedProjects = projects?.reduce((acc, project) => {
    const type = project.type;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(project);
    return acc;
  }, {});

  useEffect(() => {
    const projectTypes = new Set(projects?.map(project => project.type));
    setExpandedTypes(Array.from(projectTypes));
  }, [projects]);

  return (
    <ProjectsContainer className="projects-drafts" hasProjects={projects?.length > 0} emptyComment={NO_PROJECTS_COMMENT_FOR_DRAFTS}>
      {Object.entries(groupedProjects).map(([type, typeProjects]) => {
        const totalSum = typeProjects.reduce((sum, project) => sum + (project.price || 0), 0);

        return (
          <div key={type}>
            <div className="projects-drafts__row" onClick={() => toggleType(type)}>
              <ProjectsGroupTitle type={type} count={typeProjects.length} totalSum={totalSum} expandedTypes={expandedTypes.includes(type)} isMobile={isMobile} />
            </div>
            <div className={`projects-archive__row-reports ${expandedTypes.includes(type) ? "expanded" : ""}`}>
              {typeProjects.map((project, i) => <ProjectsDraftsRow key={i} project={project} isMobile={isMobile} />)}
            </div>
          </div>
        );
      })}
    </ProjectsContainer>
  );
}
