import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";

import ProjectsContainer from "../components/ProjectsContainer/ProjectsContainer";
import ProjectsGroupTitle from "../components/ProjectsGroup/ProjectsGroupTitle/ProjectsGroupTitle";
import ProjectsFilter from "../components/ProjectsFilter/ProjectsFilter";
import ProjectsArchiveRow from "./ProjectsArchiveRow/ProjectsArchiveRow";

import AppStore from "AppStore";

import { NO_PROJECTS_COMMENT_FOR_ARCHIVE } from "../../Projects/ProjectsConstants";

import "./ProjectsArchive.scss";

const ProjectsArchive = observer(({ projects, isMobile }) => {
  const [expandedTypes, setExpandedTypes] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);

  const groupedProjects = useMemo(() => {
    return projects?.reduce((acc, project) => {
      const type = project.type;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(project);
      return acc;
    }, {});
  }, [projects]);

  useEffect(() => {
    if (groupedProjects) {
      setExpandedTypes(Object.keys(groupedProjects || {}));
    }
  }, [groupedProjects]);

  const toggleType = (type) => {
    if (expandedTypes.includes(type)) {
      setExpandedTypes(expandedTypes.filter((t) => t !== type));
    } else {
      setExpandedTypes([...expandedTypes, type]);
    }
  };

  const toggleDropdown = (type) => {
    setOpenDropdown(openDropdown === type ? null : type);
  };

  return (
    <ProjectsContainer className="projects-archive" hasProjects={projects?.length > 0} emptyComment={NO_PROJECTS_COMMENT_FOR_ARCHIVE}>
          {Object.entries(groupedProjects).map(([type, typeProjects]) => {
            const filteredProjects = AppStore.getFilteredProjects('archive', type, typeProjects);
            const cities = AppStore.getCitiesForType(typeProjects);

            return (
              <div key={type}>
                <div className="projects-archive__row">
                  <div className="projects-archive__row-header" onClick={() => toggleType(type)}>
                    <ProjectsGroupTitle type={type} count={filteredProjects.length ? filteredProjects.length || typeProjects.length : 0} expandedTypes={expandedTypes.includes(type)} isMobile={isMobile} />
                  </div>
                  <ProjectsFilter
                    cities={cities}
                    selectedCities={AppStore.selectedCities.archive}
                    type={type}
                    typeProjects={typeProjects}
                    onChange={(type, city) => AppStore.setSelectedCity('archive', type, city)}
                    isOpen={openDropdown === type}
                    onToggle={() => toggleDropdown(type)}
                    isMobile={isMobile}
                  />
                </div>

                <div className={`projects-archive__row-reports ${expandedTypes.includes(type) ? "expanded" : ""}`}>
                  {filteredProjects.length > 0 
                    ? filteredProjects.map((project, i) => <ProjectsArchiveRow key={i} project={project} isMobile={isMobile} />) 
                    : <div className="projects-archive__no-results">Нет результатов</div>
                  }
                </div>
              </div>
            );
          })}
      </ProjectsContainer>
  );
})

export default ProjectsArchive;