import { observer } from "mobx-react";

import { Popup } from "components/Popup/Popup";
import Button from "components/UI/Button/Button";

import "./FAQModal.scss";

export const FAQModal = observer(({ store, faq, type, isMobile }) => {
  const selectedQuestionIndex = store.selectedQuestionIndex;
  const selectedAnswers = store.selectedAnswers;
  const advice = selectedQuestionIndex === 0;
  const selectedQuestion = faq[selectedQuestionIndex] || {};
  const important = selectedQuestion.important || [];

  const handleBack = () => {
    store.open("report-modal");
  };

  const handleClose = () => {
    store.close();
    store.setSelectedQuestionIndex(null);
    store.setSelectedAnswers([]);
  };

  const handleQuestionClick = (index) => {
    store.setSelectedQuestionIndex(index);
    store.setSelectedAnswers(faq[index].answers);
  };

  return (
    <Popup
      modalType={type}
      header={true}
      title="FAQ"
      backButton={type === "faq-in-report" ? true : false}
      onBack={handleBack}
    >
      <div className="faq-modal__content">
        <div className="faq-modal__body">
          {isMobile && (type === "faq-in-report" ? (
              <Button title="Вернуться назад" onClick={handleBack} />
            ) : (
              <Button title="Вернуться к Моим проектам" onClick={handleClose} />
            ))}
          <div className={`faq-modal__body-answers ${advice ? "advice" : ""}`}>
            {selectedAnswers.map((answer, index) => (
              <div key={index} className="faq-modal__body-answers-answer">
                <h3 className={`${advice ? "advice-title" : ""}`}>
                  <span>Q: </span>
                  {answer.title}
                </h3>
                <p>
                  <span>A: </span>
                  {answer.text}
                </p>
              </div>
            ))}
            {important.length > 0 && (
              <div className="faq-modal__body-important">
                {important.map((item, index) => (
                  <div key={index} className="faq-modal__body-important-item">
                    <p>{item.title}</p>
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="faq-modal__body-questions">
            <div className="faq-modal__body-list">
              {faq.map((faq, index) => (
                <div
                  className={`faq-report-modal__faqs-question ${selectedQuestionIndex === index ? "active" : ""}`}
                  key={index}
                  onClick={() => handleQuestionClick(index)}
                >
                  {faq.question}
                  <div className={`faq-report-modal__faqs-question-img ${selectedQuestionIndex === index ? "active" : ""}`} />
                </div>
              ))}
            </div>
            {!isMobile && (type === "faq-in-report" ? (
              <Button title="Вернуться назад" onClick={handleBack} />
            ) : (
              <Button title="Вернуться к Моим проектам" onClick={handleClose} />
            ))}
          </div>
        </div>
      </div>
    </Popup>
  );
});
