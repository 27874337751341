import { Link } from "react-router-dom";

import Plate from "components/UI/Plate/Plate";
import Button from "components/UI/Button/Button";
import FoldableSection from "components/UI/FoldableSection/FoldableSection";
import { HowItWorksSteps } from "./HowItWorksSteps/HowItWorksSteps";
import InfoPoint from "components/UI/InfoPoint/InfoPoint";
import Icon from "components/UI/IconSVG/Icon";

import { EVENTS, metricsEvent } from "metrics";

import "./HowItWorks.scss";

export default function HowItWorks({ isExpanded, toggleExpanded, title, stepData, additionalContent, advicesText, stepPhrase1, stepPhrase2, isMobile }) {
  const plateClassName = `how-it-works ${isExpanded ? "other-padding" : ""}`;

  return (
    <Plate className={plateClassName}>
      <FoldableSection expanded={isExpanded}>
        {!isMobile &&
          <div className="how-it-works__header" onClick={toggleExpanded}>
            <p className="how-it-works__header-title">{title}</p>
            <Button className="how-it-works__header-button-more" title={isExpanded ? "Свернуть" : "Узнать"} />
          </div>
        }

        <div className="how-it-works__wrapper">
          <div className="how-it-works__advices-container">
            <div className="how-it-works__advices-content">
              {isMobile 
                ? <>
                    <div className="how-it-works__mobile-row">{stepData.slice(0, 2).map((step, index) => <HowItWorksSteps key={index} {...step} />)}</div>
                    <div className="how-it-works__mobile-single">{stepData.slice(2).map((step, index) => <HowItWorksSteps key={index + 2} {...step} />)}</div>
                  </>
                : stepData.map((step, index) => <HowItWorksSteps key={index} {...step} />)
              }
              <div className="how-it-works__additional-content">{additionalContent}</div>
            </div>
            {advicesText && <p className="how-it-works__advices-text">{advicesText}</p>}
          </div>

          <div className="how-it-works__create-project">
            <p className="how-it-works__create-project-title">Как создать проект?</p>
            <div className="how-it-works__create-project-points">
              <Icon sprite="another" name={isMobile ? "mobile-dino-step-first" : "dino-step-first"} />
              <div className="how-it-works__create-project-points-wrapper">
                <InfoPoint spriteIcon={{ sprite: "another", name: "icon-triangle" }} text={<p><span>Выберите площадку</span> для продвижения или проверьте уже выбранную площадку</p>} />
                <InfoPoint spriteIcon={{ sprite: "another", name: "icon-triangle" }} text={<h2>{stepPhrase1}</h2>} />
              </div>
            </div>
            <div className="how-it-works__create-project-points">
              {isMobile && <Icon sprite="another" name="mobile-dino-step-second" />}
              <div className="how-it-works__create-project-points-wrapper">
                <InfoPoint spriteIcon={{ sprite: "another", name: "icon-triangle" }} text={<h2>{stepPhrase2},{" "}<span>чтобы исполнителям было проще его найти</span></h2>}/>
                <InfoPoint spriteIcon={{ sprite: "another", name: "icon-triangle" }} text={<p>И не забудьте заполнить поля поисковой фразы. <span> Это очень важно!</span></p>}/>
              </div>
              {!isMobile && <Icon sprite="another" name="dino-step-second" />}
            </div>
          </div>

          <div className="how-it-works__buttons">
            <Link to={process.env.REACT_APP_SUPPORT_ADDRESS} target="_blank">
              <Button title="Хочу задать вопросы поддержке" className="how-it-works__button-support" onClick={() => metricsEvent(EVENTS.TELEGRAM_HELP)} />
            </Link>
            <Button title="Все понятно — приступим!" classes={["wide"]} onClick={toggleExpanded} />
          </div>
        </div>
      </FoldableSection>
    </Plate>
  );
}
