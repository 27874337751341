import { observer } from "mobx-react";
import { useState } from "react";

import Button from "components/UI/Button/Button";
import Icon from "components/UI/IconSVG/Icon";
import ProjectTitle from "pages/Projects/components/Project/ProjectTitle/ProjectTitle";
import ProjectsDraftsAbstractRow from "../ProjectsDraftsAbstractRow/ProjectsDraftsAbstractRow";
import ProjectLinkButton from "pages/Projects/components/ProjectLinkButton/ProjectLinkButton";
import ProjectCounters from "pages/Projects/components/Project/ProjectCounters/ProjectCounters";
import ProjectSettingsMobile from "pages/Projects/components/Project/mobile-ver/ProjectSettingsMobile/ProjectSettingsMobile";

import AppStore from "AppStore";
import popupStore from "components/Popup/PopupStore";

import {
  calcTotalProjectActions,
  findActivityCount,
  formatCity,
} from "../../../Projects/ProjectsConstants";

import "./ProjectsDraftsRow.scss";

const ProjectsDraftsRow = observer(({ project, isMobile }) => {
  const [isShowSettings, setIsShowSettings] = useState(false);
  const activities = project?.activities;
  const actions = calcTotalProjectActions(project).toLocaleString();
  const reviewsCount = findActivityCount(activities, "MAIN.REVIEW");
  const { price } = project;
  const isEditing = AppStore.editingTitle && AppStore.editingProjectId === project.id;

  if (!project) return null;

  return (
    <ProjectsDraftsAbstractRow className="projects-drafts-row">
      <div className="projects-drafts-row__title-container">
        <ProjectTitle store={AppStore} project={project} expanded={false} isMobile={isMobile} />
        {!isMobile && (isEditing
          ? <></>
          : <button type="button" aria-label="Редактировать" onClick={() => { AppStore.startEditingProjectTitle(project.id); AppStore.setEditingTitle(true); AppStore.setNewTitle(project.title); }}>
              <Icon className="projects-drafts-row__button-edit" sprite="projects" name="edit" color="#1E1E1E" />
            </button>
        )}
        {isMobile && 
          <button type="button" aria-label="Открыть все настройки" onClick={() => { popupStore.open("project-settings-mobile"); setIsShowSettings(true); }}>
            <Icon className="projects-drafts-row__button-mobile-config" sprite="projects" name="mobile-config" color="#1E1E1E" width={4} height={18} />
          </button>
        }
      </div>
      <div className="projects-drafts-row__info">
        {!isMobile && <ProjectLinkButton style={{ visibility: project.link ? "visible" : "hidden" }} link={project.link} />}
        <div className="projects-drafts-row__info-status">Ожидает оплаты</div>
        <ProjectCounters actions={actions} reviewsCount={reviewsCount} projectBots={project.bots} projectTest={project.testing} />
        <div className="projects-archive-row__info-city">{formatCity(project.city)}</div>
      </div>
      <div className="projects-drafts-row__buttons">
        <Button title={price ? "Списать" : "Запустить"} boldText={price ? `${project.price.toLocaleString()} ₽` : undefined} onClick={() => { AppStore.payProject(project) }} />
        <button type="button" aria-label="Удалить" className="projects-drafts-row__wrapper" onClick={() => { AppStore.deleteProjectWithConfirm(project) }}>
          <Icon className="projects-drafts-row__wrapper-delete-icon" sprite="projects" name="delete" color="#FF7373" />
        </button>
      </div>

      {isMobile && isShowSettings && <ProjectSettingsMobile store={AppStore} project={project} isMobile={isMobile} />}
    </ProjectsDraftsAbstractRow>
  );
});

export default ProjectsDraftsRow;
