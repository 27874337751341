import { observer } from "mobx-react";
import { useEffect, useState } from "react";

import ActionLink from "./ActionLink/ActionLink";

import "./ActivityList.scss";

const ActivityList = observer(({ store, isMobile }) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { market, currentCalculator } = store.currentMarketStore;
  const hasReviewAction = currentCalculator.actions.some(action => action.type === 'MAIN.REVIEW');

  const gptReviewAction = hasReviewAction ? {
    title: "Генерация отзыва от GPT",
    titleComment: "не пишите сами — GPT сделает это за вас",
    icon: "home-magic",
    path: 'https://ai.boostclick.ru/',
    target: '_blank',
    isGPT: true,
  } : null;

  const formatAndOrderActions = () => {
    const orderedActions = [
      market.modes[0],
      ...market.modes.slice(1),
      ...market.modes[0].actions.filter(action => !action.hidden)
    ];

    return orderedActions
      .map(action => {
        const formattedAction = { 
          ...action, 
          icon: action.iconForHomePage || action.iconHome 
        };

        if (action.title === "Боты 🤖") {
          return {
            ...formattedAction,
            title: "ПФ боты 🤖"
          };
        }
  
        if (action.title === "Реальные люди 🙋🙋‍♀️") {
          return {
            ...formattedAction,
            title: "ПФ реальные люди 🙋🙋‍♀️"
          };
        }

        if (action.title === "ПФ") {
          return {
            ...formattedAction,
            title: "Поведенческие факторы",
            titleComment: `Накрутка ПФ ${['telegram', 'vk'].includes(market.id)? "в" : "на"} ${market.id === "avito" ? "досках объявлений" : market.title}`
          };
        }
        return formattedAction;
      })
      .slice(0, gptReviewAction ? 8 : 9);
  };

  const getMarketTitle = (index) => {
    if (market.id === "avito") {
      return index < 4 ? "на досках объявлений" : "";
    }
    return market.title;
  };

  const combinedActions = formatAndOrderActions();
  const displayActions = [
    ...combinedActions, 
    ...(gptReviewAction ? [gptReviewAction] : [])
  ];

  useEffect(() => {
    setIsAnimated(true);
    const timer = setTimeout(() => setIsAnimated(false), 300);
    return () => clearTimeout(timer);
  }, [market]);

  return (
    <div className={`main__services_activity ${isAnimated ? 'animated' : ''}`}>
      <div className={`main__services_activity-list ${isAnimated ? 'animated' : ''}`}>
        {displayActions.map((action, index) => (
          <ActionLink
            key={index}
            action={action}
            path={action.path || currentCalculator.mode.path}
            isMobile={isMobile}
            isAnimated={isAnimated}
            marketTitle={action.isGPT ? "" : (action.title === "Поведенческие факторы" ? "" : getMarketTitle(index))}
            status={action.statusForHomePage}
            target={action.target}
          />
        ))}
      </div>
    </div>
  );
});

export default ActivityList;