import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';

import ProjectsActive from './ProjectsActive/ProjectsActive';
import ProjectsDrafts from './ProjectsDrafts/ProjectsDrafts';
import ProjectsArchive from './ProjectsArchive/ProjectsArchive';
import { ButtonWithIcon } from 'components/UI/ButtonWithIcon/ButtonWithIcon';
import Preloader from 'components/Preloader/Preloader';

import AppStore from 'AppStore';

import plusIcon from "assets/images/plus.svg";
import { EVENTS, metricsEvent } from 'metrics';
import { useMatchMedia } from 'utils/hooks/use-match-media';

import "./Projects.scss";

/**
 * @param {Object[]} projects
 * @param {String[]} statuses
 */
function filterProjectsByStatuses(projects, statuses) {
  return projects?.filter((project) => statuses.includes(project.status));
}

/**
 * @param {Object[]} projects
 * @param {String} status
 */
function filterProjectsByStatus(projects, status) {
  return filterProjectsByStatuses(projects, [status]);
}

/**
 * Проверяет наличие проектов с определенным типом исполнителя
 * @param {Object[]} projects
 * @param {String} executorType - тип исполнителя ("humans" или "bots")
 * @returns {Boolean}
 */
function hasProjectsWithExecutorType(projects, executorType) {
  if (!projects || !projects.length) return false;
  
  const hasBots = executorType === "bots";
  return projects.some(project => hasBots ? project.bots : !project.bots);
}

export const Projects = observer(() => {
  const { isMobile } = useMatchMedia();
  const [isLoading, setIsLoading] = useState(true);

  const tabs = useMemo(
    () => [
      {
        id: "active",
        label: isMobile ? "Активные" : "Активные проекты",
        component: ProjectsActive,
        projects: AppStore.projects
        ? filterProjectsByStatuses(AppStore.projects, ["running", "failed"])
            .filter(project => AppStore.executorType === "humans" ? !project.bots : project.bots)
        : [],
        count:
          filterProjectsByStatuses(AppStore.projects, ["running", "failed"])
            ?.length ?? 0,
      },
      {
        id: "drafts",
        label: `Черновики`,
        component: ProjectsDrafts,
        projects: AppStore.projects
        ? filterProjectsByStatus(AppStore.projects, "new")
            .filter(project => AppStore.executorType === "humans" ? !project.bots : project.bots)
        : [],
        count: filterProjectsByStatus(AppStore.projects, "new")?.length ?? 0,
      },
      {
        id: "archive",
        label: `Архив`,
        component: ProjectsArchive,
        projects: AppStore.projects
        ? filterProjectsByStatus(AppStore.projects, "finished")
            .filter(project => AppStore.executorType === "humans" ? !project.bots : project.bots)
        : [],
        count:
          filterProjectsByStatus(AppStore.projects, "finished")?.length ?? 0,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [AppStore.projects, AppStore.executorType]
  );

  const handleExecutorTypeChange = (type) => {
    AppStore.setExecutorType(type);
  };

  useEffect(() => {
    window.scroll(0, 0);
    AppStore.updateProjects()
    .then(() => {
      if (
        AppStore.executorType === "humans" && 
        !hasProjectsWithExecutorType(AppStore.projects, "humans") && 
        hasProjectsWithExecutorType(AppStore.projects, "bots")
      ) {
        AppStore.setExecutorType("bots");
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      AppStore.checkReadyReportsAndShowWarning();
    }, 43200000);

    return () => clearInterval(interval);
  }, []);

  const handleReloadHomePage = () => {
    metricsEvent(EVENTS.PROJECT_ADD);
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <main className="projects">
      <Helmet>
        <title>Мои проекты — Boostclick</title>
        <meta name="description" content="Мои проекты в сервисе Boostclick" />
        <meta name="robots" content="noindex" />
      </Helmet>

      <div className="projects__inner">
        {/* <ProjectsFilter /> */}
        <div className='projects__header'>
          <h1 className='projects__header-title'>Мои проекты</h1>
            <ButtonWithIcon
              className="projects__header-add-button"
              title={isMobile ? "" : 'Добавить проект'}
              icon={plusIcon}
              onClick={handleReloadHomePage}
              ariaLabel="Добавить новый проект"
              to="/"
              target='_self'
            />
            {isMobile && <button className="projects__header-scroll-button" type="button" aria-label={`Вверх страницы`} onClick={handleScrollToTop} />}
        </div>

        <div className="projects__tabs-container">
          <div className="projects__tabs">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`projects__tab ${AppStore.activeTab === tab.id ? "projects__tab-active" : ""}`}
                type="button"
                aria-label={`Вкладка ${tab.label}`}
                onClick={() => AppStore.setActiveTab(tab.id)}
              >
                {!isMobile && `${tab.label} (${tab.count})`}
                {isMobile && `${tab.label}`}
              </button>
            ))}
          </div>
          <div className="projects__tab-content">
            <div className="projects__tab-executor">
              <p>Выберите тип исполнителей:</p>
              <div className="projects__tab-executor-buttons">
                <button className={`projects__tab-executor-button-humans ${AppStore.executorType === "humans" ? "active" : ""}`} onClick={() => handleExecutorTypeChange("humans")}>Люди 🙋🙋‍♀️</button>
                <button className={`projects__tab-executor-button-bots ${AppStore.executorType === "bots" ? "active" : ""}`} onClick={() => handleExecutorTypeChange("bots")}>Боты 🤖</button>
              </div>
            </div>

            {isLoading 
              ? <Preloader />
              : tabs.map((tab) => AppStore.activeTab === tab.id && (<tab.component key={tab.id} projects={tab.projects} isMobile={isMobile} />))
            }
          </div>
        </div>
      </div>
    </main>
  );
});
