import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";

import { Hint } from "components/UI/Hint/Hint";

import "./WorkerBadge.scss";

const PRO_DATA = {
  emoji: "⭐️",
  label: "PRO",
  title: "Задание сделал опытный исполнитель",
  description: "Выполнил > 150 заданий, с минимальным процентом отклоненных отчетов"
};

const NEW_DATA = {
  emoji: "🌱",
  label: "NEW",
  title: "У этого исполнителя мало опыта на бирже",
  description: "Новые исполнители ошибаются чаще. Рекомендуем проверить отчет внимательнее, а при сомнениях запросить доработку"
};

const WorkerBadge = observer(({ isPro, isMobile }) => {
  const [showHint, setShowHint] = useState(false);
  const badgeRef = useRef(null);

  const data = isPro ? PRO_DATA : NEW_DATA;
  const badgeClass = `worker-badge ${isPro ? 'pro-badge' : 'new-badge'}`;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (badgeRef.current && !badgeRef.current.contains(event.target)) {
        setShowHint(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  if (isPro === undefined) return null;

  return (
    <div className={badgeClass} ref={badgeRef} onMouseEnter={() => setShowHint(true)} onMouseLeave={() => setShowHint(false)}>
      {`${data.emoji} ${data.label}`}
      {isMobile && showHint && <div className="worker-badge__bg" />}
      {showHint &&
        <Hint
          title={<p><span>{data.emoji}</span> {isMobile && <br />} {data.title}</p>}
          text={data.description}
          buttonText={isMobile ? "Понятно" : ""}
          onClick={() => setShowHint(false)}
        />
      }
    </div>
  );
});

export default WorkerBadge;