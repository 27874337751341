import { observer } from "mobx-react";

import Icon from "components/UI/IconSVG/Icon";

import "./StatusEquipment.scss";

const StatusEquipment = observer(({ expiryDate }) => {
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU');
  };

  return (
    <div className="status-equipment">
      <div className={`status-equipment__info ${!expiryDate ? "not-paid" : ""}`}>
        <div className="status-equipment__info-paid">
          {expiryDate 
            ? <Icon sprite="another" name="paid-equipment" width={16} height={16} /> 
            : <Icon sprite="another" name="not-paid-equipment" width={16} height={16} /> 
          }
          <p>{expiryDate ? "Оборудование оплачено" : "Оборудование отключено"}</p>
        </div>
        <span>{expiryDate ? `до ${formatDate(expiryDate)}` : "(Оплата истекла)"}</span>
      </div>
    </div>
  );
});

export default StatusEquipment;
