import { observer } from "mobx-react";

import ProjectsActiveReportAuctionVisibilityPercentage from "./ProjectsActiveReportAuctionVisibilityPercentage/ProjectsActiveReportAuctionVisibilityPercentage";

import "./ProjectsActiveReportAuctionVisibility.scss";

const ProjectsActiveReportAuctionVisibility = observer(({ store, report, isMobile }) => {
  const extraPrice = report?.extraPrice || 0;
  const addition = store.markValues[store.value];
  const percentage = store.getPercentageWithAddition(store.baseAmount, store.basePercentage, extraPrice, addition);

  return (
    <div className="projects-active-report-auction-visibility">
      {isMobile && <p className="projects-active-report-auction-visibility__title">Ваша ставка сейчас:</p>}
      <ProjectsActiveReportAuctionVisibilityPercentage percentage={percentage} report={report} isMobile={isMobile} />
      {!isMobile && 
        <p className="projects-active-report-auction-visibility__title">
          индекс привлекательности задания
        </p>
      }
    </div>
  );
});

export default ProjectsActiveReportAuctionVisibility;
