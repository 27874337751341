import { observer } from "mobx-react";

import Icon from "components/UI/IconSVG/Icon";
import { BudgetSlider } from "components/BudgetSlider/BudgetSlider";

import { getCountActions } from "utils/helpers";

import "./HumansCalculator.scss";

const HumansCalculator = observer(
  ({
    store,
    mode,
    pathname,
    currentCalculatorPrice,
    showRecommendation,
    getDisplayCount,
    getAllActionCount,
    isMobile
  }) => {
    return (
      <div className="humans-calculator">
        {!isMobile && <p className="humans-calculator__title">Общая стоимость{!showRecommendation ? "*" : ""}</p>}

        <div className="humans-calculator__wrapper">
          <BudgetSlider store={store}>
            <p className="humans-calculator__price">{`${currentCalculatorPrice.toLocaleString()} ₽`}</p>

            <div className="humans-calculator__info">
              <div className="humans-calculator__info-actions">
                <p>≈</p>
                <Icon className="humans-calculator__info-actions-icon" sprite="activities" name="action" color="#4F4F4F" />
                <p>{`${getAllActionCount(store.currentCalculator, pathname)} ${getCountActions(getAllActionCount(store.currentCalculator, pathname))}`}</p>
              </div>

              {getDisplayCount(store.currentCalculator, pathname) && (
                <div className={`humans-calculator__info-reviews ${mode.actions.find((action) =>action.type.includes("REVIEW"))? "": "hidden"}`}>
                  <p>≈</p>
                  <Icon className="humans-calculator__info-reviews-icon" sprite="activities" name="message" color="#4F4F4F" />
                  <p>{getDisplayCount(store.currentCalculator, pathname)}</p>
                </div>
              )}
            </div>
          </BudgetSlider>
        </div>
      </div>
    );
  }
);

export default HumansCalculator;
