import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { CalculatorStep } from "./CalculatorStep/CalculatorStep";
import { SettingsStep } from "./SettingsStep/SettingsStep";
import { SettingsSeoStep } from "./SettingsSeoStep/SettingsSeoStep";
import { SettingsSeoStepBots } from "./SettingsSeoStep/SettingsSeoStepBots/SettingsSeoStepBots";
// import MarketSelection from "./CalculatorStep/componentsCalculatorStep/MarketSelection/MarketSelection";
import HowItWorks from "components/HowItWorks/HowItWorks";
import IntroWithPoints from "components/IntroWithPoints/IntroWithPoints";
import Answers from "components/Answers/Answers";

import { useMatchMedia } from "utils/hooks/use-match-media";

import AppStore from 'AppStore';
import popupStore from "components/Popup/PopupStore";

import { HOW_IT_WORKS_TITLE, STEP2_TITLE_LOOKING_PERFORMER, STEP2_DESCRIPTION_LOOKING_PERFORMER, STEP3_TITLE_CHECK_PAY_REPORT, STEP3_DESCRIPTION_CHECK_PAY_REPORT } from "./marketConstants";

import "./Market.scss";

const promoData = [
  {
    spriteName: "another",
    icon: "icon-step-first-main",
    step: "number-step-first",
    title: <>Вы создаете <br /> задачу</>,
    text: 
    <>
      Она попадает в <Link to={AppStore.isAuth ? "/projects" : "/signin"}  className="how-it-works__advices-link">Черновики</Link>
      <br />
      Там вы можете оплатить ее, сперва пополнив счет в&#160;
      {AppStore.isAuth ? <button className="how-it-works__advices-button" type="button" aria-label="Открыть пополнение баланса" onClick={() => popupStore.open("paymodal")}>Личном кабинете</button>
      : <Link to="/signin" className="how-it-works__advices-link">Личном кабинете</Link>}
    </>
  },
  {
    spriteName: "another",
    icon: "icon-step-second-main",
    step: "number-step-second",
    title: STEP2_TITLE_LOOKING_PERFORMER,
    text: STEP2_DESCRIPTION_LOOKING_PERFORMER,
  },
  {
    spriteName: "another",
    icon: "icon-step-third-main",
    step: "number-step-third",
    title: STEP3_TITLE_CHECK_PAY_REPORT,
    text: STEP3_DESCRIPTION_CHECK_PAY_REPORT,
  },
];

const Market = observer(({ noindex = false, title, description, introTitle }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { isMobile } = useMatchMedia();
  const location = useLocation();
  const lastHash = useRef("");
  const calculatorStepRef = useRef();
  const settingsStepRef = useRef();
  const linkSettingsStepRef = useRef(null);
  const categorySettingsStepRef = useRef(null);
  const citySettingsStepRef = useRef(null);
  const searchPhrasesSettingsStepRef = useRef(null);
  const screenshotSettingsStepRef = useRef(null);
  const reviewStep2Ref = useRef(null);
  const commentStep2Ref = useRef(null);
  const brandPhrasesSettingsStepRef = useRef(null);

  const calculator = AppStore.currentMarketStore.currentCalculator;
  const { mode } = calculator;
  const meta = mode.meta;

  const market = AppStore.currentMarketStore.calculators[0].market;
  const isSettingsSeo = market.isSeo;
  const isSettingsMaps = market.isMaps;

  const renderSettingsComponent = () => {
    if (mode.bots) {
      return (
        <SettingsSeoStepBots 
          key={isMobile ? "mobile" : "desktop"}
          store={AppStore.currentMarketStore}
          linkRef={linkSettingsStepRef}
          cityRef={citySettingsStepRef}
          searchPhrasesRef={searchPhrasesSettingsStepRef}
          scrollToError={scrollToError}
        />
      );
    }

    if (isSettingsSeo || isSettingsMaps) {
      return (
        <SettingsSeoStep
          key={isMobile ? "mobile" : "desktop"}
          store={AppStore.currentMarketStore}
          linkRef={linkSettingsStepRef}
          cityRef={citySettingsStepRef}
          searchPhrasesRef={searchPhrasesSettingsStepRef}
          screenshotRef={screenshotSettingsStepRef}
          brandPhrasesRef={brandPhrasesSettingsStepRef}
          scrollToError={scrollToError}
        />
      );
    }

    return (
      <SettingsStep
        key={isMobile ? "mobile" : "desktop"}
        store={AppStore.currentMarketStore}
        linkRef={linkSettingsStepRef}
        categoryRef={categorySettingsStepRef}
        cityRef={citySettingsStepRef}
        searchPhrasesRef={searchPhrasesSettingsStepRef}
        screenshotRef={screenshotSettingsStepRef}
        reviewRef={reviewStep2Ref}
        commentRef={commentStep2Ref}
        scrollToError={scrollToError}
      />
    );
  };

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const scrollToError = (errorRef) => {
    if (errorRef.current) {
      const headerHeight = 100;
      const rect = errorRef.current.getBoundingClientRect();
      const offsetTop = rect.top + window.scrollY - headerHeight;
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (location.hash) lastHash.current = location.hash.slice(1);

    if (!lastHash.current || !document.getElementById(lastHash.current))
      return;

    setTimeout(() => {
      document
        .getElementById(lastHash.current)
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
      lastHash.current = "";
    }, 0);
  }, [location]);

  const intro = mode.intro;
  if (introTitle)
    intro.title = introTitle;

  return (
    <main className="market">
      <Helmet>
        <title>{title || meta.title}</title>
        <meta name="description" content={description || meta.description} />
        <meta name="robots" content={noindex ? "noindex" : "all"} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>

      <div className="market__inner">
        {!AppStore.isAuth && <IntroWithPoints {...intro} type={mode.id} marketMode={mode} hiddenContent={mode.hiddenContent} isMobile={isMobile} />}

        <div className="market__interactive">
          <div className="market__interactive-wrapper">
            <div ref={settingsStepRef} className="market__interactive-title">
              <p>Шаг 1<span>/2</span></p><h2 className="market__interactive-title__steps-total">{mode.stepPhrase1}</h2>
            </div>
            {/* <MarketSelection store={AppStore.currentMarketStore} /> */}
            <CalculatorStep
              key={isMobile ? "mobile" : "desktop"}
              store={AppStore.currentMarketStore}
              nextStep={calculatorStepRef}
              reviewRef={reviewStep2Ref}
              commentRef={commentStep2Ref}
              scrollToError={scrollToError}
            />
          </div>

          <div className="market__interactive-wrapper">
            <div ref={calculatorStepRef} className="market__interactive-title">
              <p>Шаг 2<span>/2</span></p><h2 className="market__interactive-title__steps-total">{mode.stepPhrase2}</h2>
            </div>
            {renderSettingsComponent()}
          </div>
        </div>

        {isMobile && <>
          <div className="how-it-works__button-open">
            <p className={`how-it-works__button-open-text ${isExpanded ? "other-color" : ""}`} onClick={toggleExpanded}>🤔 Как работает биржа заданий?</p>
            {isExpanded && <HowItWorks isExpanded={isExpanded} toggleExpanded={toggleExpanded} title={HOW_IT_WORKS_TITLE} stepData={promoData} additionalContent={<ul className="how-it-works__advice">{mode.howItWorks.map((advice, i) => <li key={i} className="how-it-works__advice-item">{advice}</li>)}</ul>} stepPhrase1={mode.stepPhrase1} stepPhrase2={mode.stepPhrase2} isMobile={isMobile} />}
          </div>
        </>}
        {!isMobile && !mode.bots && <HowItWorks isExpanded={isExpanded} toggleExpanded={toggleExpanded} title={HOW_IT_WORKS_TITLE} stepData={promoData} additionalContent={<ul className="how-it-works__advice">{mode.howItWorks.map((advice, i) => <li key={i} className="how-it-works__advice-item">{advice}</li>)}</ul>} stepPhrase1={mode.stepPhrase1} stepPhrase2={mode.stepPhrase2} isMobile={isMobile} />}

        <Answers title={mode.titleFAQ} questions={mode.faq} />
      </div>
    </main>
  );
});

export default Market;
