import { observer } from "mobx-react";
import { useEffect, useState } from "react";

import { TextInput } from "components/UI/TextInput/TextInput";
import Button from "components/UI/Button/Button";

import AppStore from "AppStore";

import "./ProjectEditingTitle.scss";

const ProjectEditingTitle = observer(({ project, initialValue, onSave, isMobile }) => {
  const [newTitle, setNewTitle] = useState('');
  
  useEffect(() => {
    if (initialValue) {
      setNewTitle(initialValue);
    }
  }, [initialValue]);

  const handleSave = () => {
    if (onSave) {
      onSave(newTitle);
    } else {
      AppStore.updateProjectDetails(project, { title: newTitle });
      AppStore.setEditingTitle(false);
    }
  };

  return (
    <>
      {AppStore.editingTitle && AppStore.editingProjectId === project.id && (
        <div className="project-editing-title">
          <TextInput
            placeholder="Введите название проекта"
            type="text"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
          {!isMobile && <button
            className="project-editing-title__button-save"
            onClick={handleSave}
          />}

          {isMobile &&
            <div className="project-editing-title__buttons">
              <Button title="Сохранить изменения" onClick={handleSave} />
              <Button className="project-editing-title__button-cancel" title="Отмена" onClick={() => AppStore.setEditingTitle(false)} />
              </div>
          }
        </div>
      )}
    </>
  );
});

export default ProjectEditingTitle;
