import { observer } from "mobx-react";

import Tooltips from "components/UI/Tooltips/Tooltips";
import ProjectEditingTitle from "../../ProjectSetupModal/ProjectEditingTitle/ProjectEditingTitle";

import "./ProjectTitle.scss";

const ProjectTitle = observer(({ store, project, onTitleClick, isMobile }) => {
  const showEditingUI = store.editingTitle && store.editingProjectId === project.id && !isMobile;

  return (
    <div className="project-title" onClick={onTitleClick}>
      {showEditingUI ? (
        <ProjectEditingTitle project={project} isMobile={isMobile} />
      ) : (
        <Tooltips description={project.fullTitle}>
          <div className="project-title__container">
            <p>{project.titleToShow.split(" (#")[0]}</p>
            <p>{`(#${project.id})`}</p>
          </div>
        </Tooltips>
      )}
    </div>
  );
});

export default ProjectTitle;
