import { observer } from "mobx-react";

import Button from "components/UI/Button/Button";
import ProjectPropertyColumn from "../ProjectPropertyColumn";
import StatusEquipment from "../../StatusEquipment/StatusEquipment";

import AppStore from "AppStore";

import { SERVER_PRICE } from "models/markets/YandexSeo";
import { calcTotalProjectActions } from "pages/Projects/ProjectsConstants";

import "./BotColumns.scss";

const TOPVISOR_ID = process.env.REACT_APP_TOPVISOR_ID;

const STATS_ICONS = [
  { name: 'ya-webmaster', link: 'https://webmaster.yandex.ru/' },
  { name: 'ya-metrika', link: 'https://metrika.yandex.ru/',
    // временный фикс
    svg: (
      <svg width="26" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="25">
            <path d="M12.57 24.96c6.94 0 12.568-5.588 12.568-12.48C25.138 5.587 19.511 0 12.57 0 5.627 0 0 5.587 0 12.48c0 6.892 5.627 12.48 12.57 12.48z" fill="#fff"/>
          </mask>
          <g mask="url(#a)">
            <path d="M0 0h25.138v24.96H0V0z" fill="url(#b)"/>
            <path d="M0 15.357h7.735v9.6H0v-9.6z" fill="url(#c)"/>
            <path d="M7.734 12.286c0-1.613 0-2.42.317-3.036a2.89 2.89 0 0 1 1.267-1.258c.62-.314 1.433-.314 3.057-.314h5.028v17.28H7.734V12.286z" fill="url(#d)"/>
            <path d="M17.402-.002h7.735v24.96h-7.735V-.002z" fill="url(#e)"/>
          </g>
        </g>
        <defs>
          <linearGradient id="b" x1="18.015" y1="18.095" x2="-2.529" y2="-10.747" gradientUnits="userSpaceOnUse">
            <stop stopColor="#4643B9"/>
            <stop offset="1" stopColor="#1E8AFF"/>
          </linearGradient>
          <linearGradient id="c" x1="8.244" y1="21.636" x2="-14.61" y2="17.627" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FF002E"/>
            <stop offset="1" stopColor="#FFADA1"/>
          </linearGradient>
          <linearGradient id="d" x1="67.196" y1="62.878" x2="72.871" y2="16.128" gradientUnits="userSpaceOnUse">
            <stop stopColor="#3C3BA0"/>
            <stop offset=".49" stopColor="#1E8AFF"/>
            <stop offset="1" stopColor="#00B2FF"/>
          </linearGradient>
          <linearGradient id="e" x1="17.402" y1="1.282" x2="39.228" y2="12.64" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFEA1A"/>
            <stop offset="1" stopColor="#FFB800"/>
          </linearGradient>
        </defs>
      </svg>
    )
   },
  {
    name: 'ya-topvisor',
    link: `https://topvisor.com/${TOPVISOR_ID ? `?inv=${TOPVISOR_ID}` : ''}`,
  },
];

const FAQ_TOOLTIP_TEXT = <p>Более глубокая статистика работы бота на сторонних сервисах <br /><br /> <strong>Важно:</strong> Перед использованием убедитесь, что на продвигаемом сайте настроена аналитика</p>;

const TWO_DAYS_IN_MS = 2 * 24 * 60 * 60 * 1000;

const BotColumns = observer(({ project, report, status, statusText, activityDate, hasBotsInType }) => {
  const actions = calcTotalProjectActions(project);

  const paid = AppStore.profile?.serverRentedUntilDatetime;
  const shouldShowRenewButton = () => {
    const currentTime = new Date().getTime();
    const expiryDate = new Date(paid).getTime();
    return currentTime > expiryDate || (expiryDate - currentTime <= TWO_DAYS_IN_MS);
  };

  // TODO: добавить функцию продления
  const handleRenewServer = async () => {
    try { } catch (error) { }
  }

  return (
    <>
      <div className="bot-columns">
        <ProjectPropertyColumn title="Дата активностей" text={activityDate} />
        <ProjectPropertyColumn title="Статус" text={statusText} />
        <ProjectPropertyColumn title="Кол-во действий" text={`≈ ${actions / 1000}k 🤖`} />
        <ProjectPropertyColumn title="Город" text={project.city ? project.city : "—"} status={status} />
        <ProjectPropertyColumn title="Ключевые запросы" titleIcon="copy" text={report.searchPhrases || "—"} project={project} status={status} className="project-property-column__key-phrases" />
        <ProjectPropertyColumn title="Статистика" tooltipContent={FAQ_TOOLTIP_TEXT} icons={STATS_ICONS} showTooltip />
      </div>

      <div className="projects-active-row__info_equipment">
        {hasBotsInType && !project.pricing?.useCommunalServer && <StatusEquipment expiryDate={AppStore.profile?.serverRentedUntilDatetime} />}
        {!project.testing && project.bots && shouldShowRenewButton() && <Button className="projects-active-row__extend-button" title="Продлить за" boldText={`${SERVER_PRICE.toLocaleString()} ₽`} onClick={handleRenewServer} />}
      </div>
    </>
  );
});

export default BotColumns;
