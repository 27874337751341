import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import Icon from "components/UI/IconSVG/Icon";
import Faqed from "components/UI/Faqed/Faqed";
import { Hint } from "components/UI/Hint/Hint";
import { KeyPhrasesPopup } from "./BotColumns/KeyPhrasesPopup/KeyPhrasesPopup";

import popupStore from "components/Popup/PopupStore";

import "./ProjectPropertyColumn.scss";

export default function ProjectPropertyColumn({
  project,
  title,
  text,
  className,
  activityIcon,
  titleIcon,
  status,
  color,
  timer,
  tooltipContent,
  icons,
  showTooltip,
}) {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setTooltipVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`project-property-column ${status}`}>
      <div className="project-property-column__title">
        {title}
        {titleIcon && (
          <div className="project-property-column__title-icon-wrapper">
            <Icon className="project-property-column__title-icon" sprite="projects" name={titleIcon} />
          </div>
        )}
        {showTooltip && tooltipContent && <Faqed text={tooltipContent} customPlateStyle={{ left: "auto", right: 0 }} />}
      </div>
      <div className={`project-property-column__text ${className} ${titleIcon ? "hidden-text" : ""}`} ref={tooltipRef} onClick={() => setTooltipVisible(true)} style={{ color }}>
        {activityIcon && <Icon className="project-property-column__icon" sprite="activities" name={activityIcon} color="#FFE14B" />}
        {text}
        {isTooltipVisible && Array.isArray(text) && <Hint text={text?.slice(0, 2).map((phrase, index) => <div key={index}>{phrase}</div>)} buttonText="Смотреть все" onClick={() => { popupStore.open("key-phrases"); setShowPopup(true); }} />}
        {icons &&
          <div className="project-property-column__icons">
            {icons.map((icon, index) =>
              <Link key={index} className="project-property-column__icon-link" to={icon.link} target="_blank" rel="noopener noreferrer">
                {icon.svg || <Icon className="project-property-column__stat-icon" sprite="projects" name={icon.name} width={26} height={26} />}
              </Link>
            )}
          </div>
        }
      </div>
      {timer &&
        <div className="project-property-column__timer">
          <div className="project-property-column__timer-icon" />
          <p>{timer}</p>
        </div>
      }
      {showPopup && <KeyPhrasesPopup project={project} phrases={text} />}
    </div>
  );
}
