import { observer } from "mobx-react";

import ProjectPropertyColumn from "../ProjectPropertyColumn";
import Faqed from "components/UI/Faqed/Faqed";

import {
  AUTO_AI_DATE_TEXT,
  formatTime,
} from "pages/Projects/ProjectsConstants";

import "./StandardColumns.scss";
import { ProjectPropertyColumnReviews } from "../../ProjectPropertyColumnReviews/ProjectPropertyColumnReviews";

const StandardColumns = observer(({ project, report, status, statusMap, activityDate, activityType, activityIcon, reportTimer, isDayLeft, renderAutoReworkTooltip, isReview }) => {
    const { text: statusText } = statusMap[status] || {};

    return (
      <div className="standard-columns">
        {isReview 
          ? <ProjectPropertyColumnReviews project={project} reportTimer={reportTimer} title="Тип активности" text={activityType} activityIcon={activityIcon} status={status} />
          : <ProjectPropertyColumn title="Тип активности" text={activityType} activityIcon={activityIcon} status={status} />
        }
        <ProjectPropertyColumn
          title="Статус"
          text={report.autoReworkReason && status === "run" ? renderAutoReworkTooltip(report.autoReworkReason) : statusText}
          className={`status-${status}`}
          timer={status === "ready" && !isDayLeft ? formatTime(reportTimer.timeLeft) : ""}
          color={statusMap[status].color}
        />
        <ProjectPropertyColumn title="Отчет" text={`#${report.id}`} status={status} />
        <ProjectPropertyColumn title="Город" text={project.city ? project.city : "—"} status={status} />
        <ProjectPropertyColumn title="Поисковая фраза" text={report.fullSearchPhrase || "—"} status={status} />
        <div className="projects-active-report-row__report-data">
          <ProjectPropertyColumn
            title="Дата активности"
            text={activityDate ? activityDate : <Faqed text={AUTO_AI_DATE_TEXT} customPlateStyle={{ left: "calc(-150px)", textAlign: "left" }}>Auto AI</Faqed>}
            status={status}
            className="projects-active-report-row__report-data-column"
          /> 
        </div>
      </div>
    );
  }
);

export default StandardColumns;
