import { observer } from "mobx-react";

import { ActivitiesCalculator } from "../componentsCalculatorStep/ActivitiesCalculator/ActivitiesCalculator";
import ModeSelection from "../componentsCalculatorStep/ModeSelection/ModeSelection";
import BotsCalculator from "../componentsCalculatorStep/CalculatorStep/BotsCalculator/BotsCalculator";
import HumansCalculator from "../componentsCalculatorStep/CalculatorStep/HumansCalculator/HumansCalculator";
import Recommendation from "../componentsCalculatorStep/Recommendation/Recommendation";
import Faqed from "components/UI/Faqed/Faqed";
import { Notice } from "../componentsCalculatorStep/Notice/Notice";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import Icon from "components/UI/IconSVG/Icon";
import DeadlineSelector from "../componentsCalculatorStep/DeadlineSelector/DeadlineSelector";

import upDoubleArrow from "assets/images/upDoubleArrow.svg";

import "./IsDesktopCalculatorStep.scss";

export const IsDesktopCalculatorStep = observer(
  ({
    store,
    bots = false,
    calculator,
    pathname,
    reviewRef,
    commentRef,
    getDisplayCount,
    getAllActionCount,
    onClickNext,
    showRecommendation,
    currentCalculatorPrice,
    isTestPackageSelected,
    titleModeSelection,
    descriptionModeSelection,
    isMobile
  }) => {
    const { market, mode } = calculator;
    const notice = mode.notice;

    return (
      <div className="market-step2">
        <div className="market-step2__distribution">
          {store.calculators.filter(calculator => !!calculator.mode.bots === bots).length > 1 && <ModeSelection store={store} bots={bots} title={titleModeSelection} />}
          <Faqed text={mode.stepTwoTitleFaq}>
            <Icon className="market-step2-mobile__distribution-icon" name={market.icon} />
            <p className="market-step2__distribution-title">{mode.calculatorTitle}</p>
          </Faqed>
          {market.isSeo && store.calculators.length > 1 && <ModeSelection store={store} title={titleModeSelection} description={descriptionModeSelection} />}
          <ActivitiesCalculator store={store} reviewRef={reviewRef} commentRef={commentRef} />
          {mode.bots && <Recommendation market={market} isEditGrayVisible={false} hasBots={mode.bots} />}
        </div>

        <div className="market-step2__settings">  
          {/* {store.calculators.some(calculator => calculator.mode.bots) && <ExecutorSelection store={store} />} */}
          <div className="market-step2__settings-container">
            {mode.bots
              ? (!calculator.store.testing && <BotsCalculator store={store} modeBots={mode.bots} currentCalculatorPrice={currentCalculatorPrice} isTestPackageSelected={isTestPackageSelected} isMobile={isMobile} />)
              : <HumansCalculator store={store} mode={mode} pathname={pathname} currentCalculatorPrice={currentCalculatorPrice} showRecommendation={showRecommendation} getDisplayCount={getDisplayCount} getAllActionCount={getAllActionCount} isMobile={isMobile} />
            }
            <DeadlineSelector store={store} title={mode.bots ? "Выберите пакет продвижения:" : "Выберите скорость отправки заданий исполнителям:"} isTestPackageSelected={isTestPackageSelected} isMobile={isMobile} />
            {!mode.bots && showRecommendation && <Recommendation market={market} isEditGrayVisible={true} />}
            <ButtonWithIcon className="market-step2__create-button" title="Следующий шаг" icon={upDoubleArrow} onClick={onClickNext} />
          </div>

          {!mode.bots && !showRecommendation && <Notice notice={notice} />}
        </div>
      </div>
    );
  }
);
