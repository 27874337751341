import { useLocation } from "react-router-dom";

import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import IntroMarkets from "./IntroMarkets/IntroMarkets";
import HiddenContent from "components/UI/HiddenContent/HiddenContent";

import AppStore from "AppStore";

import "./Intro.scss";

export default function Intro({ title, children, marketMode, isMobile, hiddenContent }) {
  const { pathname } = useLocation();

  return (
    <div className="intro">
      <div className="intro__info-container">
        <h1 className="intro__title">{title}</h1>
        {hiddenContent && <HiddenContent content={hiddenContent} />}
        <div>{children}</div>
      </div>
      <div className="intro__container-markets">
        {AppStore.isMarketPage(pathname) && <IntroMarkets marketMode={marketMode} isMobile={isMobile} />}
        {isMobile && marketMode.bots && <ButtonWithIcon title="Скачать презентацию" ariaLabel="Скачать презентацию" to="/docs/presentation-ya-pf.pdf" target="_blank" className="button-with-icon" />}
        <div className="intro__container-markets_big-circle" />
        <div className="intro__container-markets_small-circle" />
      </div>
    </div>
  );
}
