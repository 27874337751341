import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import Plate from "components/UI/Plate/Plate";
import { IsMobileCalculatorStep } from "./IsMobileCalculatorStep/IsMobileCalculatorStep.jsx";
import { IsDesktopCalculatorStep } from "./IsDesktopCalculatorStep/IsDesktopCalculatorStep.jsx";

import AppStore from "AppStore";

import { useMatchMedia } from "utils/hooks/use-match-media";
import { getCountReviews } from "utils/helpers.js";

import { EVENTS , metricsEvent } from "metrics";

import "./CalculatorStep.scss";

export const CalculatorStep = observer(
  ({ store, reviewRef, commentRef, scrollToError, nextStep }) => {
    const { isMobile } = useMatchMedia();
    const { pathname } = useLocation();
    const calculator = store.currentCalculator || AppStore.currentMarketStore.currentCalculator;
    const { market, mode } = calculator;
    const showRecommendation = ((market.id === 'avito' && mode.id !== 'reviews') || market.isSeo);

    const currentCalculatorPrice = store.currentCalculator.price;
    const selectedDeadline = store.currentCalculator.deadlines[store.selectedDeadlineIndex];
    const isTestPackageSelected = selectedDeadline?.title === "Протестировать";
    const descriptionModeHumans = (
      <>
        Более высокая стоимость, но гарантированно качественный результат
        и долгосрочный эффект. Если от ПФ недостаточно эффекта, то вы можете
        увеличить объем запросов через ботов.{" "}
        <Link to={`${mode.path}-bots`} style={{ color: "#FEB700" }}>
          Перейти на ботов
        </Link>
      </>
    );
    const descriptionModeSelection = mode.bots
    ? "Более низкая стоимость, но меньшая эффективность и риск блокировки аккаунта. "
    : descriptionModeHumans;

    const titleModeSelection = store.hasBots
    ? "Источник трафика"
    : "Что будем улучшать в первую очередь?";

    function onClickNext() {
      if (!store.validateStep2()) {
        scrollToError(reviewRef);
        return;
      }

      metricsEvent(EVENTS.PROJECT_STEP);

      if (nextStep?.current) {
        const headerHeight = 80;
        const yOffset =
          nextStep.current.getBoundingClientRect().top +
          window.scrollY -
          headerHeight;
        window.scrollTo({ top: yOffset, behavior: "smooth" });
      }
    }

    const getDisplayCount = (calculator, pathname) => {
      if (showRecommendation) {
        return `${calculator.reviewsCount} ${getCountReviews(calculator.reviewsCount)}`;
      }
      return `${calculator.reviewsCount} ${getCountReviews(calculator.reviewsCount)}**`;
    };

    const getAllActionCount = (calculator, pathname) => {
      return calculator.actionsCount;
    };

    return (
      <Plate className="market-step2-plate">
        {isMobile ? (
          <IsMobileCalculatorStep
            store={store}
            bots={!!(store.executor?.id === 'bots')}
            calculator={calculator}
            pathname={pathname}
            nextStep={nextStep}
            reviewRef={reviewRef}
            commentRef={commentRef}
            scrollToError={scrollToError}
            getDisplayCount={getDisplayCount}
            getAllActionCount={getAllActionCount}
            onClickNext={onClickNext}
            showRecommendation={showRecommendation}
            currentCalculatorPrice={currentCalculatorPrice}
            isTestPackageSelected={isTestPackageSelected}
            titleModeSelection={titleModeSelection}
            descriptionModeSelection={descriptionModeSelection}
            isMobile={isMobile}
          />
        ) : (
          <IsDesktopCalculatorStep
            store={store}
            bots={!!(store.executor?.id === 'bots')}
            calculator={calculator}
            pathname={pathname}
            nextStep={nextStep}
            reviewRef={reviewRef}
            commentRef={commentRef}
            scrollToError={scrollToError}
            getDisplayCount={getDisplayCount}
            getAllActionCount={getAllActionCount}
            onClickNext={onClickNext}
            showRecommendation={showRecommendation}
            currentCalculatorPrice={currentCalculatorPrice}
            isTestPackageSelected={isTestPackageSelected}
            titleModeSelection={titleModeSelection}
            descriptionModeSelection={descriptionModeSelection}
            isMobile={isMobile}
          />
        )}
      </Plate>
    );
  }
);
