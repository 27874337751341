import humans from "assets/images/dashboard/faces.png";
import bots from "assets/images/dashboard/bots.png";

import "./IntroPerformers.scss";

const COUNT_PERFORMERS_HUMANS = 49000;
const COUNT_PERFORMERS_BOTS = 10000000;

export default function IntroPerformers({ marketMode = null }) {
  const marketModeBots = marketMode && marketMode.bots;

  return (
    <div className="intro-performers">
        {marketModeBots
          ? <div className="intro-performers__bots">
              <img src={bots} alt="Исполнители-боты" className="intro-performers__bots-img" />
            </div>
          : <div className="intro-performers__people">
              <img src={humans} alt="Исполнители-люди" className="intro-performers__people-img" />
            </div>
        }

        {marketModeBots
          ? <div className="intro-performers__bots_description">
              <p className="intro-performers__bots_description-title">{COUNT_PERFORMERS_BOTS.toLocaleString()} <span>ботов</span></p>
              <p className="intro-performers__bots_description-text">в вашем распоряжении</p>
            </div>
          : <div className="intro-performers__people_description">
              <p className="intro-performers__people_description-title">+ {COUNT_PERFORMERS_HUMANS.toLocaleString()} <span>исполнителей</span></p>
              <p className="intro-performers__people_description-text">и ни одного бота</p>
            </div>
        }        
    </div>
  );
}
