import { observer } from "mobx-react";
import { useEffect, useState } from "react";

import Icon from "components/UI/IconSVG/Icon";
import Button from "components/UI/Button/Button";
import { BudgetSlider } from "components/BudgetSlider/BudgetSlider";
import Equipment from "../../Equipment/Equipment";
import Faqed from "components/UI/Faqed/Faqed";
import CheckBox from "components/UI/CheckBox/CheckBox";

import { SERVER_PRICE } from 'models/markets/YandexSeo';

import "./BotsCalculator.scss";

const BotsCalculator = observer(({ store, modeBots, currentCalculatorPrice, isTestPackageSelected, onExtendClick }) => {
  const [showExtendButton, setShowExtendButton] = useState(false);

  const paid = !!store.serverRentedUntilDatetime;
  const paidUnknown = (store.serverRentedUntilDatetime === undefined);

  const totalSum = isTestPackageSelected 
    ? currentCalculatorPrice 
    : (currentCalculatorPrice + (paid ? 0 : (store.isBotFarm ? SERVER_PRICE : 0)));  const testing = store.testing;

  // TODO: добавить функцию продления
  const handleExtendClick = () => {
    
  };

  useEffect(() => {
    if (paid) {
      const expirationDate = new Date(store.serverRentedUntilDatetime);
      const today = new Date();
      const diffTime = expirationDate.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      
      setShowExtendButton(diffDays <= 5 && diffDays >= 0);
    }
  }, [store.serverRentedUntilDatetime, paid]);

  return (
    <div className="bots-calculator">
      <div className="bots-calculator__container">
        <div className="bots-calculator__container-top">
          <div className="bots-calculator__info">
            <p className="bots-calculator__info-text">Стоимость ботов</p>
            <p className="bots-calculator__info-sum">{testing ? 'ТЕСТ' : `${currentCalculatorPrice.toLocaleString()} ₽`}</p>
          </div>

          {!isTestPackageSelected && !paid && store.isBotFarm &&
            <p className="bots-calculator__price-equipment" style={paidUnknown ? { visibility: 'hidden' } : {}}>
              + аренда оборудования <span>{SERVER_PRICE.toLocaleString() + '\u00a0₽'}</span>
            </p>
          }
          {!isTestPackageSelected && paid && store.isBotFarm &&
            <div className={`bots-calculator__price-equipment ${paid ? "paid" : ""}`} style={paidUnknown ? { visibility: 'hidden' } : {}}>
              <p>Аренда оборудования</p>
              <div className="bots-calculator__price-equipment-paid">
                <div>
                  <Icon sprite="another" name="paid-equipment" width={12} height={12} />
                  Оплачено
                </div>
                <p>до {store.serverRentedUntilDatetime.toLocaleDateString("ru-RU")}</p>
                {showExtendButton && <Button className="bots-calculator__extend-button" title="Продлить?" onClick={handleExtendClick} />}
              </div>
            </div>
          }
        </div>

        <div className="bots-calculator__budget" style={testing ? { visibility: 'hidden' } : {}}>
          <BudgetSlider store={store} modeBots={modeBots} disabled={testing}>
            <Icon className="bots-calculator__budget-icon" sprite="another" name="bot-budget" />
            <p>{testing ? '' : `≈ ${store.currentCalculator.chainsCount.toLocaleString()}`}</p>
          </BudgetSlider>
        </div>
      </div>

      <div className="bots-calculator__checked">
        <Faqed text="Система, которая автоматически создает и управляет ботами, адаптированными под ваши интересы. Боты могут собирать информацию, публиковать контент, взаимодействовать с подписчиками и выполнять другие задачи, экономя ваше время">
          <CheckBox title="Персональная бот-ферма с учетом интересов пользователей" checked={store.isBotFarm} onClick={() => store.setIsBotFarm(!store.isBotFarm)} />
        </Faqed>
      </div>

      <div className="bots-calculator__container">
        {store.isBotFarm && !isTestPackageSelected && store.serverRentedUntilDatetime === null && <Equipment />}

        <div className="bots-calculator__price-total">
          <p>Итого:</p>
          <p>{testing ? 'ТЕСТ' : `${totalSum.toLocaleString()} ₽`}</p>
        </div>
      </div>
    </div>
  );
});

export default BotsCalculator;
