import { observer } from "mobx-react";

import Tooltips from "components/UI/Tooltips/Tooltips";
import Icon from "components/UI/IconSVG/Icon";

import "./ProjectCounters.scss";

const ProjectCounters = observer(({ actions, reviewsCount, projectBots, projectTest }) => {
  return (
    <div className="project-counters">
      <Tooltips description="Всего действий">
        <div className={`project-counters__activity ${projectBots ? "with-border" : ""} ${projectTest ? "test-style" : ""}`}>
          {projectTest
            ? <p>тест</p>
            : <>
                <Icon className="project-counters__activity-icon" sprite="projects" name="action" color="#4F4F4F" />
                {actions}
              </>
          }
        </div>
      </Tooltips>
      {!projectBots &&
         <Tooltips description="Количество отзывов" innerStyle={{ left: "65px" }}>
          <div className="project-counters__reviews">
            <Icon className="project-counters__reviews-icon" sprite="projects" name="message" color="#4F4F4F" />
            {reviewsCount}
          </div>
        </Tooltips>
      }
    </div>
  );
});

export default ProjectCounters;
