import Button from 'components/UI/Button/Button';
import { Popup } from 'components/Popup/Popup';

import popupStore from 'components/Popup/PopupStore';
import popupBoxStore from 'PopupBox/PopupBoxStore';

import './KeyPhrasesPopup.scss';

export const KeyPhrasesPopup = ({ project, phrases, onClose }) => {
  const handleCopy = async () => {
    if (!phrases) return;
    
    try {
      await navigator.clipboard.writeText(phrases.join("\n"));
      popupStore.close();
      popupBoxStore.showInfo("Ссылка скопирована");
    } catch (err) {
      const textArea = document.createElement("textarea");
      textArea.value = phrases.join("\n");
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      
      textArea.focus();
      textArea.select();

      let success = false;
      try {
        success = document.execCommand("copy");
      } catch (e) {
        console.error("Failed to copy: ", e);
      }
      
      document.body.removeChild(textArea);
      
      if (success) {
        popupBoxStore.showInfo("Успешно скопировано");
      } else {
        popupBoxStore.showError("Не удалось скопировать");
      }

      popupStore.close();
    }
  };

  return (
    <Popup modalType="key-phrases" title="Ключевые запросы" subtitle={`#${project.id}`} onClose={onClose}>
      <div className="key-phrases__container">
        {phrases.map((phrase, index) => <div key={index}>{phrase}</div>)}
      </div>
      <div className="key-phrases__buttons">
        <Button title="Закрыть" className="key-phrases__button-cancel" onClick={() => popupStore.close()} />
        <Button title="Скопировать" classes={["wide"]} onClick={handleCopy} />
      </div>
    </Popup>
  );
};