import { useEffect, useRef } from "react";

import Deadline from "./Deadline/Deadline";
import TestPackageNotice from "./TestPackageNotice/TestPackageNotice";

import { getCountDays } from "utils/helpers";

import "./DeadlineSelector.scss";

export default function DeadlineSelector({ store, title, isTestPackageSelected, modePath, isMobile }) {
  const testPackageRef = useRef(null);

  useEffect(() => {
    if (isMobile && isTestPackageSelected && testPackageRef.current) {
      const headerHeight = 100;
      const yOffset = testPackageRef.current.getBoundingClientRect().top + window.scrollY - headerHeight;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  }, [isMobile, isTestPackageSelected]);

  return (
    <div className="deadline-selector">
      {isTestPackageSelected && <div ref={testPackageRef}><TestPackageNotice /></div>}
      <p className="deadline-selector__title">{title}</p>
      <div className={`deadline-selector__choice ${modePath ? "column" : ""}`}>
        {store.currentCalculator.deadlines.map((deadline, i) => (
          <Deadline
            key={i}
            deadline={`${deadline.days} ${getCountDays(deadline.days)}`}
            emoji={deadline.emoji}
            title={deadline.title}
            text={deadline.text}
            isActive={i === store.selectedDeadlineIndex}
            onClick={() => store.selectDeadline(i)}
            modePath={modePath}
            isMobile={isMobile}
          />
        ))}
      </div>
    </div>
  );
}
