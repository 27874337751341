import { observer } from "mobx-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import Badges from "components/UI/Badges/Badges";

import selectedIcon from "assets/images/selectdeadline.svg";

import AppStore from "AppStore";

import "./ModeSelection.scss";

const ModeSelection = observer(({ store, title, description }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    AppStore.selectMarketStoreAndCalculatorByPath(pathname);
  }, [pathname]);

  return (
    <div className="mode-selection">
      <p className="mode-selection__title">{title}</p>
      <div className={`mode-selection__container ${store.market.isSeo ? "other-colors" : ""}`}>
        {store.calculators.map((calculator, i) => (
          <div key={i} className={`mode-selection__wrapper ${calculator.mode.bots ? "has-price" : ""}`}>
            <ButtonWithIcon
              title={calculator.mode.title}
              className={`mode-selection__button ${store.selectedCalculatorIndex === i ? "active" : ""}  ${store.market.isSeo ? "other-colors" : ""}`}
              icon={store.selectedCalculatorIndex === i ? selectedIcon : ""}
              onClick={() => { store.selectCalculator(i) }}
              ariaLabel={`Выбрать режим ${calculator.mode.title}`}
              to={calculator.mode.path}
              target="_self"
            />
            {i === 0 && store.market.isSeo && <Badges status="дешевле" />}
          </div>
        ))}
      </div>
      {description && <p className="mode-selection__description">{description}</p>}
    </div>
  );
});

export default ModeSelection;
