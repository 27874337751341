import { observer } from "mobx-react";

import Slider from "components/UI/Slider/Slider";
import { ProjectsActiveReportAuctionSliderMark } from "./ProjectsActiveReportAuctionSliderMark/ProjectsActiveReportAuctionSliderMark";

import "./ProjectsActiveReportAuctionSlider.scss";

const ProjectsActiveReportAuctionSlider = observer(({ store, isMobile }) => {
  const handleChange = (newValue) => {
    store.setValue(newValue);
  };

  return (
    <div className="projects-active-report-auction-slider">
      <div className="projects-active-report-auction-slider__container">
        {isMobile && <p><span>30%</span><span>100%</span></p>}
        {!isMobile && <span>30%</span>}
        <Slider
          min={0}
          max={4}
          step={1}
          value={store.value}
          handler={handleChange}
          onMouseDown={() => {}}
          onTouchEnd={() => {}}
          thresholdColor="#FED400"
        />
        {!isMobile && <span>100%</span>}
      </div>
      <div className="projects-active-report-auction-slider__marks">
        {store.markValues.map((mv, index) => (
          <ProjectsActiveReportAuctionSliderMark key={index} store={store} value={mv} />
        ))}
      </div>
    </div>
  );
});

export default ProjectsActiveReportAuctionSlider;
