export const rulesList = {
  main: [
    "Я понимаю, что Boostclick является сервисом-посредником между заказчиком и исполнителем",
    "Я понимаю, что Boostclick не выполняет задания самостоятельно, а только передает их исполнителям",
    "Я понимаю, что Boostclick не несет ответственности за исполнителей и качество их работы",
    "Я понимаю, что я должен(-a) самостоятельно проверять и модерировать отчеты исполнителей",
    "Я понимаю, что если время на проверку отчетов истечет, исполнитель получит оплату автоматически, и вернуть ее будет невозможно",
  ],
  forRaisePrice: [
    [
      "Я понимаю, что повышение цены делает задание привлекательнее, но",
      <i> не гарантирует, что ее успеет взять добросовестный исполнитель</i>,
    ],
    [
      "Я понимаю, что",
      <i> BOOSTCLICK не несет ответственности за исполнителей</i>,
      " и качество их работы",
    ],
    [
      "Я понимаю, что",
      <i> я должен(-a) самостоятельно проверять и модерировать отчеты</i>,
      " исполнителей",
    ],
    "Я понимаю, что если время на проверку отчетов истечет, исполнитель получит оплату автоматически, и вернуть ее будет невозможно, даже если услуга не оказана",
  ],
  forBotsSeo: [
    [
      "Я понимаю, что",
      <i> перед работой с ПФ</i>,
      " нужно сделать",
      <i> базовую SEO оптимизацию</i>
    ],
    [
      "Я понимаю, что",
      <i> объем закупки ПФ</i>,
      " должен определять SEO специалист",
    ],
    [
      "Я понимаю, что",
      <i> оборудование и софт оплачиваются на месяц</i>,
      " и за него не вернуть деньги",
    ],
    [
      "Я понимаю, чтобы боты находили продвигаемый сайт,",
      <i> поисковые запросы</i>,
      " на момент накрутки ПФ",
      <i> должны быть в ТОП-50</i>,
    ],
    [
      "Я понимаю, что сервис",
      <i> BOOSTCLICK</i>,
      " это инструмент и он",
      <i> не несет ответственности за результаты использования</i>,
    ],
    [
      "Я понимаю, что",
      <i> создавая проект, я беру всю ответственность на себя</i>,
    ],
  ],
};
