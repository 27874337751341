import { observer } from "mobx-react";
import { useState } from "react";
import { Link } from "react-router-dom";

import Icon from "components/UI/IconSVG/Icon";
import ProjectsArchiveAbstractRow from "../ProjectsArchiveAbstractRow/ProjectsArchiveAbstractRow";
import ProjectLinkButton from "pages/Projects/components/ProjectLinkButton/ProjectLinkButton";
import { ProjectsActiveReportRow } from "pages/Projects/ProjectsActive/ProjectsActiveRow/ProjectsActiveReportRow/ProjectsActiveReportRow";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import FoldableSection from "components/UI/FoldableSection/FoldableSection";
import ProjectCounters from "pages/Projects/components/Project/ProjectCounters/ProjectCounters";
import ProjectTitle from "pages/Projects/components/Project/ProjectTitle/ProjectTitle";
import ProjectSettingsMobile from "pages/Projects/components/Project/mobile-ver/ProjectSettingsMobile/ProjectSettingsMobile";

import AppStore from "AppStore";
import popupStore from "components/Popup/PopupStore";

import {
  getProjectReportsWithNotStatus,
  formatReportDateArchive,
  calcTotalProjectActions,
  findActivityCount,
  formatCity,
} from "../../../Projects/ProjectsConstants";

import repeatIcon from "assets/images/repeat.svg";

import "./ProjectsArchiveRow.scss";

const ProjectsArchiveRow = observer(({ project, isMobile }) => {
  const [expanded, setExpanded] = useState(false);
  const [renderReports, setRenderReports] = useState(false);
  const [isShowSettings, setIsShowSettings] = useState(false);

  const isEditing = AppStore.editingTitle && AppStore.editingProjectId === project.id;

  const statisticsIcons = [
    { name: 'ya-webmaster', link: 'https://webmaster.yandex.ru/' },
    { name: 'ya-metrika', link: 'https://metrika.yandex.ru/',
      svg: (
        <svg width="26" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g>
            <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="25">
              <path d="M12.57 24.96c6.94 0 12.568-5.588 12.568-12.48C25.138 5.587 19.511 0 12.57 0 5.627 0 0 5.587 0 12.48c0 6.892 5.627 12.48 12.57 12.48z" fill="#fff"/>
            </mask>
            <g mask="url(#a)">
              <path d="M0 0h25.138v24.96H0V0z" fill="url(#b)"/>
              <path d="M0 15.357h7.735v9.6H0v-9.6z" fill="url(#c)"/>
              <path d="M7.734 12.286c0-1.613 0-2.42.317-3.036a2.89 2.89 0 0 1 1.267-1.258c.62-.314 1.433-.314 3.057-.314h5.028v17.28H7.734V12.286z" fill="url(#d)"/>
              <path d="M17.402-.002h7.735v24.96h-7.735V-.002z" fill="url(#e)"/>
            </g>
          </g>
          <defs>
            <linearGradient id="b" x1="18.015" y1="18.095" x2="-2.529" y2="-10.747" gradientUnits="userSpaceOnUse">
              <stop stopColor="#4643B9"/>
              <stop offset="1" stopColor="#1E8AFF"/>
            </linearGradient>
            <linearGradient id="c" x1="8.244" y1="21.636" x2="-14.61" y2="17.627" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FF002E"/>
              <stop offset="1" stopColor="#FFADA1"/>
            </linearGradient>
            <linearGradient id="d" x1="67.196" y1="62.878" x2="72.871" y2="16.128" gradientUnits="userSpaceOnUse">
              <stop stopColor="#3C3BA0"/>
              <stop offset=".49" stopColor="#1E8AFF"/>
              <stop offset="1" stopColor="#00B2FF"/>
            </linearGradient>
            <linearGradient id="e" x1="17.402" y1="1.282" x2="39.228" y2="12.64" gradientUnits="userSpaceOnUse">
              <stop stopColor="#FFEA1A"/>
              <stop offset="1" stopColor="#FFB800"/>
            </linearGradient>
          </defs>
        </svg>
      )
     },
    { name: 'ya-topvisor', link: 'https://topvisor.com/?inv=427724' },
  ];

  function invertExpanded(e) {
    if (AppStore.editingTitle && AppStore.editingProjectId === project.id) {
      return;
    }
    if (e.target.closest(".project-editing-title__button-save")) {
      return;
    }

    setExpanded(!expanded);
    if (!expanded)
      setRenderReports(true);
  }

  if (!project) return null;

  const activities = project.activities;

  const reviewsCount = findActivityCount(activities, "MAIN.REVIEW");
  const lastActivityDate = project.reports?.findLast(
    (report) => report.sentDatetime
  )?.sentDatetime;

  const statusMap = {
    finished: "Завершен",
    failed: "Ошибка",
    canceled: "Отменен",
  };

  let status = statusMap[project.status] || project.status;

  if (project.cancelling)
    status = "Отменен";

  const onTransitionEnd = () => {
    if (!expanded)
      setRenderReports(false);
  };

  return (
    <>
    <FoldableSection className="projects-archive-row" expanded={expanded} onTransitionEnd={onTransitionEnd}>
      <ProjectsArchiveAbstractRow className={`projects-archive-row__abstract ${project.cancelling ? `status-canceled` : ""}`}>
        <div className="projects-archive-row__title-container">
          <div className="projects-archive-row__title-section" onClick={invertExpanded}>
            <Icon className={`projects-archive-row__title-section-icon ${expanded ? "expanded" : ""}`} sprite="projects" name="accordion" color="#4F4F4F" />
            <ProjectTitle store={AppStore} project={project} expanded={expanded} isMobile={isMobile} />
          </div>
          {!isMobile && (isEditing ? (
            <></>
          ) : (
            <button
              type="button"
              aria-label="Редактировать"
              className="projects-archive-row__button-edit"
              onClick={() => {
                AppStore.startEditingProjectTitle(project.id);
                AppStore.setEditingTitle(true);
                AppStore.setNewTitle(project.title);
              }}
            />
          ))}
          {isMobile && 
            <button type="button" aria-label="Открыть все настройки" onClick={() => { popupStore.open("project-settings-mobile"); setIsShowSettings(true); }}>
              <Icon className="projects-drafts-row__button-mobile-config" sprite="projects" name="mobile-config" color="#1E1E1E" width={4} height={18} />
            </button>
          }
        </div>
        <div className="projects-archive-row__info">
          {!isMobile && <ProjectLinkButton style={{ visibility: project.link ? 'visible' : 'hidden' }} link={project.link} />}
          <div className={`projects-archive-row__info-status
            ${project.status ? `projects-archive-row__info-status-${project.status}` : ""}
            ${project.cancelling ? `projects-archive-row__info-status-canceled` : ""} 
          `}>
            {status}
          </div>

          <ProjectCounters actions={calcTotalProjectActions(project).toLocaleString()} reviewsCount={reviewsCount} projectBots={project.bots} projectTest={project.testing} />
          <div className="projects-archive-row__info-last-activity">
            {lastActivityDate
              ? formatReportDateArchive(new Date(lastActivityDate))
              : "—"}
          </div>
          <div className="projects-archive-row__info-city">
            {formatCity(project.city)}
          </div>
        </div>
        {project.test ? (
          <div><b>ТЕСТ</b></div>
        ) : project.bots ? (
          <div className="project-property-column__icons">
            {statisticsIcons.map((icon, index) =>
              <Link key={index} className="project-property-column__icon-link" to={icon.link} target="_blank" rel="noopener noreferrer">
                {icon.svg || <Icon className="project-property-column__stat-icon" sprite="projects" name={icon.name} width={26} height={26} />}
              </Link>
            )}
          </div>
        ) : (
          <ButtonWithIcon
            title="Повторить"
            className="projects-archive-row__button-repeat"
            icon={repeatIcon}
            onClick={() => {
              AppStore.repeatProject(project);
            }}
            ariaLabel="Кнопка повторить проект"
          />
        )}
      </ProjectsArchiveAbstractRow>
      {renderReports && <div className="projects-archive-row__reports">
        {getProjectReportsWithNotStatus(project.reports, "new")?.map(
          (report, i) => (
            <ProjectsActiveReportRow
              key={i}
              project={project}
              report={report}
              isMobile={isMobile}
            />
          )
        )}
      </div>}
    </FoldableSection>
    
    {isMobile && isShowSettings && <ProjectSettingsMobile store={AppStore} project={project} isMobile={isMobile} />}
    </>
  );
});

export default ProjectsArchiveRow;
