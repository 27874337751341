import Icon from "components/UI/IconSVG/Icon";

import "./TestPackageNotice.scss";

export default function TestPackageNotice() {
  return (
    <div className="test-package-notice">
      <div className="test-package-notice__title">
        <Icon className="test-package-notice__title-icon" sprite="another" name="attention-budget" />
        <p>Обратите внимание</p>
      </div>
      <div className="test-package-notice__description">
        <p>
          <span>Пакет «Протестировать»</span> предполагает автоматическую
          настройку проекта. Пользовательские настройки параметров продвижения
          недоступны в данном пакете.
        </p>
        <p>Пожалуйста, предоставьте все необходимые данные о вашем сайте <span>на Шаге 2</span></p>
      </div>
    </div>
  );
}
