import {
  MARKET_MODES,
  MARKET_MODE_STATUS,
  Market
} from '../market';

export const SERVER_PRICE = 9250;

const YANDEX_SEO_PEOPLE_FAQ = [
  {
    title: 'Что такое накрутка поведенческих факторов для Яндекса?',
    isH3: true,
    text:
      <div>
        Накрутка поведенческих факторов - это процесс искусственного увеличения показателей, которые влияют на ранжирование сайта в поисковой выдаче.
        <br />
        К ним относятся:
        <ul>
          <li>Просмотры страниц</li>
          <li>Время на сайте</li>
          <li>Глубина просмотра</li>
          <li>Количество переходов по ссылкам</li>
        </ul>
        <br />
        Сервис использует для накрутки ПФ реальных пользователей, которые получают задания на внутренней бирже.
        Исполнители получают только скриншот, что делает весь пользовательский путь максимально органическим.
        <br />
        <br />
        В отчетах мы не используем скриншоты и вопросы, на которые можно ответить с помощью копированного контента.
        <br />
        Это не позволит при накрутке ПФ Яндекс Метрике зафиксировать регулярные подозрительные действия.
      </div>
  },
  {
    title: 'Почему накрутка поведенческих факторов в Яндексе важна для сайта?',
    isH3: true,
    text:
      <p>
        Каждому поисковику необходимо понимать, по какому принципу ранжировать выдачу.
        <br />
        Одна из важных метрик при ранжировании - это поведение пользователей на сайте.
        <br />
        Чем лучше ваши метрики относительно конкурентов, тем полезнее ваш сайт будет казаться для поисковика, и тем выше могут быть ваши позиции.*
        <br />
        <br />
        * ПФ - это вспомогательный инструмент, и важно, чтобы была выполнена верная внутренняя и внешняя оптимизация.
      </p>
  },
  {
    title: 'Как вы накручиваете поведенческие факторы для Яндекса?',
    isH3: true,
    text:
      <p>
        Мы используем реальных пользователей, которые просматривают ваш сайт по заданным параметрам.
        <br />
        Это позволяет избежать искусственной накрутки и получить естественные, безопасные для поисковых систем показатели.
      </p>
  },
  {
    title: 'Безопасно ли пользоваться вашими услугами?',
    isH3: false,
    text:
      <p>
        Да, наши услуги безопасны. Мы используем только проверенные методы, которые не нарушают правила поисковых систем.
      </p>
  },
  {
    title: 'Как долго действует эффект от накрутки в Яндексе?',
    isH3: false,
    text:
      <p>
        Эффект от накрутки сохраняется до тех пор, пока поведение пользователей на вашем сайте более качественное относительно трафика конкурентов, а параметры внешней и внутренней оптимизации не ухудшаются.
      </p>
  },
  {
    title: 'Как заказать услуги?',
    isH3: false,
    text:
      <p>
        Оформите заказ на нашем сайте, указав необходимые параметры. После оплаты исполнители приступят к выполнению заказа.
        <br />
        <br />
        После выполнения каждой задачи вы получите отчет от исполнителя, и его можно будет сравнить с поведением в Яндекс Вебвизоре.
        <br />
        <br />
        Если задача сделана верно, то примите отчет, а если нет, то отправьте на доработку.
      </p>
  },
  {
    title: 'Сколько стоит накрутка поведенческих факторов в Яндексе?',
    isH3: false,
    text:
      <p>
        Цена зависит от количества поисковых запросов и конкуренции.
        <br />
        Вы можете рассчитать стоимость проекта в калькуляторе при создании проекта.
      </p>
  },
];

const YANDEX_SEO_BOTS_FAQ = [
  {
    title: 'Какие факторы влияют на ранжирование сайта в Яндексе по поисковым запросам?',
    isH3: true,
    text:
        <ul>
          К ним относятся:
          <br />
          <li><span>Качество контента:</span>Уникальный, полезный и интересный контент, отвечающий на запросы пользователей.</li>
          <li><span>Техническая оптимизация:</span>Быстрая загрузка страниц, корректная работа сайта на всех устройствах, наличие HTTPS.</li>
          <li><span>Ключевые слова:</span>Использование релевантных ключевых слов в текстах, заголовках и мета-тегах.</li>
          <li><span>Структура сайта:</span>Логичная и понятная структура сайта, удобная навигация.</li>
          <li><span>Внешние ссылки:</span>Ссылки на сайт с других авторитетных ресурсов.</li>
          <li><span>Поведенческие факторы:</span>Время, проведенное пользователями на сайте, показатель отказов, глубина просмотра.</li>
        </ul>
  },
  {
    title: 'Чем отличается накрутка ПФ ботами от накрутки ПФ через микрозадания, которые делают люди?',
    isH3: false,
    text: <p>Поведенческие факторы ботами позволяет сильно масштабировать задачи. Отправлять боты для целей ПФ кратно тысячам, существенно проще и дешевле чем оплачивать микрозадачи реальным людям</p>
  },
  {
    title: 'Как работает накрутка поведенческих факторов в Яндексе через ботов?',
    isH3: false,
    text:
      <p>
        С помощью специального сервиса из вашей Яндекс.Метрики мы получаем интересы вашей аудитории. 
        <br />
        При создании учеток ботов для вашего сайта, эти интересы учитываются и боты нагуливают по сайтам таким образом, чтобы их интересы совпадали с вашей аудиторией.
        <br />
        В последствии эти боты находят ваш сайт через поиск Яндекса. Переходят на сайт и рассматривают его, временами даже выполняя цели метрики
      </p>
  },
  {
    title: 'Как быстро можно увидеть результат?',
    isH3: true,
    text: <p>Понять, имеет ли ваш сайт отклик от ПФ, можно уже в первые двое суток.</p>
  },
  {
    title: 'Сколько стоит накрутка ПФ ботами?',
    isH3: true,
    text:
      <p>
        Стоимость накрутки ПФ ботами начинается от {SERVER_PRICE} руб. 
        <br />
        <br />
        Для накрутки требуется аренда сервера для ботов, аренда мобильных прокси-серверов и софта, который создает и руководит ботами.
        <br />
        Также требуется оплата сервиса для решения капчи Яндекса
      </p>
  },
  {
    title: 'Какие гарантии дает сервис?',
    isH3: false,
    text:
      <p>
        Сервис гарантирует старт ПФ в течении 5 рабочих дней.
        <br />
        Результат от ПФ ботами сильно зависит от бюджета, возраста сайта и базовой SEO оптимизации.
      </p>
  },
  {
    title: 'Есть ли риски при накрутке ПФ ботами?',
    isH3: false,
    text:
      <p>
        Так как ПФ может закупить абсолютно любой пользователь на любой сайт, то поисковым системам невозможно установить заказчика.
        <br /> 
        В противном случае закупку ПФ с хорошими поведенческими метриками использовали бы для борьбы с конкурентами через наложение санкций от поисковых систем.
        <br />
        <br />
        Сейчас недобросовестные конкуренты используют ПФ только с показателями сессии 1 страница и просмотр менее 3 секунд.  
      </p>
  },
  {
    title: 'Как создать проект?',
    isH3: false,
    text:
      <ul>
        <li>выберите количество ботов, которые планируете направить на продвигаемый сайт</li>
        <li>перечислите ключевые фразы и город продвижения</li>
        <li>сделайте скриншот заголовка (title) вашего сайта в Яндекс</li>
      </ul>
  },
  {
    title: 'В каком виде я увижу отчет о работе ботов?',
    isH3: false,
    text:
      <p>
        Процесс работы ботов можно увидеть через Яндекс Метрику и по результатам изменения позиций в Топвизоре (при условии если правильной технической оптимизации сайта и необходимой закупки ботов относительно конкуренции).
        <br />
        <br />
        В личном кабинете Boostclick будет отражаться примерный объем ботов перешедший на ваш сайт за день и за весь период активности
      </p>
  },
  {
    title: 'Как мне понять сколько надо ботов чтобы увидеть результат?',
    isH3: false,
    text:
      <p>
        Результат зависит от конкурентности ниши деятельности вашего сайта.
        <br />
        <br />
        Рекомендуем не менее 1000 ботов.
        <br />
        Для более впечатляющих результатов потребуется большее количество ботов
      </p>
  },
  {
    title: 'Если я не сделаю базовую техническую оптимизацию сайта, какой будет результат?',
    isH3: false,
    text: <p>Мы не рекомендуем запускать проект без базовой оптимизации сайта. В таком случае результат от ПФ будет нулевым или минимальным</p>
  },
];

const STEP_TWO_TITLE_FAQ = <p>
  Почему при добавлении одного действия в калькуляторе появляются
  дополнительные действия?
  <br />
  <br />
  Сервис формирует уникальные сценарии для каждого исполнителя, и каждый
  сценарий начинается для нового исполнителя с поиска товара по сделанному
  скриншоту вашей ссылки.
</p>;

const REAL_ACTIONS = [
  { type: 'ALWAYS.OPEN', icon: "open", iconHome: "home-open", title: 'Открыть поисковик Яндекс', initialShare: 1 },
  { type: 'ALWAYS.SEARCH', icon: "search", iconHome: "home-search", title: 'Набрать поисковый запрос', initialShare: 1 },
  { type: 'ALWAYS.COMPETITORS', icon: "time", iconHome: "home-time", title: 'Открыть несколько сайтов из ТОП-10', titleComment: 'не более 10 сек', initialShare: 1 },
  { type: 'ALWAYS.FIND', icon: "search", iconHome: "home-search", title: 'Найти сайт в поисковой выдаче Яндекса', initialShare: 1 },
  { type: "ALWAYS.CLICK", icon: "click", iconHome: "home-click", title: "Зайти на продвигаемый сайт", initialShare: 1 },
  {
    type: 'EXTRA.DESCRIPTION',
    icon: "time",
    iconHome: "home-time",
    title: 'Изучить меню / каталог / услуги / товар',
    titleComment: 'от 3 мин',
    initialShare: 1,
    activityType: 'Изучение',
  },
  {
    type: 'MAIN.INFO',
    icon: "glasses",
    iconHome: "home-glasses",
    title: 'Почитать информацию о компании',
    initialShare: 1,
    activityType: 'Информация',
  },
  {
    type: 'MAIN.GEO',
    icon: "people",
    iconHome: "home-people",
    title: 'Зайти в адреса / контакты / карту',
    initialShare: 0,
    activityType: 'Контакты',
  },
  {
    type: 'MAIN.PHONE',
    icon: "phone",
    iconHome: '',
    title: 'Копировать номер телефона с сайта',
    titleComment: 'без звонка',
    initialShare: 0,
    activityType: 'Телефон',
  },
  {
    type: 'MAIN.EMAIL',
    icon: "message",
    iconHome: "home-message",
    title: 'Копировать на сайте почту из контактов',
    initialShare: 0,
    activityType: 'Почта',
  },
];

const BOT_ACTIONS = [
  { type: 'ALWAYS.OPEN', icon: "open", iconHome: "home-open", title: 'Открыть поисковик Яндекс', initialShare: 1 },
  { type: 'ALWAYS.SEARCH', icon: "search", iconHome: "home-search", title: 'Набрать поисковый запрос', initialShare: 1 },
  { type: 'ALWAYS.FIND', icon: "search", iconHome: "home-search", title: 'Найти сайт в поисковой выдаче Яндекса', initialShare: 1 },
  { type: "ALWAYS.CLICK", icon: "click", iconHome: "home-click", title: "Зайти на продвигаемый сайт", initialShare: 1 },
  {
    type: 'ALWAYS.DESCRIPTION',
    icon: "time",
    iconHome: "home-time",
    title: 'Изучить меню / каталог / услуги / товар',
    titleComment: 'от 3 мин',
    initialShare: 1,
    activityType: 'Изучение',
  },
  {
    type: 'EXTRA.INFO',
    icon: "glasses",
    iconHome: "home-glasses",
    title: 'Почитать информацию о компании',
    initialShare: 1,
    activityType: 'Информация',
  },
  {
    type: 'MAIN.GEO',
    icon: "people",
    iconHome: "home-people",
    title: 'Зайти в адреса / контакты / карту',
    initialShare: 1,
    activityType: 'Контакты',
  },
];

const STEP_PHRASE_2 = 'Укажите информацию для поиска сайта и закажите накрутку в Яндекс';

const YANDEX_SEO_KEYWORDS = ['Yandex', 'yandex', 'Яндекс', 'яндекс' ];

const CHAIN_PRICE_REAL = 14; // изначальная цена
const CHAIN_PRICE_BOTS = 7;

class YandexSeoClass extends Market {

  id = 'yandexseo';

  host = 'yandex.ru';
  url = 'https://www.yandex.ru/';

  title = 'Яндекс';

  isMarketplace = false;
  isSeo = true;

  icon = "yandex";
  titlePlaces = 'Яндекс';
  statusForHomePage = MARKET_MODE_STATUS.NEW;

  chainsPerDayNormal = 42;

  chainsMin = 20;
  chainsMax = 1000;
  chainPrice = CHAIN_PRICE_REAL;

  chainsDefault = 20;

  reviewPrice = 250;

  chainsStep = 10;
  viewsStep = 10;

  keywords = YANDEX_SEO_KEYWORDS;

  targetLinkComment = 'Укажите тут адрес вашего сайта';
  targetLinkCommentShort = 'Укажите сайт';

  modes = [
    {
      id: MARKET_MODES.CHAINS,
      bots: true,
      path: '/nakrutka-pf-yandex-bots',
      rating: 0.01,
      title: 'Боты 🤖',
      navigateTitle: 'Накрутка ПФ в Яндекс ботами',
      icon: "lightning",
      iconForHomePage: "home-action",
      status: MARKET_MODE_STATUS.NEW,
      statusForHomePage: MARKET_MODE_STATUS.NEW,
      meta: {
        title: `Накрутка ПФ Яндекс от 1.25 руб / БОТ. Заказать продвижение в ТОП Яндекса через накрутку поведенческих факторов | Boostclick`,
        description: 'Накрутка ПФ ботами в Яндекс с учетом краткосрочных и долгосрочных интересов пользователей. Сервис для самостоятельной работы с поведенческими факторами Яндекс.',
      },
      intro: {
        title: 'ПФ продвижение в поиске Яндекс',
        points: [
          'Учитываются краткосрочные и долгосрочные интересы пользователей',
          'Увеличит просмотры и активность',
          'Оптимизирует расходы на рекламу',
          'Улучшит позиции в поиске',
        ]
      },
      howItWorks: [
        'Настройте параметры активности в соответствие с конкуренцией',
        'Укажите фразы для поиска вашего сайта',
        'Укажите город и прикрепите скриншот целевой страницы',
        'После оплаты мы передадим исполнителю скриншот и параметры для поиска',
        'Проверьте ответы и активность, примите работу или отправьте на доработку'
      ],
      stepPhrase1: 'Настройте параметры для быстрой накрутки ПФ',
      stepPhrase2: STEP_PHRASE_2,
      stepTwoTitleFaq: STEP_TWO_TITLE_FAQ,
      calculatorTitle: 'Накрутка ПФ Яндекс',
      titleFAQ: "Вопрос-ответ о накрутке поведенческих факторов в Яндекс",
      faq: YANDEX_SEO_BOTS_FAQ,
      actions: BOT_ACTIONS,
      notice: [
        '* Сервис сгенерирует уникальные цепочки действий с учетом поисковых запросов',
        'Каждый исполнитель получит индивидуальное задание, что гарантирует разное поведение пользователей на вашем сайте',
        'Сложность цепочек и время исполнения зависит от их количества в проекте',
      ],
      chainsStep: 1000,
    },
    {
      id: MARKET_MODES.CHAINS,
      path: '/nakrutka-pf-yandex',
      rating: 0.01,
      title: 'Реальные люди 🙋🙋‍♀️',
      navigateTitle: 'Накрутка ПФ в Яндекс людьми',
      icon: "lightning",
      iconForHomePage: "home-action",
      status: MARKET_MODE_STATUS.NEW,
      statusForHomePage: MARKET_MODE_STATUS.NEW,
      meta: {
        title: `Накрутка ПФ Яндекс от ${CHAIN_PRICE_REAL} руб. Заказать продвижение в ТОП Яндекса через накрутку поведенческих факторов | Boostclick`,
        description: 'Мы не продаем клик — мы продаем пользовательский путь. С нами вы можете улучшить SEO на Яндексе через накрутку ПФ.',
      },
      intro: {
        title: 'ПФ продвижение в поиске Яндекс',
        comment: 'Через выполнение микрозадач от реальных пользователей',
        points: [
          'Повысит эффективность рекламных объявлений',
          'Увеличит просмотры и активность в аккаунте',
          'Оптимизирует затраты на рекламу',
          'Улучшит позиции по поисковым запросам',
        ]
      },
      howItWorks: [
        'Настройте параметры активности в соответствие с конкуренцией',
        'Укажите фразы для поиска вашего сайта',
        'Укажите город и прикрепите скриншот целевой страницы',
        'После оплаты мы передадим исполнителю скриншот и параметры для поиска',
        'Проверьте ответы и активность, примите работу или отправьте на доработку'
      ],
      stepPhrase1: 'Настройте параметры для быстрой накрутки ПФ',
      stepPhrase2: STEP_PHRASE_2,
      stepTwoTitleFaq: STEP_TWO_TITLE_FAQ,
      calculatorTitle: 'Накрутка ПФ Яндекс',
      titleFAQ: "Вопрос-ответ о накрутке поведенческих факторов в Яндекс",
      faq: YANDEX_SEO_PEOPLE_FAQ,
      actions: (() => {
        return [
          ...REAL_ACTIONS.filter(action => action.type !== 'MAIN.REVIEW' && !action.type.startsWith('VIEW.')),
          ...REAL_ACTIONS.filter(action => action.type === 'MAIN.REVIEW'),
        ];
      })(),
      notice: [
        '* Сервис сгенерирует уникальные цепочки действий с учетом поисковых запросов',
        'Каждый исполнитель получит индивидуальное задание, что гарантирует разное поведение пользователей на вашем сайте',
        'Сложность цепочек и время исполнения зависит от их количества в проекте',
      ]
    }
  ];

  executorTypes = [
    {
      id: 'real',
      title: 'Реальные люди 🙋🙋‍♀️',
      description: 'Более высокая стоимость, но гарантированно качественный результат и долгосрочный эффект',
      chainPrice: CHAIN_PRICE_REAL,
    },
    {
      id: 'bots',
      title: 'Боты 🤖',
      description: 'Более низкая стоимость, но меньшая эффективность и риск блокировки аккаунта',
      chainPrice: CHAIN_PRICE_BOTS,
    }
  ];

  actionsMap = REAL_ACTIONS.reduce((map, action) => {
    map[action.type] = action;
    return map;
  }, {});

  emptyUrlStr = 'Укажите ссылку на сайт';
  invalidUrlStr = 'Укажите корректную ссылку на сайт';
}

const YandexSeo = new YandexSeoClass();
export default YandexSeo;
