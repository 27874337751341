import "./InfoSection.scss";

const InfoSection = ({ title, text, children, type }) => {
  const sectionClassName = type === "comment" ? "comment-section" : "timer-section";

  return (
    <div className={`info-section ${sectionClassName}`}>
      <div className="info-section__container">
        <p className="info-section__title">{title}</p>
        <p className="info-section__text">{text}</p>
      </div>
      {children}
    </div>
  );
};

export default InfoSection;
