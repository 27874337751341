import { observer } from "mobx-react";

import Icon from "components/UI/IconSVG/Icon";

import "./ProjectsFilter.scss";

const ProjectsFilter = observer(({ cities, selectedCities, type, typeProjects, onChange, isOpen, onToggle, isMobile }) => {
  const selectedCity = selectedCities[type] || "Все города";

  return (
    <div className="projects__filter">
      <div className={`projects__filter-select ${isOpen ? "open" : ""}`} onClick={onToggle}>
        {!isOpen && <Icon className="projects__filter-icon" sprite="projects" name="filter" color="#4F4F4F" />}
        {!isMobile && <p className={selectedCity ? "active" : ""}>
          {selectedCity}
        </p>}
        {isOpen && <div className="projects__filter-arrow" />}
      </div>
      {isOpen && (
        <div className="projects__filter-dropdown">
          {cities.map((city) => (
            <div key={city} className={`projects__filter-option ${selectedCity === city ? "active" : ""}`} onClick={() => { onChange(type, city); onToggle() }}>
              <p>{city}</p>
              <p className="projects__filter-option-count">
                {city === "Все города" ? typeProjects.length : typeProjects.filter((project) => (project.city || "Без города") === city).length}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
})

export default ProjectsFilter;