import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import Intro from "components/Intro/Intro";
import IntroIllustration from "components/Intro/IntroIllustration/IntroIllustration";
import IntroPoints from "components/Intro/IntroPoints/IntroPoints";

import "./IntroWithPoints.scss";

export default function IntroWithPoints({
  title,
  comment,
  points,
  marketMode,
  hiddenContent,
  isMobile,
}) {
  const path = marketMode?.path ?? '';
  const classesReviewAvito = path.includes('reviews-na-obyavleniya');
  const classesReviewYandex = path.includes('reviews-on-yandexmaps');

  return (
    <section className="intro-with-points">
      {isMobile && <>{classesReviewAvito || classesReviewYandex ? <div className={`intro-markets__container-review ${classesReviewAvito ? 'avito' : 'yandex'}`}/> : <IntroIllustration marketMode={marketMode} />} </>}
      <Intro title={title} comment={comment} marketMode={marketMode} isMobile={isMobile} hiddenContent={hiddenContent}>
        <div className="intro-with-points__info-container">
          <div className="intro-with-points__comment">{comment}</div>
          {isMobile ? <IntroPoints stepLength={points} /> : <ul className="intro-with-points__list">{points?.map((point, i) => <li key={i}>{point}</li>)}</ul>}
          {!isMobile && marketMode.bots && <ButtonWithIcon title="Скачать презентацию" ariaLabel="Скачать презентацию" to="/docs/presentation-ya-pf.pdf" target="_blank" className="button-with-icon" />}
        </div>
      </Intro>
    </section>
  );
}
