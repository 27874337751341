/**
 * @file Файл содержит код для настройки проекта и отправки его на сервер.
 */

import 'setimmediate';

import lodash from 'lodash';

import { makeAutoObservable, observe, runInAction } from 'mobx';

import AppStore from 'AppStore';
import messageBoxStore from 'MessageBox/MessageBoxStore';

import client, { ApiError } from 'client';

import { MARKET_MODES } from 'models/market';
import { SERVER_PRICE } from 'models/markets/YandexSeo';

/**
 * Класс для настройки действий в проекте.
 * @property {number} sliderValue Текущее значение бегунка.
 * @property {number} sliderValueMin Минимальное значение бегунка.
 * @property {number} sliderValueMax Максимальное значение бегунка.
 */
class Calculator {

  store;
  market;
  mode;

  constructor(store, market, mode) {
    this.store = store;
    this.market = market;
    this.mode = mode;
  
    const modeId = mode.id;

    this.isChains = modeId === MARKET_MODES.CHAINS;
    this.isReviews = modeId === MARKET_MODES.REVIEWS;
    this.isViews = modeId === MARKET_MODES.VIEWS;

    this.reviewsMin = this.isReviews ? 1 : 0;
    this.chainsPerReview = 4;

    this.chainPrice = mode.bots ? 1.25 : market.chainPrice;
    this.chainsMin = mode.bots ? (store.isBotFarm ? 12000 : 4000) : Math.max(this.isChains ? market.chainsMin : 0, this.reviewsMin * this.chainsPerReview);
    this.chainsMax = mode.bots ? 80000 : market.chainsMax;
    if (this.isChains) {
      this.sliderValueMin = this.chainsMin / (mode.chainsStep || market.chainsStep);
      this.sliderValueMax = this.chainsMax / (mode.chainsStep || market.chainsStep);
    }

    this.viewPrice = 4;
    this.viewsMin = this.isViews ? (3 * market.viewsStep) : 0;
    this.viewsMax = 300;
    this.viewsPerDay = 90;
    if (this.isViews) {
      this.sliderValueMin = this.viewsMin / market.viewsStep;
      this.sliderValueMax = this.viewsMax / market.viewsStep;
    }

    this.reviewPrice = market.reviewPrice;
    this.reviewWarrantyPrice = 50;
    this.reviewsMax = 6;
    this.reviewsPerDay = 0.5;
    this.reviewsOffsetDays = 3;
    if (this.isReviews) {
      this.sliderValueMin = this.reviewsMin;
      this.sliderValueMax = this.reviewsMax;
    }

    this.actions = this.mode.actions.map(action => ({
      ...action,
      share: action.initialShare ?? 0.0,
      count: 0,
    }));

    this.actionsPerChain = this.actions.reduce((acc, action) => acc + (action.type.startsWith('ALWAYS.') ? 1 : 0), 0)
      + (this.actions.find(action => action.type.startsWith('MAIN.') && action.type !== 'MAIN.REVIEW') ? 1 : 0)
      + (this.actions.find(action => action.type.startsWith('EXTRA.')) ? 1 : 0);

    this.reviewsPerDayNormal = 0.5;

    this.deadlines = mode.bots ? [
      {
        emoji: '👁',
        title: 'Протестировать',
        text: 'Демонстрация возможностей',
        chainsPerDay: 84,
        reviewsPerDay: 1,
        days: mode.bots ? 1 : undefined,
      },
      {
        emoji: '🚀',
        title: 'Увидеть результат',
        text: 'Максимум результата',
        chainsPerDay: 42,
        reviewsPerDay: 0.5,
        days: mode.bots ? 30 : undefined,
      },
    ] : [
      {
        emoji: '🚀',
        title: 'Быстрее',
        text: 'Нет времени ждать. Полетели!',
        chainsPerDay: this.market.chainsPerDayNormal * 2,
        reviewsPerDay: this.reviewsPerDayNormal * 2,
        days: undefined,
      },
      {
        emoji: '☯️',
        title: 'Оптимально',
        text: 'Золотая середина и баланс в темпе',
        chainsPerDay: this.market.chainsPerDayNormal,
        reviewsPerDay: this.reviewsPerDayNormal,
        days: undefined,
      },
      {
        emoji: '🐌',
        title: 'Медленнее',
        text: 'Ползем и никуда не торопимся',
        chainsPerDay: this.market.chainsPerDayNormal / 2,
        reviewsPerDay: this.reviewsPerDayNormal / 1.5,
        days: undefined,
      },
    ];

    this.selectedDeadlineIndex = 1;
    this.testing = false;

    this.setChainsCount(lodash.clamp(this.isChains ? market.chainsDefault : (this.isReviews ? 4 : 0), this.chainsMin, this.chainsMax), false);
    this.setViewsCount(lodash.clamp(this.isViews ? 3 * this.market.viewsStep : 0, this.viewsMin, this.viewsMax), false);
    this.setReviewsCount(lodash.clamp(this.isReviews ? 1 : 0, this.reviewsMin, this.reviewsMax), false);

    this.recalculateCounters();
    this.recalculatePriceActionsDays();
  }

  recalculateBotsPricing() {
    const { store, market, mode } = this;
    if (!mode.bots)
      return;

    this.chainsMin = store.serverRentedUntilDatetime ? 2000 : (store.isBotFarm ? 12000 : 4000);
    this.sliderValueMin = this.chainsMin / (mode.chainsStep || market.chainsStep);
    this.priceMin = this.chainsMin * this.chainPrice;

    let recalculate = false;
    if (this.chainsCount < this.chainsMin) {
      this.setChainsCount(this.chainsMin, false);
      recalculate = true; 
    } else if (this.chainsCount > this.chainsMax) {
      this.setChainsCount(this.chainsMax, false);
      recalculate = true;
    }
    if (recalculate) {
      this.recalculateCounters();
      this.recalculatePriceActionsDays();
    }
  }

  onServerRentedUntilChanged() {
    this.recalculateBotsPricing();
  }

  onSetBotIsFarm() {
    this.recalculateBotsPricing();
  }

  setActionCount(action, count) {
    if (count < 0)
      return;
    action.count = count;

    const type = action.type;

    action.canAdd = (() => {
      if (type === 'MAIN.REVIEW')
        return this.reviewsCount < this.reviewsMax;
      if (type.startsWith('VIEW.'))
        return this.viewsCount < this.viewsMax;
      return this.chainsCount < this.chainsMax;
    })();

    action.canSub = (() => {
      if (!count)
        return false;

      if (type === 'MAIN.REVIEW')
        return this.reviewsCount > this.reviewsMin;
      if (type.startsWith('VIEW.'))
        return this.viewsCount > this.viewsMin;
      return this.chainsCount > this.chainsMin;
    })();
  }

  /**
   * Устанавливает значение бегунка.
   * @param {number|string} value 
   * @returns {void}
   */
  setSliderValue(value) {
    if (typeof count !== 'number')
      value = Number(value);
    if (value < this.sliderValueMin || value > this.sliderValueMax)
      return;

    if (this.isChains)
      return this.setChainsCount(value * (this.mode.chainsStep || this.market.chainsStep));
    if (this.isReviews)
      return this.setReviewsCount(value);
    if (this.isViews)
      return this.setViewsCount(value * this.market.viewsStep);
  }

  setChainsCount(count, recalculate = true) {
    if (count < this.chainsMin || count > this.chainsMax)
      return;
    this.chainsCount = count;
    if (this.isChains)
      this.sliderValue = count / (this.mode.chainsStep || this.market.chainsStep);

    this.reviewsCount = Math.min(this.reviewsCount, Math.floor(count / this.chainsPerReview));

    if (recalculate) {
      this.recalculateCounters();
      this.recalculatePriceActionsDays();
    }
  }

  setReviewsCount(count, recalculate = true) {
    if (count < this.reviewsMin || count > this.reviewsMax)
      return;
    this.reviewsCount = count;
    if (this.isReviews)
      this.sliderValue = count;

    const newChainsCount = Math.ceil(count * this.chainsPerReview);
    this.setChainsCount(newChainsCount, false);

    if (recalculate) {
      this.recalculateCounters();
      this.recalculatePriceActionsDays();
    }
  }

  setViewsCount(count, recalculate = true) {
    if (count < this.viewsMin || count > this.viewsMax)
      return;
    this.viewsCount = count;
    if (this.isViews)
      this.sliderValue = count / this.market.viewsStep;

    if (recalculate) {
      this.recalculateCounters();
      this.recalculatePriceActionsDays();
    }
  }

  recalculateCounters(categoryToExclude) {
    const market = this.market;
    const mode = this.mode;

    const chainsStep = mode.chainsStep || market.chainsStep;

    if (categoryToExclude !== 'MAIN') {
      this.fillCountersByTheirDistribution('MAIN', this.chainsCount / chainsStep);
      this.getActionsStartsWith('MAIN').forEach(action => { action.count *= chainsStep });
    }
    this.fillCountersByConstant('ALWAYS', this.chainsCount / chainsStep);
    this.getActionsStartsWith('ALWAYS').forEach(action => { action.count *= chainsStep });
    if (categoryToExclude !== 'EXTRA') {
      this.fillCountersByTheirDistribution('EXTRA', this.chainsCount / chainsStep);
      this.getActionsStartsWith('EXTRA').forEach(action => { action.count *= chainsStep });
    }

    this.fillCountersByTheirDistribution('VIEW', this.viewsCount / market.viewsStep);
    this.getActionsStartsWith('VIEW').forEach(action => { action.count *= market.viewsStep });

    const reviewAction = this.actions.find(action => action.type === 'MAIN.REVIEW');
    if (reviewAction)
      reviewAction.count = this.reviewsCount;

    this.actions.forEach(action => { this.setActionCount(action, action.count) });
  }

  getActionsStartsWith(startsWith) {
    return this.actions.filter(action => action.type.startsWith(startsWith));
  }

  fillCountersByConstant(startsWith, value) {
    const actions = this.getActionsStartsWith(startsWith).filter(action => action.type !== 'MAIN.REVIEW');
    actions.forEach(action => { this.setActionCount(action, value) });
  }

  fillCountersByTheirDistribution(startsWith, chains, actionToExclude = null) {
    const actions = this.getActionsStartsWith(startsWith).filter(action => action.type !== 'MAIN.REVIEW')
      .filter(action => action !== actionToExclude);
    actions.forEach(action => { this.setActionCount(action, Math.round(chains * action.share)) });

    const total = actions.reduce((acc, action) => acc + action.count, 0);

    let diff = chains - total;
    while (diff > 0) {
      const min = actions.reduce((acc, action) => !action.count || (acc.count < action.count) ? acc : action, actions[0]);
      this.setActionCount(min, min.count + 1);
      diff--;
    }
    while (diff < 0) {
      const max = actions.reduce((acc, action) => !action.count || (acc.count > action.count) ? acc : action, actions[0]);
      this.setActionCount(max, max.count - 1);
      diff++;
    }
  }

  addActionCount(action, add) {
    const countNew = action.count + add;
    if (countNew < 0)
      return;
  
    const type = action.type;
    if (type.startsWith('VIEW')) {
      const viewsCountNew = this.viewsCount + add;
      if (viewsCountNew < this.viewsMin || viewsCountNew > this.viewsMax)
        return;
      this.viewsCount = viewsCountNew;
      if (this.isViews)
        this.sliderValue = this.viewsCount / this.market.viewsStep;

      this.setActionCount(action, countNew);
      this.actions.forEach(action => { this.setActionCount(action, action.count) });

      this.recalculateDistribution('VIEW');
      this.recalculatePriceActionsDays();
      return;
    }

    let chainsCountNew = this.chainsCount;

    if (type === 'MAIN.REVIEW') {
      if (countNew < this.reviewsMin || countNew > this.reviewsMax)
        return;
      if (this.chainsCount < Math.ceil(countNew * this.chainsPerReview)) {
        chainsCountNew = Math.ceil(countNew * this.chainsPerReview);
        if (chainsCountNew > this.chainsMax)
          return;
      }
      this.setActionCount(action, countNew);
      this.reviewsCount = countNew;
      if (this.isReviews)
        this.sliderValue = this.reviewsCount;
    } else {
      chainsCountNew += add;
    }

    if (chainsCountNew !== this.chainsCount) {
      if (type === 'MAIN.REVIEW') {
        this.setChainsCount(chainsCountNew);
        return;
      }

      if (chainsCountNew < this.chainsMin || chainsCountNew > this.chainsMax)
        return;
      this.chainsCount = chainsCountNew;
      if (this.isChains)
        this.sliderValue = chainsCountNew / (this.mode.chainsStep || this.market.chainsStep);

      if (this.reviewsCount) {
        this.reviewsCount = Math.min(Math.floor(this.chainsCount / this.chainsPerReview), this.reviewsCount);
        this.setActionCount(this.actions.find(action => action.type === 'MAIN.REVIEW'), this.reviewsCount);
        if (this.isReviews)
          this.sliderValue = this.reviewsCount;
      }

      const category = type.substring(0, type.indexOf('.'));
      if (category !== 'ALWAYS')
        this.setActionCount(action, countNew);
      this.recalculateCounters(category);
      if (category !== 'ALWAYS')
        this.recalculateDistribution(category);
    }

    this.actions.forEach(action => { this.setActionCount(action, action.count) });
    this.recalculatePriceActionsDays();
  }

  recalculateDistribution(startsWith) {
    const actions = this.getActionsStartsWith(startsWith).filter(action => action.type !== 'MAIN.REVIEW');
    const total = actions.reduce((acc, action) => acc + action.count, 0);
    if (!total)
      return;
    actions.forEach(action => { action.share = action.count / total });
  }

  /**
   * Увеличить количество действия.
   */
  addAction(action) {
    const market = this.market;
    const mode = this.mode;
    this.store.setIsActivitiesCalculatorManual(true);
    this.addActionCount(action, 0 + (action.type.startsWith('VIEW.') ? market.viewsStep : (mode.chainsStep || market.chainsStep)));
  }
  /**
   * Уменьшить количество действия.
   */
  subAction(action) {
    const market = this.market;
    const mode = this.mode;
    this.store.setIsActivitiesCalculatorManual(true);
    this.addActionCount(action, 0 - (action.type.startsWith('VIEW.') ? market.viewsStep : (mode.chainsStep || market.chainsStep)));
  }

  recalculatePriceActionsDays() {
    const reviewsCount = this.actions.find(action => action.type === 'MAIN.REVIEW')?.count ?? 0;
    this.reviewsCount = reviewsCount;

    const reviewFullPrice = this.reviewPrice + (this.store.reviewWarranty ? this.reviewWarrantyPrice : 0);
    const reviewsTotalPrice = reviewsCount * reviewFullPrice;

    const reviewsMinPrice = reviewFullPrice * Math.min(reviewsCount, Math.floor(this.chainsMin / this.chainsPerReview));

    const viewsTotalCount = this.actions.filter(action => action.type.startsWith('VIEW.')).reduce((acc, action) => acc + action.count, 0);
    this.viewsCount = viewsTotalCount;
    const viewsTotalPrice = this.viewPrice * viewsTotalCount;

    const chainsPrice = this.chainsCount * this.chainPrice;

    this.price = chainsPrice + reviewsTotalPrice + viewsTotalPrice;
    if (this.isChains) {
      this.priceMin = this.chainsMin * this.chainPrice + reviewsMinPrice + viewsTotalPrice;
      this.priceMax = this.chainsMax * this.chainPrice + reviewsTotalPrice + viewsTotalPrice;
    } else if (this.isReviews) {
      this.priceMin = this.reviewsMin * reviewFullPrice + Math.max(this.chainsCount, Math.ceil(this.reviewsMin * this.chainsPerReview)) * this.chainPrice + viewsTotalPrice;
      this.priceMax = this.reviewsMax * reviewFullPrice + Math.max(this.chainsCount, Math.ceil(this.reviewsMax * this.chainsPerReview)) * this.chainPrice + viewsTotalPrice;
    } else if (this.isViews) {
      this.priceMin = this.viewsMin * this.viewPrice + reviewsTotalPrice + chainsPrice;
      this.priceMax = this.viewsMax * this.viewPrice + reviewsTotalPrice + chainsPrice;
    }

    this.actionsCount = this.actions.reduce((acc, action) => acc + ((action.subactions ?? 1) * action.count), 0);

    if (!this.mode.bots) {
      const deadlines = this.deadlines;
      for (const deadline of deadlines) {
        deadline.days = Math.round(Math.max(
          this.chainsCount / deadline.chainsPerDay,
          reviewsCount / deadline.reviewsPerDay + (reviewsCount ? this.reviewsOffsetDays : 0),
          viewsTotalCount / this.viewsPerDay
        ));
      }
      // fix deadline errors
      deadlines[0].days = Math.max(deadlines[0].days, 1);
      for (let i = 1; i < deadlines.length; ++i)
        deadlines[i].days = Math.max(deadlines[i].days, deadlines[i - 1].days + 1);
    }
  }
}

/**
 * Класс с общей логикой для работы с площадками.
 * @description Класс позволяет настроить и создать на сервере новый проект.
 * На данный момент представляет собой сборную солянку
 * настроек проекта для всех возможных площадок.
 * @property {Calculator[]} calculators Доступные калькуляторы.
 * Между калькуляторами можно переключаться и настраивать их
 * независимо друг от друга.
 * Калькулятор определяет виды и количество действий,
 * которые можно заказывать на площадке.
 * На выходе калькулятор выдает цену и сроки,
 * на которые должен согласиться пользователь.
 */
export default class MarketStore {

  market;
  calculators;

  serverRentedUntilDatetime = undefined;

  constructor(market) {
    this.market = market;

    this.calculators = market.modes.map(mode => makeAutoObservable(mode.calculator ?? new Calculator(this, market, mode)));
    this.hasBots = market.modes.some(mode => mode.bots);

    this.defaultSelectedCalculatorIndex = (() => {
      let pathname = document.location.pathname;
      while (pathname.endsWith('/'))
        pathname = pathname.substring(0, pathname.length - 1);
      if (pathname.endsWith('-new'))
        pathname = pathname.substring(0, pathname.length - '-new'.length);
      const path = pathname ?? '/';

      const index = this.calculators.findIndex(calculator => calculator.mode.path === path);
      return index >= 0 ? index : 0;
    })();
    this.selectedCalculatorIndex = this.defaultSelectedCalculatorIndex;
    this.currentCalculator = this.calculators[this.defaultSelectedCalculatorIndex]

    this.handleInitialReviewCount();
    makeAutoObservable(this);

    setImmediate(() => {
      observe(AppStore, 'profile', () => {
        const profile = AppStore.profile;
        let t = undefined;
        if (profile !== undefined) {
          t = profile?.serverRentedUntilDatetime ?? null;
          if (!t || t.getTime() <= (new Date()).getTime())
            t = null;
        }
        runInAction(() => {
          this.serverRentedUntilDatetime = t;
          for (const calculator of this.calculators)
            calculator.onServerRentedUntilChanged?.(t);
        });
      });
      observe(AppStore, 'token', () => {
        if (!AppStore.token) {
          runInAction(() => {
            this.serverRentedUntilDatetime = null;
          });
        }
      });
      if (!AppStore.token) {
        runInAction(() => {
          this.serverRentedUntilDatetime = null;
        });
      }
    });
  }

  get needFullParams() {
    if (!this.market.isMarketplace)
      return false;
    const calculator = this.currentCalculator;
    return !!(calculator.chainsCount || calculator.reviewsCount);
  }

  handleInitialReviewCount() {
    const urlParams = new URLSearchParams(window.location.search);
    const initialReviewCount = urlParams.get('number-reviews');
    
    if (initialReviewCount) {
      const reviewCount = parseInt(decodeURIComponent(atob(initialReviewCount, 10)));
      if (!isNaN(reviewCount) && reviewCount > 0) {
        const reviewAction = this.currentCalculator.actions.find(action => action.type === 'MAIN.REVIEW');
        if (reviewAction) {
          this.currentCalculator.setReviewsCount(reviewCount);
        }
      }
    }
  }

  linkToAd = '';
  linkError = false;
  setLinkToAd(link) {
    this.linkToAd = link;
    this.linkError = false;
  }

  address = '';
  setAddress(address) {
    this.address = address;
  }

  country = '';
  setCountry(country) {
    this.country = country;
  }

  city = '';
  cityError = false;
  setCity(city) {
    this.city = city;
    this.cityError = false;
  }

  allCitiesFlag = false;
  setAllCitiesFlag(flag = true) {
    this.allCitiesFlag = flag;
  }

  currentCategoryIndex = undefined;
  categoryError = false;
  selectCategoryByIndex(index) {
    this.currentCategoryIndex = index;
    this.categoryError = false;
  }

  searchPhrases = [];
  searchPhrasesError = '';

  longInterests = [];

  brandPhrases = [];
  brandPhrasesError = '';

  keysClusterBuffer = '';
  setKeysClusterBuffer(buffer) {
    this.keysClusterBuffer = buffer;
  }

  longInterestsBuffer = '';
  setLongInterestsBuffer(buffer) {
    this.longInterestsBuffer = buffer;
  }

  addSearchPhrase(phraseToAdd) {
    phraseToAdd = phraseToAdd.trim();
    if (!phraseToAdd)
      return;

    if (this.searchPhrases.find(phrase => phrase === phraseToAdd))
      return;

    this.searchPhrases.push(phraseToAdd);
    this.searchPhrasesError = '';
  }

  removeSearchPhrase(phraseToRemove) {
    this.searchPhrases = this.searchPhrases.filter(phrase => phrase !== phraseToRemove);
    this.searchPhrasesError = '';
  }

  addBrandPhrase(phrase) {
    if (!this.brandPhrases.includes(phrase) && this.brandPhrases.length < 10) {
      this.brandPhrases.push(phrase);
      this.brandPhrasesError = '';
    }
  }

  removeBrandPhrase(phrase) {
    this.brandPhrases = this.brandPhrases.filter(p => p !== phrase);
    this.brandPhrasesError = '';
  }

  // TODO: Split by customizer models
  reviewComment = '';
  reviewCommentError = false;
  setReviewComment(text) {
    this.reviewComment = text;
    if (text.length > 549) {
      this.reviewCommentError = true;
    } else {
      this.reviewCommentError = false;
    }
  }
  commentComment = '';
  commentCommentError = false;
  setCommentComment(text) {
    this.commentComment = text;
    if (text.length > 449) {
      this.commentCommentError = true;
    } else {
      this.commentCommentError = false;
    }
  }

  reviewWarranty = false;
  setReviewWarranty(flag) {
    if (flag === this.reviewWarranty)
      return;
    this.reviewWarranty = flag;
    for (const calculator of this.calculators)
      calculator.recalculatePriceActionsDays();
  }

  selectedDeadlineIndex = 1;
  testing = false;
  selectDeadline(i) {
    this.selectedDeadlineIndex = i;
    this.testing = (this.currentCalculator.mode.bots && (this.selectedDeadlineIndex === 0));
  }

  selectCalculator(i) {
    this.selectedCalculatorIndex = i;
    this.currentCalculator = this.calculators[i];

    this.testing = (this.currentCalculator.mode.bots && (this.selectedDeadlineIndex === 0));

    // TODO: it's very bad code
    if (this.currentCalculator.mode.bots) {
      if (this.selectedDeadlineIndex > 1)
        this.selectDeadline(1);
    }
  }

  isActivitiesCalculatorManual = false;
  setIsActivitiesCalculatorManual(flag) {
    this.isActivitiesCalculatorManual = flag;
  }

  instructionsScreen = false;
  setInstructionsScreen(value) {
    this.instructionsScreen = value;
  }
  projectSetup = false;
  setProjectSetup(value) {
    this.projectSetup = value;
  }

  windowsOS = true;
  setSelectedOS(os) {
    this.windowsOS = os === 'Windows';
  }

  pfYandex = true; // TODO: change to Common SEO, not only Yandex
  setPfYandex(url) {
    this.pfYandex = url === 'yandex';
  }
  
  isTogglerActive = true;
  setTogglerActive(value) {
    this.isTogglerActive = value;
    if (!this.isTogglerActive) {
      this.brandPhrasesError = '';
    }
  }

  executorType = 'real';
  setExecutorType(type) {
    this.executorType = type;
    if (type === 'bots')
      this.selectCalculator(this.calculators.findIndex(calculator => calculator.mode.bots));
  }

  isBotFarm = false;
  setIsBotFarm(value) {
    this.isBotFarm = value;
    for (const calculator of this.calculators)
      calculator.onSetBotIsFarm?.(value);
  }

  screenshotComment = '';
  screenshotCommentError = false;
  setScreenshotComment(text) {
    this.screenshotComment = text;
    this.screenshotCommentError = false;
  }

  isPhotosUpload = false;
  setPhotosUpload(value) {
    this.isPhotosUpload = value;
  }

  photosUrl = '';
  setPhotosUrl(text) {
    this.photosUrl = text;
  }

  validateStep1() {
    const needFullParams = this.needFullParams;

    const showError = (error, inputName) => {
      messageBoxStore.showError(error);
      if (inputName === 'link') this.linkError = true;
      else if (inputName === 'category') this.categoryError = true;
      else if (inputName === 'city') this.cityError = true;
      return false;
    };

    if (needFullParams) {
      if (!AppStore.uploadedFiles.default) {
        AppStore.uploadedFileError = true;
        messageBoxStore.showError('Прикрепите скриншот объявления');
        return;
      }
    }

    const linkError = this.market.validateTargetUrl(this.linkToAd);
    if (linkError)
      return showError(linkError, 'link');

    if (needFullParams) {
      if (this.currentCategoryIndex === undefined)
        return showError('Укажите категорию объявления', 'category');
  
      if (!this.address)
        return showError('Укажите город продвижения', 'city');
      if (!this.country)
        return showError('Адрес продвижения должен быть со страной', 'city');
      if (!this.city)
        return showError('Адрес продвижения должен быть с городом', 'city');
  
      if (!this.searchPhrases.length) {
        this.searchPhrasesError = 'Добавьте поисковую фразу';
        return false;
      }
    }

    this.linkError = false;
    this.categoryError = false;
    this.cityError = false;

    return true;
  }

  validateStep2() {
    const reviewAction = this.currentCalculator.actions.find(action => action.type === 'MAIN.REVIEW');
    if (reviewAction?.count) {
      if (!this.reviewComment.trim()) {
        messageBoxStore.showError('Укажите требования к отзыву');
        this.reviewCommentError = true;
        return false;
      }
    }
    this.reviewCommentError = false;

    const commentAction = this.currentCalculator.actions.find(action => action.type === 'MAIN.COMMENT');
    if (commentAction?.count) {
      if (!this.commentComment.trim()) {
        messageBoxStore.showError('Укажите требования к комментариям');
        this.commentCommentError = true;
        return false;
      }
    }
    this.commentCommentError = false;

    return true;
  }

  validateStepSeo() {
    const showError = (error, inputName) => {
      messageBoxStore.showError(error);
      if (inputName === 'link') this.linkError = true;
      else if (inputName === 'city') this.cityError = true;
      return false;
    };

    if (this.pfYandex) {
      if (!this.searchPhrases.length) {
        this.searchPhrasesError = 'Добавьте поисковую фразу';
        return false;
      }

      if (this.isTogglerActive && !this.brandPhrases.length) {
        this.brandPhrasesError = 'Добавьте бренд-фразу';
        return false;
      }

      if (!AppStore.getUploadedFile('landingScreenshot')) {
        AppStore.uploadedFileError = true;
        messageBoxStore.showError('Прикрепите скриншот целевой страницы');
        return;
      }
    } else {
      if (!this.linkToAd)
        return showError("Укажите корректную ссылку на объявление", 'link');

      if (!AppStore.getUploadedFile('linkScreenshot')) {
        AppStore.uploadedFileError = true;
        messageBoxStore.showError('Прикрепите скриншот ссылки');
        return;
      }

      if (!AppStore.getUploadedFile('landingScreenshot')) {
        AppStore.uploadedFileError = true;
        messageBoxStore.showError('Прикрепите скриншот целевой страницы');
        return;
      }
    }

    this.linkError = false;
    this.cityError = false;

    return true;
  }

  validateStepSeoBots() {
    const showError = (error, inputName) => {
      messageBoxStore.showError(error);
      if (inputName === 'link') this.linkError = true;
      else if (inputName === 'city') this.cityError = true;
      else if (inputName === 'searchPhrases') this.searchPhrasesError = true;
      return false;
    };

    const linkError = this.market.validateTargetUrl(this.linkToAd);
    if (linkError)
      return showError(linkError, 'link');

    if (!this.allCitiesFlag) {
      if (!this.address)
        return showError('Укажите город продвижения', 'city');
      if (!this.country)
        return showError('Адрес продвижения должен быть со страной', 'city');
      if (!this.city)
        return showError('Адрес продвижения должен быть с городом', 'city');
    }

    this.searchPhrases = this.keysClusterBuffer.split(',').map(key => key.trim()).filter(key => key);
    if (!this.searchPhrases.length)
      return showError('Укажите кластер ключевых запросов', 'searchPhrases');
    this.longInterests = this.longInterestsBuffer.split(',').map(key => key.trim()).filter(key => key);

    this.linkError = false;
    this.cityError = false;
    this.searchPhrasesError = false;

    return true;
  }

  isCreatingProject = false;
  /** @param {boolean} flag */
  setIsCreatingProject(flag = true) {
    this.isCreatingProject = flag;
  }

  agreedRulesCreatingProject = false;
  /** @param {boolean} flag */
  setAgreedRulesCreatingProject(flag = true) {
    this.agreedRulesCreatingProject = flag;
  }

  isCreatingNewProject = false;
  async createNewProject() {
    if (this.isCreatingNewProject || !this.agreedRulesCreatingProject)
      return;

    let projectId;

    this.isCreatingNewProject = true;

    const calculator = this.currentCalculator;
    
    try {
      const pricing = calculator.mode.bots
        ? {
          botPrice: calculator.chainPrice,
          serverPrice: SERVER_PRICE,
        } : {
          chain: calculator.chainPrice,
          review: calculator.reviewPrice,
          reviewWarranty: calculator.reviewWarrantyPrice,
          view: calculator.viewPrice,
        };

      if (calculator.mode.bots && !this.isBotFarm)
        pricing.useCommunalServer = true;

      /** @type {import('client.js').ProjectParams} */
      let project = {
        type: this.market.id.toUpperCase(),
        activities: this.testing ? null : calculator.actions.reduce((activities, action) => {
          const type = action.type;
          let a = {
            type: type,
            count: action.count,
          };
          if (type === 'MAIN.REVIEW') {
            a.info = this.reviewComment;
            if (this.reviewWarranty)
              a.warranty = true;
          } else if (type === 'MAIN.COMMENT') {
            a.info = this.commentComment;
          }
          activities.push(a);
          return activities;
        }, []),
        days: calculator.deadlines[this.selectedDeadlineIndex].days,
        pricing,
      };

      if (calculator.mode.bots)
        project.bots = true;
      else
        project.price = this.testing ? 0 : calculator.price;

      if (this.testing)
        project.testing = true;

      // add comment
      if (this.reviewComment || this.commentComment) {
        if (!project.extras)
          project.extras = {};
        project.extras.comment = this.reviewComment || this.commentComment;
      }

      // add photos upload
      if (this.isPhotosUpload) {
        if (!project.extras)
          project.extras = {};
        project.extras.photosUrl = this.photosUrl;
      }

      if (!this.market.isSeo && !this.market.isMaps)
        project.link = this.linkToAd.startsWith('https://') ? this.linkToAd : ('https://' + this.linkToAd);

      if (this.needFullParams) { // TODO: isMarketplace
        project = {
          ...project,
          screenshot: await AppStore.computeScreenshotBlob(AppStore.uploadedFiles.default),
          category: this.market.categories[this.currentCategoryIndex].type,
          address: this.address,
          country: this.country,
          city: this.city,
          searchPhrases: this.searchPhrases,
        }
      }

      if (this.longInterests.length)
        project.longInterests = this.longInterests;

      if (this.market.isSeo || this.market.isMaps) { // TODO: class MarketSeoStore extends MarketStore
        if (this.pfYandex) {
          project = {
            ...project,
            link: calculator.mode.bots ? ((this.linkToAd.startsWith('https://') || this.linkToAd.startsWith('http://')) ? this.linkToAd : ('https://' + this.linkToAd)) : undefined,
            searchPhrases: this.searchPhrases,
            address: this.allCitiesFlag ? undefined : this.address,
            country: this.allCitiesFlag ? undefined : this.country,
            city: this.allCitiesFlag ? true : this.city,
            screenshot: calculator.mode.bots ? undefined : await AppStore.computeScreenshotBlob(AppStore.uploadedFiles.landingScreenshot),
          };
          if (!calculator.mode.bots && this.isTogglerActive)
            project.brandPhrases = this.brandPhrases;
        } else {
          project = {
            ...project,
            link: (this.linkToAd.startsWith('https://') || this.linkToAd.startsWith('http://')) ? this.linkToAd : ('https://' + this.linkToAd),
            screenshotLink: await AppStore.computeScreenshotBlob(AppStore.uploadedFiles.linkScreenshot), // TODO: подождать поддержку на беке
            screenshot: await AppStore.computeScreenshotBlob(AppStore.uploadedFiles.landingScreenshot),
          };
          if (this.screenshotComment)
            project.screenshotComment = this.screenshotComment;
        }
      }

      projectId = await client.createProject(project);
    } catch (e) {
      if (e instanceof ApiError)
        messageBoxStore.showError(e.message ?? 'Не удалось создать проект. Пожалуйста, повторите попытку');
      else
        throw e;
    } finally {
      this.isCreatingNewProject = false;
    }

    return projectId;
  }

  clearFieldsAfterCreateProjects() {
    this.setLinkToAd('');
    this.selectCategoryByIndex();
    this.setAddress('');
    this.setCountry('');
    this.setCity('');
    this.searchPhrases = [];
    this.longInterests = [];
    this.brandPhrases = [];
    this.keysClusterBuffer = '';
    this.longInterestsBuffer = '';
    this.setReviewComment('');
    this.setCommentComment('');
    this.setScreenshotComment('');
    this.setPhotosUrl('');
    AppStore.clearUploadedFile('default');
    AppStore.clearUploadedFile('linkScreenshot');
    AppStore.clearUploadedFile('landingScreenshot');  }
}
